import React from 'react';
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

export const DropdownTrader = ({...args}) => {
    return (
        <div className="dropdown dropdown-bottom">
            <label tabIndex={0} className="btn m-1 mt-0 bg-yellow-600 border-0 rounded-sm">Trader</label>
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-auto whitespace-nowrap z-40">
                <li><a className={"btn-ghost hover:bg-yellow-600"} href={"/orders-trader"}>Lista zleceń</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"} href={"/create-order"}>Nowe zlecenie</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"} href={'/price-lists-trader'}>Cenniki</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"} href={"/users-list"}>Lista klientów</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"} href={"/new-users"}>Nowi klienci</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"} href={"/registration"}>Nowy klient</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"} href={"/reports"}>Raporty</a></li>
            </ul>
        </div>
    );
}

export const DropdownTraderMobile = ({...args}) => {
    return (
            <ul tabIndex={0} className="menu">
                <li><a className="btn btn-ghost" href={"/orders-trader"}>Lista zleceń</a></li>
                <li><a className="btn btn-ghost" href={"/create-order"}>Nowe zlecenie</a></li>
                <li><a className="btn btn-ghost" href={"/price-lists-trader"}>Cenniki</a></li>
                <li><a className="btn btn-ghost" href={"/users-list"}>Lista klientów</a></li>
                <li><a className="btn btn-ghost" href={"/new-users"}>Nowi klienci</a></li>
                <li><a className="btn btn-ghost" href={"/registration"}>Nowy klient</a></li>
                <li><a className="btn btn-ghost" href={"/reports"}>Raporty</a></li>
            </ul>
    );
}

export default DropdownTrader;
