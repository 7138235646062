import axios from "axios";
import { API_URL } from "../constants";
import {getJwtToken} from "../token_service";

export async function AdminRegistration(username, password, PasswordConfirm,
    Email, CompanyName, CompanyShortName,
    Country, State, PhoneNumber, TaxNumber, 
    Address, BuildingNumber, City, PostCode,
    isKlient, isTrader, isLogistyk, isTerminal, isAdmin,
    exemptionFromExciseDutyDocumentName,
    exemptionFromExciseDutyDocumentFile
) {

    let data = JSON.stringify({
        "UserName": username,
        "Password": password,
        "PasswordConfirm": PasswordConfirm,
        "Email": Email,
        "CompanyName": CompanyName,
        "CompanyShortName": CompanyShortName,
        "Country": Country,
        "State": State,
        "PhoneNumber": PhoneNumber,
        "TaxNumber": TaxNumber,
        "Address": Address,
        "BuildingNumber": BuildingNumber,
        "City": City,
        "PostCode": PostCode,
        "isKlient": isKlient,
        "isTrader": isTrader,
        "isLogistyk": isLogistyk,
        "isTerminal": isTerminal,
        "isAdministrator": isAdmin,
        "exemptionFromExciseDutyDocumentName": exemptionFromExciseDutyDocumentName

    });
    let formdata = new FormData()
    formdata.append('document',data)
    formdata.append('file',exemptionFromExciseDutyDocumentFile)
    
    let config = {
        method: 'post',
        url: API_URL + '/api/Admin/AdministratorRegistration',
        headers: {
            'Authorization': 'Bearer ' + getJwtToken()
        },
        data: formdata,
        withCredentials: true
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function uploadNewStatute(newStatute) {
    let formdata = new FormData()
    formdata.append('file', newStatute)
    let config = {
        method: 'post',
        url: API_URL + '/api/statute/statuteUpdate',
        headers: {
            'Authorization': 'Bearer ' + getJwtToken()
        },
        data: formdata,
        withCredentials: true
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function getUserById(userId) {
    let config = {
        method: 'get',
        url: API_URL + '/api/user/userDataAdmin/' + userId,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true

    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function updateUser(user) {
    let data = JSON.stringify(user)

    let config = {
        method: 'put',
        url: API_URL + '/api/user/userUpdateByAdmin',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        data: data,
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    }
    );
}

export function getLogs(date) {
    let config = {
        method: 'get',
        url: API_URL + '/api/admin/getLogs/'+ date,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true,
        responseType: 'blob'
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "log"+date+".txt");
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                reject(error);
            })
    }
    );
}
