import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    activateTerminal,
    deactivateTerminal,
    editTerminal,
    getAllTerminals,
    postTerminal
} from "../../app/requests/terminalList";


function TerminalManagementScreen() {
    const [terminals, setTerminals] = useState([])

    const [name, setName] = useState("")
    const [shortName, setShortName] = useState("")

    const [changeFlag, setChangeFlag] = useState(false)
    const [error, setError] = useState("");

    const [editFlags, setEditFlags] = useState([])


    const navigate = useNavigate();

    useEffect(() => {
            getAllTerminals().then((result) => {
                setTerminals(result);
                setEditFlags(result.map(() => false))
            });
        }
        , [])

    let changeTerminalState = (id) => {
        if (terminals[id].isActive) {
            deactivateTerminal(terminals[id].id).then(() => {
                let newTerminals = terminals;
                newTerminals[id].isActive = false;
                setTerminals(newTerminals);
                setChangeFlag(!changeFlag);
            })
        } else {
            activateTerminal(terminals[id].id).then(() => {
                let newTerminals = terminals;
                newTerminals[id].isActive = true;
                setTerminals(newTerminals);
                setChangeFlag(!changeFlag);
            });
        }
    }

    return (
        <div
            className={"p-8 px-8 md:px-16"}>
            <h1 className={"text-2xl font-bold md-3 max-w-lg mb-5"}>Terminale</h1>
            <div className={"mt-5 overflow-x-auto"}>
                <table className={"table table-compact w-full rounded-none static"}>
                    <thead className={"rounded-none"}>
                    <tr key={"head"} className={"rounded-none"}>
                        <th className={"rounded-none w-16"}>Id</th>
                        <th className={"rounded-none w-max"}>Nazwa</th>
                        <th className={"rounded-none w-24"}>Skrót</th>
                        <th className={"rounded-none w-36"}>Aktywny</th>
                    </tr>
                    </thead>
                    <tbody>
                    {terminals.map((terminal, index) => (
                        <tr key={terminal.id}
                            className={"hover:bg-gray-200 group"}
                        >
                            <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}>{terminal.id}</td>
                            <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}>
                                {editFlags[index] ?
                                    <>
                                        <input
                                            type={"text"}
                                            className={"input input-bordered rounded-sm input-sm w-96"}
                                            value={terminal.name}
                                            onChange={(e) => {
                                                let newTerminals = terminals;
                                                newTerminals[index].name = e.target.value;
                                                setTerminals(newTerminals);
                                                setChangeFlag(!changeFlag);
                                            }}
                                        />
                                    </> :
                                    <>
                                        {terminal.name}
                                    </>
                                }
                            </td>
                            <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}>
                                {editFlags[index] ?
                                    <>
                                        <input
                                            type={"text"}
                                            className={"input input-bordered rounded-sm input-sm w-96"}
                                            value={terminal.shortName}
                                            onChange={(e) => {
                                                let newTerminals = terminals;
                                                newTerminals[index].shortName = e.target.value;
                                                setTerminals(newTerminals);
                                                setChangeFlag(!changeFlag);
                                            }}
                                        />
                                        <button
                                            className={"btn btn-ghost btn-sm rounded-sm transition duration-500 transition-all my-0"}
                                            onClick={() => {
                                                editTerminal({
                                                    id: terminal.id,
                                                    name: terminal.name,
                                                    shortName: terminal.shortName
                                                }).then(() => {
                                                    let newEditFlags = editFlags;
                                                    newEditFlags[index] = false;
                                                    setEditFlags(newEditFlags);
                                                    setChangeFlag(!changeFlag);
                                                })
                                            }}
                                        >Zapisz
                                        </button>
                                    </>
                                    :
                                    <>
                                        {terminal.shortName}
                                        <button
                                            className={"btn btn-ghost btn-sm rounded-sm transition duration-500 transition-all my-0"}
                                            onClick={() => {
                                                let newEditFlags = editFlags;
                                                newEditFlags[index] = true;
                                                setEditFlags(newEditFlags);
                                                setChangeFlag(!changeFlag)
                                            }}
                                        >Edytuj
                                        </button>
                                        
                                    </>
                                }
                            </td>
                            <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}>
                                <button
                                    className={"btn btn-ghost btn-sm rounded-sm transition duration-500 transition-all" + (terminal.isActive ? " bg-green-500 text-white" : " bg-red-500 text-white")}
                                    onClick={() => {
                                        changeTerminalState(index)
                                    }}
                                >{terminal.isActive ? "Tak" : "Nie"}</button>
                            </td>
                        </tr>
                    ))
                    }
                    <tr>
                        <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}></td>
                        <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}>
                            <input
                                className={"input input-bordered rounded-sm input-sm w-full"}
                                type={"text"}
                                placeholder={"Nazwa"}
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                        </td>
                        <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}>
                            <input
                                className={"input input-bordered rounded-sm input-sm w-full"}
                                type={"text"}
                                placeholder={"Skrót"}
                                value={shortName}
                                onChange={(e) => {
                                    setShortName(e.target.value)
                                }}
                            />
                        </td>
                        <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}>
                            <button
                                className={"btn btn-ghost btn-sm rounderd-sm"}
                                onClick={() => {
                                    postTerminal(name, shortName).then(() => {
                                        setError("")
                                        setName("")
                                        setShortName("")
                                        getAllTerminals().then(setTerminals);
                                    })
                                        .catch((e) => {
                                            if (e.response.status === 409) {
                                                setError("Terminal o podanej nazwie lub skrócie już istnieje!")
                                            } else {
                                                setError("Wystąpił błąd podczas dodawania terminala!")
                                            }
                                        })
                                }}
                            >{"Dodaj"}</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <button className={"btn btn-sm mt-2 rounded-sm"}
                        onClick={() => {
                            navigate(-1)
                        }}
                >
                    Powrót
                </button>
                <br/>
                {error !== "" ?
                    <div
                        className="alert alert-error shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                 fill="none"
                                 viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            <span>{error}</span>
                        </div>
                    </div> : <div/>
                }
            </div>
        </div>
    )
}

export default TerminalManagementScreen
;