import {isAdministrator, isClient, isTerminal, isTrader} from "../../app/token_service";

const ReportsScreen = () => {

    return (
        <div className={"p-2"}>
            <div
                className="p-8 pt-0 my-8 mx-auto rounded-sm max-w-[65rem] ">
                <div className="grid grid-cols-1 md:grid-cols-2">
                    {isClient() ? (
                            <div className={"inline-grid max-w-md bg-base-300 rounded-md mx-2 px-2 mb-4"}>
                            <span className={"text-sm font-bold"}>
                                Klient
                            </span>
                                <a className={"btn btn-sm btn-ghost my-2"}
                                   href={"/reports/issues/client"}
                                >
                                    Raport wydań samochodowych
                                </a>
                            </div>)
                        : null}
                    {
                        isTrader() ? (
                                <label className={"inline-grid max-w-md bg-base-300 rounded-md mx-2 px-2 mb-4"}>
                                <span className={"text-sm font-bold"}>
                                    Trader
                                </span>
                                    <a className={"btn btn-sm btn-ghost my-2"}
                                       href={"/reports/issues/trader"}
                                    >
                                        Raport wydań samochodowych
                                    </a>

                                </label>)
                            : null
                    }
                    {
                        isTerminal() ? (
                                <div className={"inline-grid max-w-md bg-base-300 rounded-md mx-2 px-2 mb-4"}>
                                <span className={"text-sm font-bold"}>
                                    Terminal
                                </span>
                                    <a className={"btn btn-sm btn-ghost my-2"}
                                       href={"/reports/issues/terminal"}
                                    >
                                        Raport wydań samochodowych
                                    </a>
                                </div>)
                            : null
                    }
                    {
                        isAdministrator() ? (
                                <div className={"inline-grid max-w-md bg-base-300 rounded-md mx-2 px-2 mb-4"}>
                                <span className={"text-sm font-bold"}>
                                    Administrator
                                </span>
                                    <a className={"btn btn-sm btn-ghost my-2"}
                                       href={"/statute-acceptation-report"}
                                    >
                                        Raport akceptacji regulaminu
                                    </a>
                                </div>)
                            : null
                    }
                </div>
            </div>
        </div>
    )
}

export default ReportsScreen