import jwt_decode from "jwt-decode";
import {refreshToken} from "./requests/annonymous";

export const TOKEN_NAME = 'sbk_token';

export function getJwtToken() {
    return localStorage.getItem(TOKEN_NAME);
}

export function setJwtToken(token) {
    localStorage.setItem(TOKEN_NAME, token);
}

export function removeJwtToken() {
    localStorage.removeItem(TOKEN_NAME);
}

export function getJwtTokenExpirationDate() {
    try {
        const token = getJwtToken();
        const decoded = jwt_decode(token);

        if (decoded.exp === undefined) return null;

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    } catch (e) {
        return null;
    }
}

export function isJwtTokenExpired() {
    const date = getJwtTokenExpirationDate();
    if (date === undefined || date === null) return true;
    return !(date.valueOf() > new Date().valueOf());
}

export function isJwtTokenRefreshable() {
    const date = getJwtTokenExpirationDate();
    if (date === undefined || date === null) return false;
    return date.valueOf() < new Date().valueOf() + 1000 * 60 * 55;
}

export function refreshJwtToken() {
    console.log("refresh token")
    refreshToken()
        .then(response => {
            setJwtToken(response.data);
        })
        .catch(error => {
            removeJwtToken();
            window.location.href = "/";
        });

}

export function getJwtTokenClaims() {
    try {
        const token = getJwtToken();
        return jwt_decode(token);
    } catch (e) {
        return null;
    }
}

export function getJwtTokenClaim(claim) {
    try {
        const token = getJwtToken();
        const decoded = jwt_decode(token);
        return decoded[claim];
    } catch (e) {
        return null;
    }
}

export function getJwtTokenRoles() {
    return getJwtTokenClaim("http://schemas.microsoft.com/ws/2008/06/identity/claims/role");
}

export function getJwtTokenCompany() {
    return getJwtTokenClaim("SBK24_Company");
}

export function getJwtTokenTerminalId() {
    return getJwtTokenClaim("SBK24_TerminalId");
}
export function getJwtTokenStatuteAcceptation() {
    return getJwtTokenClaim("IsStatuteAccepted");
}
export function getJwtTokenId() {
    return getJwtTokenClaim("http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier");
}

export function getJwtTokenName() {
    return getJwtTokenClaim("http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name");
}

export function isAdministrator(){
    return getJwtTokenRoles().includes("Admin");
}

export function isLogistyk() {
    return getJwtTokenRoles().includes("Logistyk");
}

export function isTerminal() {
    return getJwtTokenRoles().includes("Terminal");
}

export function isTrader() {
    return getJwtTokenRoles().includes("Trader");
}

export function isClient() {
    return getJwtTokenRoles().includes("Klient");
}