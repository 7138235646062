import {useEffect, useState} from "react";
import {
    activateProduct,
    deactivateProduct,
    editProduct,
    getAllProducts,
    postProduct
} from "../../app/requests/productsList";
import {useNavigate} from "react-router-dom";
import {activateTerminal, deactivateTerminal, getAllTerminals, postTerminal} from "../../app/requests/terminalList";


function ProductsManagementScreen() {
    const [products, setProducts] = useState([])
    const [productName, setProductName] = useState("")
    const [changeFlag, setChangeFlag] = useState(false)
    const [error, setError] = useState(null);

    const [editFlags, setEditFlags] = useState([])

    const navigate = useNavigate();

    useEffect(() => {
            getAllProducts().then((result) => {
                setProducts(result);
                setEditFlags(result.map(() => false))
            });
        }
        , [])

    const changeProductState = (id) => {
        if (products[id].isActive) {
            deactivateProduct(products[id].id).then(() => {
                let newProducts = products;
                newProducts[id].isActive = false;
                setProducts(newProducts);
                setChangeFlag(!changeFlag);
            })
        } else {
            activateProduct(products[id].id).then(() => {
                let newProducts = products;
                newProducts[id].isActive = true;
                setProducts(newProducts);
                setChangeFlag(!changeFlag);
            });
        }
    }

    return (
        <div
            className={"p-8 px-8 md:px-16"}>
            <h1 className={"text-2xl font-bold md-3 max-w-lg mb-5"}>Produkty</h1>
            <div className={"mt-5 overflow-x-auto"}>
                <table className={"table table-compact w-full rounded-none static"}>
                    <thead className={"rounded-none"}>
                    <tr key={"head"} className={"rounded-none"}>
                        <th className={"rounded-none w-16"}>Id</th>
                        <th className={"rounded-none w-max"}>Nazwa</th>
                        <th className={"rounded-none w-48"}>Kod Symfonii</th>
                        <th className={"rounded-none w-36"}>Aktywny</th>
                    </tr>
                    </thead>
                    <tbody>
                    {products.map((product, index) => (
                        <tr key={product.id}
                            className={"hover:bg-gray-200 group"}
                        >
                            <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all" + (product.isDefault ? " font-bold" : "")}>{product.id}</td>
                            <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all" + (product.isDefault ? " font-bold" : "")}>
                                {editFlags[index] ?
                                    <>
                                        <input
                                            type={"text"}
                                            className={"input input-bordered rounded-sm input-sm w-96"}
                                            value={product.name}
                                            onChange={(e) => {
                                                let newProducts = products;
                                                newProducts[index].name = e.target.value;
                                                setProducts(newProducts);
                                                setChangeFlag(!changeFlag)
                                            }}
                                        />
                                        <button
                                            className={"btn btn-ghost btn-sm rounded-sm transition duration-500 transition-all my-0"}
                                            onClick={() => {
                                                editProduct({id: product.id, name: product.name, symfoniaCode: product.symfoniaCode})
                                                    .then(() => {
                                                        let newEditFlags = editFlags;
                                                        newEditFlags[index] = false;
                                                        setEditFlags(newEditFlags);
                                                        setChangeFlag(!changeFlag)
                                                    })
                                            }}>Zapisz
                                        </button>
                                    </>
                                    : <>
                                        {product.name}
                                        <button
                                            className={"btn btn-ghost btn-sm rounded-sm transition duration-500 transition-all my-0"}
                                            onClick={() => {
                                                let newEditFlags = editFlags;
                                                newEditFlags[index] = true;
                                                setEditFlags(newEditFlags);
                                                setChangeFlag(!changeFlag)
                                            }}>
                                            Edytuj
                                        </button>
                                    </>
                                }
                            </td>
                            <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all" + (product.isDefault ? " font-bold" : "")}>
                                {editFlags[index] ?
                                    <>
                                        <input
                                            type={"text"}
                                            className={"input input-bordered rounded-sm input-sm w-96"}
                                            value={product.symfoniaCode}
                                            onChange={(e) => {
                                                let newProducts = products;
                                                newProducts[index].symfoniaCode = e.target.value;
                                                setProducts(newProducts);
                                                setChangeFlag(!changeFlag)
                                            }}
                                        />
                                        <button
                                            className={"btn btn-ghost btn-sm rounded-sm transition duration-500 transition-all my-0"}
                                            onClick={() => {
                                                editProduct({id: product.id, name: product.name, symfoniaCode: product.symfoniaCode})
                                                    .then(() => {
                                                        let newEditFlags = editFlags;
                                                        newEditFlags[index] = false;
                                                        setEditFlags(newEditFlags);
                                                        setChangeFlag(!changeFlag)
                                                    })
                                            }}>Zapisz
                                        </button>
                                    </>
                                    : <>
                                        {product.symfoniaCode}
                                        <button
                                            className={"btn btn-ghost btn-sm rounded-sm transition duration-500 transition-all my-0"}
                                            onClick={() => {
                                                let newEditFlags = editFlags;
                                                newEditFlags[index] = true;
                                                setEditFlags(newEditFlags);
                                                setChangeFlag(!changeFlag)
                                            }}>
                                            Edytuj
                                        </button>
                                    </>
                                }
                            </td>
                            <button
                                className={"btn btn-ghost btn-sm rounded-sm transition duration-500 transition-all" + (product.isActive ? " bg-green-500 text-white" : " bg-red-500 text-white")}
                                onClick={() => {
                                    changeProductState(index)
                                }}
                            >{product.isActive ? "Tak" : "Nie"}</button>

                        </tr>
                    ))}
                    <tr>
                        <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}></td>
                        <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}>
                            <input
                                className={"input input-bordered rounded-sm input-sm w-full"}
                                type={"text"}
                                placeholder={"Nazwa"}
                                value={productName}
                                onChange={(e) => {
                                    setProductName(e.target.value)
                                }}
                            />
                        </td>
                        <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}>
                            <button
                                className={"btn btn-ghost btn-sm rounderd-sm"}
                                onClick={() => {
                                    postProduct(productName).then(() => {
                                        setError("")
                                        setProductName("")
                                        getAllProducts().then(setProducts);
                                    })
                                        .catch((e) => {
                                            if (e.response.status === 409) {
                                                setError("Produkt o podanej nazwie już istnieje!")
                                            } else {
                                                setError("Wystąpił błąd podczas dodawania produktu!")
                                            }
                                        })
                                }}
                            >{"Dodaj"}</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <button className={"btn btn-sm mt-2 rounded-sm"}
                        onClick={() => {
                            navigate(-1)
                        }}
                >
                    Powrót
                </button>
                <br/>
            </div>
        </div>
    );
}

export default ProductsManagementScreen;