import {getJwtTokenId} from "../../app/token_service";
import {useEffect, useReducer, useState} from "react";
import {getProductsList} from "../../app/requests/productsList";
import {getTerminalList} from "../../app/requests/terminalList";
import {getOrderPrice, updateOrder} from "../../app/requests/order";
import {getCustomerList} from "../../app/requests/user";
import {getDrivers} from "../../app/requests/driver";

function defaultDriver() {
    return {
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        CarPlate: "",
        DocumentNumber: "",
    }
}

function reducer(state, action) {
    switch (action.type) {
        case "setOrderType":
            return {...state, OrderType: action.payload};
        case "setClientId":
            return {...state, ClientId: action.payload.id, ClientName: action.payload.name};
        case "setOrderNumber":
            return {...state, OrderNumber: action.payload};
        case "setOrderStatus":
            return {...state, OrderStatus: action.payload};
        case "setTraderId":
            return {...state, TraderId: action.payload};
        case "setTerminalId":
            return {...state, TerminalId: action.payload};
        case "setOrderDate":
            return {...state, OrderDate: action.payload};
        case "setDaysToPick":
            return {...state, DaysToPick: action.payload};
        case "setPaymentDays":
            return {...state, PaymentDays: action.payload};
        case "setProductId":
            return {...state, ProductId: action.payload};
        case "setPriceNetPerTon":
            return {...state, PriceNetPerTon: action.payload};
        case "setQuantityTons":
            if (action.payload === '') {
                return {...state, QuantityTons: undefined, Drivers: []};
            }
            if (action.payload < 0) {
                action.payload = 0;
            }
            if (action.payload > 1000) {
                action.payload = 1000;
            }
            action.payload = Math.round(action.payload);

            return {...state, QuantityTons: action.payload};
        case "setQuantityCars":
            if (action.payload === '') {
                return {...state, QuantityCars: undefined, Drivers: []};
            }
            if (action.payload < 0) {
                action.payload = 0;
            }
            if (action.payload > 30) {
                action.payload = 30;
            }
            action.payload = Math.round(action.payload);

            const drivers = []
            for (let i = 0; i < action.payload; i++) {
                if (state.DriversDto !== undefined && state.DriversDto.length > i) {
                    drivers.push(state.DriversDto[i]);
                } else {
                    drivers.push(defaultDriver());
                }
            }

            return {...state, QuantityCars: action.payload, DriversDto: drivers};
        case "setTransportSbk":
            return {...state, TransportSbk: action.payload};
        case "setComment":
            return {...state, Comment: action.payload};
        case "setCommentTerminal":
            return {...state, CommentTerminal: action.payload};
        case "updateDriver":
            const updatedDrivers = [...state.DriversDto];
            updatedDrivers[action.payload.index].FirstName = action.payload.firstName !== undefined ? action.payload.firstName.trim() : updatedDrivers[action.payload.index].FirstName;
            updatedDrivers[action.payload.index].LastName = action.payload.lastName !== undefined ? action.payload.lastName.trim() : updatedDrivers[action.payload.index].LastName;
            updatedDrivers[action.payload.index].PhoneNumber = action.payload.phone !== undefined ? action.payload.phone.trim() : updatedDrivers[action.payload.index].PhoneNumber;
            updatedDrivers[action.payload.index].CarPlate = action.payload.car !== undefined ? action.payload.car.trim() : updatedDrivers[action.payload.index].CarPlate;
            updatedDrivers[action.payload.index].DocumentNumber = action.payload.document !== undefined ? action.payload.document.trim() : updatedDrivers[action.payload.index].DocumentNumber;
            return {...state, DriversDto: updatedDrivers};
        case "addFile":
            return {...state, Files: [...state.Files, action.payload]};
        case "removeFile":
            return {...state, Files: state.Files.filter((file, index) => index !== action.payload)};
        default:
            return state;
    }
}

export default function EditOrderTrader(props) {
    const [state, dispatch] = useReducer(reducer, props.order);

    const [productList, setProductList] = useState([]);
    const [terminalList, setTerminalList] = useState([]);
    const [customerList, setCustomerList] = useState([]);

    const [priceNetto, setPriceNetto] = useState(props.order.PriceNetPerTon);
    const [priceNettoInput, setPriceNettoInput] = useState('');
    const [priceLoading, setPriceLoading] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const [errorDict, setErrorDict] = useState({});

    const [file, setFile] = useState(null);
    const [newFiles, setNewFiles] = useState([]);

    const [promptedDrivers, setPromptedDrivers] = useState([]);

    const [focusedDriver, setFocusedDriver] = useState(-1);

    let handleSubmit = () => {
        setError("");
        setSuccess(false);
        let flag = false;
        let errorDictionary = {};
        if (state.OrderDate === '' || state.OrderDate === undefined) {
            setError("Nie podano daty zamówienia lub wybrana data jest niepoprawna");
            errorDictionary = {...errorDictionary, date: true};
            flag = true;
        } else if (state.OrderDate < new Date()) {
            setError("Data zamówienia nie może być wcześniejsza niż dzisiejsza");
            errorDictionary = {...errorDictionary, date: true};
            flag = true;
        }
        if (state.QuantityTons === 0 || state.QuantityTons === undefined) {
            setError("Nie podano ilości ton");
            errorDictionary = {...errorDictionary, quantity: true};
            flag = true;
        }
        if (state.ProductId === -1) {
            setError("Nie wybrano produktu");
            errorDictionary = {...errorDictionary, product: true};
            flag = true;
        }
        if (state.TerminalId === -1) {
            setError("Nie wybrano terminalu");
            errorDictionary = {...errorDictionary, terminal: true};
            flag = true;
        }
        if (state.PriceNetPerTon === 0) {
            setError("Wprowadź lub pobierz domyślną cenę");
            errorDictionary = {...errorDictionary, price: true};
            flag = true;
        }
        if (state.ClientId === 0) {
            setError("Nie wybrano klienta");
            errorDictionary = {...errorDictionary, customer: true};
            flag = true;
        }

        setErrorDict(errorDictionary);

        if (flag) {
            return;
        }


        if (!state.TransportSbk) {
            for (let i = 0; i < state.DriversDto.length; i++) {
                if (state.DriversDto[i].FirstName === "" || state.DriversDto[i].LastName === "" || state.DriversDto[i].CarPlate === "") {
                    setError("Nie podano wszystkich danych kierowcy " + (i + 1));
                    return;
                }
                if (state.DriversDto[i].PhoneNumber !== "" && !/^[0-9]{8,10}$/.test(state.DriversDto[i].PhoneNumber)) {
                    setError("Niepoprawny numer telefonu kierowcy " + (i + 1));
                    return;
                }

                if (!/^[a-zA-Z0-9]+$/.test(state.DriversDto[i].CarPlate)) {
                    setError("Numer rejestracyjny kierowcy " + (i + 1) + " zawiera niedozwolone znaki");
                    return;
                }

                if (state.DriversDto[i].DocumentNumber !== "" && !/^[a-zA-Z0-9]+$/.test(state.DriversDto[i].DocumentNumber)) {
                    setError("Dokument tożsamości kierowcy " + (i + 1) + " zawiera niedozwolone znaki");
                    return;
                }

                if (!/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/.test(state.DriversDto[i].LastName)) {
                    setError("Nazwisko kierowcy " + (i + 1) + " zawiera niedozwolone znaki");
                    return;
                }

                if (!/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/.test(state.DriversDto[i].FirstName)) {
                    setError("Imię kierowcy " + (i + 1) + " zawiera niedozwolone znaki");
                    return;
                }
            }
        }
        setLoading(true);

        updateOrder(state, newFiles)
            .then(() => {
                setLoading(false);
                setSuccess(true);
            })
            .catch(e => {
                setError(e.response.data);
                setLoading(false)
            });
    }

    let updateSummary = async () => {
        if (priceNettoInput === undefined || priceNettoInput === 0 || priceNettoInput === '') {
            if (state.ProductId !== -1 && state.TerminalId !== -1 && state.ClientId !== 0) {
                await getOrderPrice({
                    ProductId: state.ProductId,
                    ClientId: state.ClientId,
                    TerminalId: state.TerminalId,
                    PaymentDays: state.PaymentDays === -1 ? -1 : state.PaymentDays,
                })
                    .then(r => {
                        dispatch({type: "setPriceNetPerTon", payload: r.data});
                        setPriceNetto(r.data);
                    })
            } else {
                dispatch({type: "setPriceNetPerTon", payload: 0});
                setPriceNetto(0);
                if (state.ProductId === -1) {
                    setErrorDict({...errorDict, product: true});
                }
                if (state.TerminalId === -1) {
                    setErrorDict({...errorDict, terminal: true});
                }
            }
        } else {
            setPriceNetto(priceNettoInput);
            dispatch({type: "setPriceNetPerTon", payload: priceNettoInput})
        }
    }

    useEffect(() => {
        if (state.ProductId === 0 || state.TerminalId === 0) {
            setPriceNetto(0)
            return
        }
        updateSummary().then(r => {
        });

    }, [state.ProductId, state.TerminalId, state.PaymentDays, state.ClientId])
    let getPrompts = (serach, idx) => {
        getDrivers(serach).then(setPromptedDrivers)
    }

    const saveFile = (e) => {
        setFile(e.target.files[0]);
    }

    const addToFiles = () => {
        setNewFiles([...newFiles, file]);
        document.getElementById("fileinput").value = "";
    }

    const removeFromFiles = (index) => {
        setNewFiles([...newFiles.slice(0, index), ...newFiles.slice(index + 1)]);
    }

    useEffect(() => {
        getCustomerList().then(setCustomerList).catch(() => setError("Wystąpił błąd podczas pobierania listy klientów, odśwież stronę aby spróbować ponownie"));
        getProductsList().then(setProductList).catch(() => setError("Wystąpił błąd podczas pobierania listy produktów, odśwież stronę aby spróbować ponownie"));
        getTerminalList().then(setTerminalList).catch(() => setError("Wystąpił błąd podczas pobierania listy terminali, odśwież stronę aby spróbować ponownie"));
        getPrompts({firstName: "", lastName: "", carPlate: ""}, 0);
    }, [])
    
   
    
    return (
        <div className="p-2">
            <div
                className="p-8 pt-0 my-8 mx-auto rounded-sm max-w-[65rem] ">
                <div className="text-sm breadcrumbs max-w-md">
                    <ul>
                        <li>Trader</li>
                        <li>Aktualizuj awizację</li>
                    </ul>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="inline-grid max-w-md">
                        <div className="block max-w-md">
                            <h1 className="text-2xl font-bold md-3 max-w-md">Aktualizacja awizacji
                                nr {state.OrderNumber}</h1>
                            <br/>
                            <span className="text-sm font-bold">TYP ZAMÓWIENIA</span>
                            <select id="type_selection"
                                    className="select select-sm w-full border-gray-400 rounded-none font-normal"
                                    disabled={success}
                                    value={state.OrderType}
                                    onChange={() => dispatch({
                                        type: "setOrderType",
                                        payload: document.getElementById("type_selection").value
                                    })}>
                                >
                                <option defaultValue value={0}>Samochodowe</option>
                            </select>
                        </div>

                        <div className="block max-w-md min-w-md">
                            <div className="mt-2">
                                <span className="text-sm font-bold">KLIENT</span>
                                <br/>
                                <input type="text" id="customer_search" autoComplete={"off"}
                                       disabled={success}
                                       value={state.ClientName}
                                       className={"input input-sm w-full border-gray-400 rounded-none font-normal" + (errorDict['customer'] ? ' border-red-500' : '')}
                                       onChange={() => {
                                           let customer = customerList.find(c => c.companyName === document.getElementById("customer_search").value);
                                           if (customer !== undefined) {
                                               setErrorDict({...errorDict, customer: false});
                                               dispatch({
                                                   type: "setClientId",
                                                   payload: {id: customer.id, name: customer.companyName}
                                               })
                                           } else {
                                               setErrorDict({...errorDict, customer: true});
                                               dispatch({
                                                   type: "setClientId",
                                                   payload: {
                                                       id: 0,
                                                       name: document.getElementById("customer_search").value
                                                   }
                                               })
                                           }
                                           // dispatch({
                                           //      type: "setClient",
                                           //      payload: customerList.find(element => element.companyName === document.getElementById("customer_search").value)[0].item.id
                                           // })
                                       }}
                                       list={'client-selection'}/>
                                <datalist id={'client-selection'}>
                                    {customerList.map(c => {
                                        return <option key={c.id} value={c.companyName}/>
                                    })} </datalist>
                            </div>
                        </div>

                        <div className="block max-w-md min-w-md text-sm font-bold mt-1">
                            <div className="mt-2">
                                DATA ZAMÓWIENIA
                                <input type="date"
                                       disabled={success}
                                       value={state.OrderDate}
                                       className={"input input-bordered input-sm w-full border-gray-400 rounded-none font-normal" + (errorDict['date'] ? ' border-red-500' : '')}
                                       onChange={(e) => {
                                           if (e.target.value !== '' && e.target.value !== undefined && e.target.value >= new Date().toISOString().split('T')[0]) {
                                               setErrorDict({...errorDict, date: false});
                                               dispatch({type: "setOrderDate", payload: e.target.value})
                                           } else {
                                               setErrorDict({...errorDict, date: true});
                                               dispatch({type: "setOrderDate", payload: ''})
                                           }
                                       }}/>
                            </div>
                        </div>
                        <div className="form-control max-w-md">
                            <div className="mt-2">
                                <span className="text-sm font-bold">WAŻNE PRZEZ</span>
                                <select id="pickup_choice_list"
                                        className="select select-sm w-full rounded-none border-gray-400 font-normal"
                                        disabled={success}
                                        value={state.DaysToPick}
                                        onChange={() =>
                                            dispatch({
                                                type: "setDaysToPick",
                                                payload: Number(document.getElementById("pickup_choice_list").value)
                                            })
                                        }>
                                    <option defaultValue value={0}>Do końca dnia</option>
                                    <option value={1}>1 dzień</option>
                                    <option value={2}>2 dni</option>
                                    <option value={3}>3 dni</option>
                                    <option value={4}>4 dni</option>
                                    <option value={5}>5 dni</option>
                                    <option value={6}>6 dni</option>
                                    <option value={7}>7 dni</option>
                                    <option value={8}>8 dni</option>
                                    <option value={9}>9 dni</option>
                                    <option value={10}>10 dni</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-control max-w-md">
                            <div className=" mt-2 ">
                                <span className="text-sm font-bold">TERMIN PŁATNOŚCI</span>
                                <select id="payment_choice_list"
                                        className="select select-sm w-full border-gray-400 rounded-none font-normal"
                                        disabled={success}
                                        value={state.PaymentDays}
                                        onChange={() =>
                                            dispatch({
                                                type: "setPaymentDays",
                                                payload: Number(document.getElementById("payment_choice_list").value)
                                            })
                                        }>
                                    <option defaultValue value={0}>Przedpłata</option>
                                    <option value={7}>7 dni</option>
                                    <option value={14}>14 dni</option>
                                    <option value={21}>21 dni</option>
                                    <option value={30}>30 dni</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-control max-w-md">
                            <div className=" mt-2 ">
                                <span className="text-sm font-bold">PRODUKT</span>
                                <select id="product_choice_list"
                                        className={"select select-sm w-full border-gray-400 rounded-none font-normal" + (errorDict['product'] ? ' border-red-500' : '')}
                                        disabled={success}
                                        value={state.ProductId}
                                        onChange={() => {
                                            dispatch({
                                                type: "setProductId",
                                                payload: Number(document.getElementById("product_choice_list").value)
                                            })
                                            setErrorDict({...errorDict, product: false});
                                        }
                                        }>
                                    <option defaultValue value={-1}>Wybierz produkt</option>
                                    {
                                        productList.map((product) => (
                                            <option key={"product_" + product.id}
                                                    value={Number(product.id)}>{product.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className={"form-control max-w-md"}>
                            <div className={" mt-2"}>
                                <span className={"text-sm font-bold"}>TERMINAL</span>
                                <select id="terminal_choice_list"
                                        className={"select select-sm w-full border-gray-400 rounded-none font-normal" + (errorDict['terminal'] ? ' border-red-500' : '')}
                                        disabled={success}
                                        value={state.TerminalId}
                                        onChange={() => {
                                            dispatch({
                                                type: "setTerminalId",
                                                payload: Number(document.getElementById("terminal_choice_list").value)
                                            })
                                            setErrorDict({...errorDict, terminal: false});
                                        }}>
                                    <option defaultValue value={-1}>Wybierz Terminal</option>
                                    {
                                        terminalList.map((terminal) => (
                                            <option key={"terminal_" + terminal.id}
                                                    value={Number(terminal.id)}>{terminal.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="form-control max-w-md">
                            <label className=" mt-2 ">
                                <span className="text-sm font-bold">ILOŚĆ SAMOCHODÓW</span>
                                <input type="number" placeholder="Ilość samochodów"
                                       value={state.QuantityCars !== undefined ? state.QuantityCars : ""}
                                       className={"input input-bordered input-sm w-full border-gray-400 rounded-none font-normal" + (errorDict['quantity'] ? ' border-red-500' : '')}
                                       disabled={success}
                                       onChange={(e) => {
                                           {
                                               if (e.target.value !== '' && e.target.value !== undefined && e.target.value > 0) {
                                                   setErrorDict({...errorDict, quantity: false});
                                                   dispatch({
                                                       type: "setQuantityCars",

                                                       payload: e.target.value
                                                   })
                                               } else {
                                                   setErrorDict({...errorDict, quantity: true});
                                                   dispatch({type: "setQuantityCars", payload: 0})
                                               }
                                           }
                                       }}/>
                            </label>
                        </div>

                        <div className="form-control max-w-md">
                            <label className=" mt-2 ">
                                <span className="text-sm font-bold">ILOŚĆ W TONACH</span>
                                <input type="number" placeholder="Ilość w tonach"
                                       value={state.QuantityTons || ""}
                                       className={"input input-bordered input-sm w-full border-gray-400 rounded-none font-normal" + (errorDict['quantity'] ? ' border-red-500' : '')}
                                       disabled={success}
                                       onChange={(e) => {
                                           if (e.target.value !== '' && e.target.value !== undefined && e.target.value > 0) {
                                               setErrorDict({...errorDict, quantity: false});
                                               dispatch({type: "setQuantityTons", payload: e.target.value})
                                           } else {
                                               setErrorDict({...errorDict, quantity: true});
                                               dispatch({type: "setQuantityTons", payload: 0})
                                           }
                                       }}/>
                            </label>
                        </div>

                        <div className="form-control max-w-md">
                            <label className=" mt-2 ">
                                <span className="text-sm font-bold">KOMENTARZ</span>
                                <textarea placeholder="Komentarz"
                                          className="textarea textarea-bordered border-gray-400 px-3 rounded-none w-full"
                                          disabled={success}
                                          value={state.Comment || ""}
                                          onChange={(e) => dispatch({
                                              type: "setComment",
                                              payload: e.target.value
                                          })}/>
                            </label>
                        </div>
                        <div className="form-control max-w-md mb-2">
                            <label className=" mt-2 ">
                                <span className="text-sm font-bold">KOMENTARZ WEWNĘTRZNY</span>
                                <textarea placeholder="Komentarz wewnętrzny"
                                          className="textarea textarea-bordered border-gray-400 px-3 rounded-none w-full"
                                          disabled={success}
                                          value={state.CommentTerminal || ""}
                                          onChange={(e) => dispatch({
                                              type: "setCommentTerminal",
                                              payload: e.target.value
                                          })}/>
                            </label>
                        </div>
                    </div>
                    <div className="max-w-md">
                        <div className="p-4 pt-0 bg-white">
                            <div className=''>
                                <h1 className="text-2xl font-bold mb-12">Podsumowanie</h1>
                                <div
                                    className="flex text-md mb-2 pl-1 bg-gray-200 place-content-between align-middle">Wartość
                                    zamówienia
                                    (brutto z VAT)
                                    <nobr>
                                        <h1 className="flex mb-1 text-lg font-bold text-right">{new Intl.NumberFormat("de-DE", {
                                            style: 'currency',
                                            currency: 'PLN'
                                        }).format(priceNetto * 1.23 * state.QuantityTons || 0)}</h1>
                                    </nobr>
                                </div>
                                <br/>
                                <div
                                    className="flex text-md mb-2 pl-1 bg-gray-200 place-content-between align-middle">Wartość
                                    zamówienia
                                    (netto)
                                    <nobr>
                                        <h1 className="flex mb-1 text-lg font-bold text-right">{new Intl.NumberFormat("de-DE", {
                                            style: 'currency',
                                            currency: 'PLN'
                                        }).format(priceNetto * state.QuantityTons || 0)}</h1>
                                    </nobr>
                                </div>
                                <br/>
                                <div
                                    className="flex text-md pl-1 bg-gray-200 place-content-between align-middle">Cena
                                    za tonę (netto)
                                    <nobr>
                                        <h1 className="flex mb-1 text-lg font-bold text-right">{new Intl.NumberFormat("de-DE", {
                                            style: 'currency',
                                            currency: 'PLN'
                                        }).format(priceNetto)}</h1>
                                    </nobr>
                                </div>
                                <div className="mt-2">
                                    <span className="text-sm font-bold">CENA NETTO</span>
                                    <input type="number" placeholder="Cena za tonę (netto)"
                                           value={priceNettoInput}
                                           className="input input-bordered input-sm w-full border-gray-400 rounded-none font-normal text-right"
                                           onChange={(e) => setPriceNettoInput(e.target.value)}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') {
                                                   updateSummary()
                                               }
                                           }}
                                    />
                                </div>

                                <button
                                    className="btn btn-md mt-6 right-3 bg-yellow-600 border-none rounded-sm disabled:bg-yellow-600 disabled:text-gray-200 float-right"
                                    onClick={updateSummary}
                                >
                                    Wczytaj cenę netto
                                    {/*TODO finish this button*/}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* add checkbox for transportSBK field*/}

                <div className="form-control">
                    <label className="label cursor-pointer w-[17rem]">
                        <span className="text-md font-bold">TRANSPORT SBK</span>
                        <input type="checkbox"
                               className="toggle ml-8 border-gray-400 checked:bg-yellow-600 checked:border-gray-400"
                               disabled={success}
                               checked={state.TransportSbk} onChange={() => {
                        }}
                               onClick={() => dispatch({type: "setTransportSbk", payload: !state.TransportSbk})}/>
                    </label>
                </div>

                {state.QuantityCars > 0 && !state.TransportSbk ?
                    <div className="">
                        <span className="font-bold ml-1">KIEROWCY</span> <br/>
                        <table className="block bg-gray-200 overflow-x-auto mt-2 relative w-auto text-left pd-3">
                            <thead>
                            <tr>
                                <th className="text-left pl-7">Imię *</th>
                                <th className="text-left pl-4">Nazwisko *</th>
                                <th className="text-left pl-4">Samochód *</th>
                                <th className="text-left pl-4">Telefon</th>
                                <th className="text-left pl-4">Dokument</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.DriversDto.map((driver, index) => (<tr key={index}>
                                <td id={"driver-" + index + "-row"}>
                                    <input type="text" placeholder="Imię"
                                           className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none"
                                           disabled={success}
                                           value={state.DriversDto[index].FirstName}
                                           onChange={(e) => {
                                               setTimeout(() => {
                                                   setFocusedDriver(index)
                                               }, 201)
                                               dispatch({
                                                   type: "updateDriver",
                                                   payload: {index: index, firstName: e.target.value}
                                               })
                                               getPrompts({
                                                   ...state.DriversDto[index],
                                                   firstName: e.target.value
                                               }, index)
                                           }}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') {
                                                   if (focusedDriver === index && promptedDrivers.length >= 1) {
                                                       e.preventDefault();
                                                       dispatch({
                                                           type: "updateDriver",
                                                           payload: {
                                                               index: index,
                                                               firstName: promptedDrivers[0].firstName,
                                                               lastName: promptedDrivers[0].lastName,
                                                               phoneNumber: promptedDrivers[0].phoneNumber,
                                                               documentNumber: promptedDrivers[0].documentNumber,
                                                               car: promptedDrivers[0].carPlate
                                                           }
                                                       })
                                                       setFocusedDriver(-1)
                                                   }
                                               }
                                           }}
                                           onFocus={(e) => {
                                               setTimeout(() => {
                                                   getPrompts({
                                                       ...state.DriversDto[index]
                                                   }, index)
                                               }, 200);
                                           }}
                                           onBlur={(e) => {
                                               setTimeout(() => {
                                                   setFocusedDriver(-1)
                                               }, 100)
                                           }}

                                    />

                                </td>
                                <td>
                                    <input type="text" placeholder="Nazwisko"
                                           className="input input-bordered input-sm bordered-base-100 rounded-none"
                                           disabled={success}
                                           value={state.DriversDto[index].LastName}
                                           onChange={(e) => {
                                               setTimeout(() => {
                                                   setFocusedDriver(index)
                                               }, 201)
                                               dispatch({
                                                   type: "updateDriver",
                                                   payload: {index: index, lastName: e.target.value}
                                               })
                                               getPrompts({
                                                   ...state.DriversDto[index],
                                                   lastName: e.target.value
                                               }, index)
                                           }}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') {
                                                   if (focusedDriver === index && promptedDrivers.length >= 1) {
                                                       e.preventDefault();
                                                       dispatch({
                                                           type: "updateDriver",
                                                           payload: {
                                                               index: index,
                                                               firstName: promptedDrivers[0].firstName,
                                                               lastName: promptedDrivers[0].lastName,
                                                               phoneNumber: promptedDrivers[0].phoneNumber,
                                                               documentNumber: promptedDrivers[0].documentNumber,
                                                               car: promptedDrivers[0].carPlate
                                                           }
                                                       })
                                                       setFocusedDriver(-1)
                                                   }
                                               }
                                           }}

                                           onFocus={(e) => {
                                               setTimeout(() => {
                                                   getPrompts({
                                                       ...state.DriversDto[index]
                                                   }, index)
                                               }, 200);
                                           }}
                                           onBlur={(e) => {
                                               setTimeout(() => {
                                                   setFocusedDriver(-1)
                                               }, 100)
                                           }}
                                    />
                                </td>
                                <td>
                                    <input type="text" placeholder="Nr rejestracyjny"
                                           className="input input-bordered input-sm bordered-base-100 rounded-none"
                                           disabled={success}
                                           value={state.DriversDto[index].CarPlate}
                                           onChange={(e) => {
                                               setTimeout(() => {
                                                   setFocusedDriver(index)
                                               }, 201)
                                               dispatch({
                                                   type: "updateDriver",
                                                   payload: {index: index, car: e.target.value}
                                               })
                                               getPrompts({
                                                   ...state.DriversDto[index],
                                                   carPlate: e.target.value
                                               }, index)
                                           }}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') {
                                                   if (focusedDriver === index && promptedDrivers.length >= 1) {
                                                       e.preventDefault();
                                                       dispatch({
                                                           type: "updateDriver",
                                                           payload: {
                                                               index: index,
                                                               firstName: promptedDrivers[0].firstName,
                                                               lastName: promptedDrivers[0].lastName,
                                                               phoneNumber: promptedDrivers[0].phoneNumber,
                                                               documentNumber: promptedDrivers[0].documentNumber,
                                                               car: promptedDrivers[0].carPlate
                                                           }
                                                       })
                                                       setFocusedDriver(-1)
                                                   }
                                               }
                                           }}
                                           onFocus={(e) => {
                                               setTimeout(() => {
                                                   getPrompts({
                                                       ...state.DriversDto[index]
                                                   }, index)
                                               }, 200);
                                           }}
                                           onBlur={(e) => {
                                               setTimeout(() => {
                                                   setFocusedDriver(-1)
                                               }, 100)
                                           }}
                                    />
                                </td>
                                <td>
                                    <input type="text" placeholder="Telefon-cyfry bez spacji"
                                           className="input input-bordered input-sm bordered-base-100 rounded-none"
                                           disabled={success}
                                           value={state.DriversDto[index].PhoneNumber}
                                           onChange={(e) => {
                                               setTimeout(() => {
                                                   setFocusedDriver(index)
                                               }, 201)
                                               dispatch({
                                                   type: "updateDriver",
                                                   payload: {index: index, phone: e.target.value}
                                               })
                                               getPrompts({
                                                   ...state.DriversDto[index],
                                                   phoneNumber: e.target.value
                                               }, index)
                                           }}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') {
                                                   if (focusedDriver === index && promptedDrivers.length >= 1) {
                                                       e.preventDefault();
                                                       dispatch({
                                                           type: "updateDriver",
                                                           payload: {
                                                               index: index,
                                                               firstName: promptedDrivers[0].firstName,
                                                               lastName: promptedDrivers[0].lastName,
                                                               phoneNumber: promptedDrivers[0].phoneNumber,
                                                               documentNumber: promptedDrivers[0].documentNumber,
                                                               car: promptedDrivers[0].carPlate
                                                           }
                                                       })
                                                       setFocusedDriver(-1)
                                                   }
                                               }
                                           }}
                                           onFocus={(e) => {
                                               setTimeout(() => {
                                                   getPrompts({
                                                       ...state.DriversDto[index]
                                                   }, index)
                                               }, 200);
                                           }}
                                           onBlur={(e) => {
                                               setTimeout(() => {
                                                   setFocusedDriver(-1)
                                               }, 100)
                                           }}
                                    />
                                </td>
                                <td>
                                    <input type="text" placeholder="Dokument tożsamości"
                                           className="input input-bordered input-sm bordered-base-100 rounded-none mr-3"
                                           disabled={success}
                                           value={state.DriversDto[index].DocumentNumber}
                                           onChange={(e) => {
                                               setTimeout(() => {
                                                   setFocusedDriver(index)
                                               }, 201)
                                               dispatch({
                                                   type: "updateDriver",
                                                   payload: {index: index, document: e.target.value}
                                               })
                                               getPrompts({
                                                   ...state.DriversDto[index],
                                                   documentNumber: e.target.value
                                               }, index)
                                           }}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') {
                                                   if (focusedDriver === index && promptedDrivers.length >= 1) {
                                                       e.preventDefault();
                                                       dispatch({
                                                           type: "updateDriver",
                                                           payload: {
                                                               index: index,
                                                               firstName: promptedDrivers[0].firstName,
                                                               lastName: promptedDrivers[0].lastName,
                                                               phoneNumber: promptedDrivers[0].phoneNumber,
                                                               documentNumber: promptedDrivers[0].documentNumber,
                                                               car: promptedDrivers[0].carPlate
                                                           }
                                                       })
                                                       setFocusedDriver(-1)
                                                   }
                                               }
                                           }}
                                           onFocus={(e) => {
                                               setTimeout(() => {
                                                   getPrompts({
                                                       ...state.DriversDto[index]
                                                   }, index)
                                               }, 200);
                                           }}
                                           onBlur={(e) => {
                                               setTimeout(() => {
                                                   setFocusedDriver(-1)
                                               }, 100)
                                           }}
                                    />
                                </td>

                            </tr>))}
                            </tbody>
                        </table>

                        <div className="absolute z-10 w-[61rem]"
                             hidden={focusedDriver === -1}
                        >
                            {promptedDrivers.map((driver, indexPrompt) => (
                                <div key={indexPrompt}
                                     className="flex flex-col bg-white border border-gray-300 rounded-sm shadow-sm"
                                     onClick={() => {
                                         dispatch({
                                             type: "updateDriver",
                                             payload: {
                                                 index: focusedDriver,
                                                 firstName: driver.firstName,
                                                 lastName: driver.lastName,
                                                 car: driver.carPlate,
                                                 phone: driver.phoneNumber,
                                                 document: driver.documentNumber
                                             }
                                         })
                                         setFocusedDriver(-1)
                                     }}
                                >
                                    <div className="flex flex-row items-center justify-between p-2">
                                        <div className="flex flex-row items-center">
                                            <div className="flex flex-col">
                                                <div
                                                    className="text-sm font-bold">{driver.firstName} {driver.lastName} {driver.carPlate} {driver.phoneNumber} {driver.documentNumber}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className={"h-16"}>

                            </div>
                        </div>
                    </div> : null}


                <label className="text-sm font-bold mt-1">ZAŁĄCZNIK</label>
                <br/>
                <input
                    id="fileinput"
                    type="file"
                    className="file-input file-input-bordered file-input-sm w-full max-w-xs rounded-none"
                    onChange={saveFile}
                    disabled={success}
                />
                <button
                    className={'btn btn-sm ml-2 bg-yellow-600 border-none rounded-sm disabled:bg-yellow-600 disabled-text-gray-200'}
                    onClick={addToFiles}
                    disabled={success || !file}
                >
                    Dodaj załącznik
                </button>
                <br/>

                <label className={'text-sm font-bold mt-1'}>ZAŁĄCZNIKI</label>
                <br/>
                {
                    state.Files.map((file, index) => (<nobr>
                        <div className={'items-center justify-center m-1'}>
                            {file.FileName}
                            <button
                                className={'btn btn-xs ml-2 bg-red-600 border-none rounded-sm disabled:bg-yellow-600 disabled-text-gray-200'}
                                onClick={() => dispatch({type: "removeFile", payload: index})}
                                disabled={success}
                            >
                                Usuń
                            </button>
                        </div>
                    </nobr>))
                }
                {
                    newFiles.map((file, index) => (<nobr>
                        <div className={'items-center justify-center m-1'}>
                            {file.name}
                            <button
                                className={'btn btn-xs ml-2 bg-red-600 border-none rounded-sm disabled:bg-yellow-600 disabled-text-gray-200'}
                                onClick={() => removeFromFiles(index)}
                                disabled={success}
                            >
                                Usuń
                            </button>
                        </div>
                    </nobr>))
                }

                <br/>
                <nobr>
                    <button
                        className="btn btn-md mt-2 right-3 bg-yellow-600 border-none rounded-sm disabled:bg-yellow-600 disabled:text-gray-200"
                        onClick={handleSubmit} disabled={loading || success}>
                        {!loading && !success ? "Aktualizuj zlecenie" : !success ? "Ładowanie..." : "Zakończono"}
                    </button>
                    {
                        success ?
                            <button
                                className="btn btn-md mt-2 right-3 bg-yellow-600 border-none rounded-sm ml-4"
                                onClick={() => {
                                    window.location.reload()
                                }}>
                                Powrót
                            </button>
                            : null
                    }
                </nobr>
                {error !== "" ?
                    <div
                        className="alert alert-error shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                 fill="none"
                                 viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            <span>{error}</span>
                        </div>
                        <button className="btn btn-ghost btn-sm" onClick={() => setError("")}>Zamknij</button>
                    </div> : <div/>
                }
                {success ?
                    <div
                        className="alert alert-success shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                 fill="none" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            <span>Zlecenie zaktualizowane pomyślnie</span>
                        </div>
                    </div> : null
                }
            </div>
        </div>
    );
}
