import React from 'react';

export const DropdownLogistyk = ({...args}) => {
    return (

        <div className="dropdown dropdown-bottom">
            <label tabIndex={0} className="btn m-1 mt-0 bg-yellow-600 border-0 rounded-sm">Logistyk</label>
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-auto whitespace-nowrap">
            </ul>
        </div>
    );
}

export const DropdownLogistykMobile = ({...args}) => {
    return (

            <ul className="menu">
            </ul>
    );
}
export default DropdownLogistyk;
