import {useState} from "react";
import {sendPasswordRestartEmail} from "../../app/requests/annonymous";


export function ForgotPasswordScreen() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    
    const handlePasswordReset = () => {
        setError('');
        setLoading(true);
        if (!email) {
            setError('Email jest wymagany');
        } else {
            sendPasswordRestartEmail(email)
                .then(() => setSuccess('Wysłano email z linkiem resetującym hasło'))
                .catch((error) => {
                    setError(error.response.status === 400 ? error.response.data : 'Wystąpił błąd')
                });
        }
        setLoading(false);
    }
    
    return (
        <div className="p-2">
            <div className="p-8 my-8 mx-auto border-2 bg-base-200 border-gray-300 rounded-md max-w-lg">
                <h1 className="text-2xl font-bold">Przypomnij hasło</h1>
                <br/>
                <label>Email</label>
                <br/>
                <input
                    type="text"
                    placeholder="Email"
                    className="input input-bordered w-full max-w-xs mb-2"
                    value={email}
                    onChange={e => setEmail(e.target.value)}

                />
                <br/>

                <button
                    className={"btn" + (loading ? " loading" : "")}
                    type="button"
                    disabled={loading || success !== ''}
                    onClick={handlePasswordReset}
                >
                    {loading ? 'Ładowanie...' : 'Przypomnij hasło'}
                </button>

                {error !== "" ?
                    <div className="alert alert-error shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                 fill="none"
                                 viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            <span>{error}</span>
                        </div>
                    </div> : <div/>
                }
                {success !== "" ?
                    <div className="alert alert-success shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                 fill="none"
                                 viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M5 13l4 4L19 7"/>
                            </svg>
                            <span>{success}</span>
                        </div>
                    </div> : <div/>
                }
            </div>
        </div>
    );
}