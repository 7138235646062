import {getJwtToken} from "../token_service";
import axios from "axios";
import {API_URL} from "../constants";


export async function getProductsList() { 
    let config = {
        method: 'get',
        url: API_URL + '/api/Product',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };
    
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function getAllProducts() {
    let config = {
        method: 'get',
        url: API_URL + '/api/product/list',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function activateProduct(id) {
    let config = {
        method: 'post',
        url: API_URL + '/api/product/activate/' + id,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function deactivateProduct(id) {
    let config = {
        method: 'post',
        url: API_URL + '/api/product/deactivate/' + id,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function postProduct(name) {
    let config = {
        method: 'post',
        url: API_URL + '/api/product',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true,
        data: JSON.stringify({
            name: name,
        })
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function editProduct(putDto) {
    const data = JSON.stringify(putDto);
    console.log(data);    
    let config = {
        method: 'put',
        url: API_URL + '/api/product',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: data
    };
    
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
} 