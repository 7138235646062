import React, {Component} from 'react';
import {NavMenu} from './NavMenu';
import { Footer } from "./Footer";
import { NewStatuteAcceptationScreen } from "../screens/StatuteScreen/NewStatuteAcceptationScreen"
import { isJwtTokenRefreshable, refreshJwtToken, getJwtTokenStatuteAcceptation, getJwtToken, isJwtTokenExpired, isClient } from "../app/token_service";
import {ping} from "../app/requests/admin";

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        if (isJwtTokenRefreshable())
        {
            console.log("refresh token")
            refreshJwtToken();
        }
         if (!isJwtTokenExpired() && isClient() && getJwtTokenStatuteAcceptation().toLowerCase() === 'false')
         {
             return (
                 <div className="min-h-full">
                     <NavMenu />
                     <div className="mb-16">
                         <NewStatuteAcceptationScreen/>
                     </div>
                     <Footer />
                 </div>
             );
         }
        return (
            <div className="min-h-full">
                <NavMenu/>
                <div className="mb-16">
                    {this.props.children}
                </div>
                <Footer/>
            </div>
        );
    }
}
