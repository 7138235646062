import {useEffect, useState} from "react";
import {
    deletePriceList,
    downloadPriceList,
    downloadPriceLists,
    getPriceLists,
    importPriceList, setDefaultPriceList
} from "../../app/requests/priceLists";
import {useNavigate} from "react-router-dom";


export default function PriceListsTraderScreen() {
    const [priceLists, setPriceLists] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);
    const [success, setSuccess] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        getPriceLists().then(setPriceLists).catch(setError).finally(() => setIsLoading(false));
    }, []);

    const saveFile = (e) => {
        setFile(e.target.files[0]);
    }

    const addToFiles = (e) => {
        importPriceList(file).then(() => {
            getPriceLists().then(setPriceLists).catch(setError).finally(() => {setIsLoading(false); setSuccess(true)});
        })
        document.getElementById("file-input-sm").value = null;
    }

    return (
        <div
            className={"p-8 px-8 md:px-16"}>
            <h1 className={"text-2xl font-bold md-3 max-w-lg mb-5"}>Cenniki</h1>
            <div className={"mt-5 overflow-x-auto"}>
                <table className={"table table-compact w-full rounded-none static"}>
                    <thead className={"rounded-none"}>
                    <tr key={"head"} className={"rounded-none"}>
                        <th className={"rounded-none w-16"}>Id</th>
                        <th className={"rounded-none w-max"}>Nazwa</th>
                        <th className={"rounded-none w-12"}></th>
                        <th className={"rounded-none w-8"}></th>
                        <th className={"rounded-none w-8"}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {priceLists.map((priceList) => (
                        <tr key={priceList.id}
                            className={"hover:bg-gray-200 group"}
                        >
                            <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all" + (priceList.isDefault ? " font-bold" : "")}>{priceList.id}</td>
                            <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all" + (priceList.isDefault ? " font-bold" : "")}>{priceList.priceListName}</td>
                            <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}>
                                {!priceList.isDefault ?
                                    <button
                                        className={"btn btn-ghost btn-sm"}
                                        onClick={() => {
                                            setDefaultPriceList(priceList.id).then(() => {
                                                getPriceLists().then(setPriceLists).catch(setError).finally(() => setIsLoading(false));
                                            })
                                        }}
                                    >
                                        Ustaw Domyślny
                                    </button> : null
                                }
                            </td>
                            <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}>
                                <button
                                    className={"btn btn-ghost btn-sm"}
                                    onClick={() => {
                                        downloadPriceList(priceList.id).then(response => {
                                            const href = URL.createObjectURL(response);

                                            // create "a" HTML element with href to file & click
                                            const link = document.createElement('a');
                                            link.href = href;
                                            link.setAttribute('download', priceList.priceListName + '.xlsx'); //or any other extension
                                            document.body.appendChild(link);
                                            link.click();

                                            // clean up "a" element & remove ObjectURL
                                            document.body.removeChild(link);
                                            URL.revokeObjectURL(href);
                                        })
                                    }}
                                >
                                    Pobierz
                                </button>
                            </td>
                            <td className={"group-hover:bg-gray-200 transition duration-500 group-hover:transition-all"}>
                                <a
                                    className={"btn btn-ghost btn-sm"}
                                    href={"/price-list/" + priceList.id}
                                >
                                    Szczegóły
                                </a>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>


                <button
                    className={"btn btn-sm mt-5 rounded-sm"}
                    onClick={() => {
                        downloadPriceLists().then(response => {
                            const href = URL.createObjectURL(response);

                            // create "a" HTML element with href to file & click
                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', 'cenniki.xlsx'); //or any other extension
                            document.body.appendChild(link);
                            link.click();

                            // clean up "a" element & remove ObjectURL
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        })
                    }}
                >
                    Pobierz wszystkie cenniki
                </button>

                <a
                    className={"btn btn-sm mt-5 ml-4 rounded-sm"}
                    href={"/price-lists/create"}
                >
                    Dodaj cennik
                </a>

                <br/>
                <h2 className={"text-xl font-bold md-3 max-w-lg mt-4 "}>Importuj cenniki
                </h2>
                <input type="file" className="file-input file-input-sm rounded-sm"
                       id="file-input-sm"
                       onChange={saveFile}
                />

                <button className={'btn btn-sm border-none rounded-sm disabled-text-gray-200 mt-4'}
                        onClick={addToFiles}
                        disabled={!file}
                >
                    Wyślij
                </button>
                <br/>
                <button className={"btn btn-sm mt-2 rounded-sm"}
                        onClick={() => {
                            navigate(-1)
                        }}
                >
                    Powrót
                </button>
                <br/>
                {success ? <div className="alert alert-success shadow-lg mt-8 w-96">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none"
                             viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>
                        <span>Cennik został zaimportowany</span>
                    </div>
                </div> : null}
            </div>
        </div>

    )
}