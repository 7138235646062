import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { isJwtTokenExpired, isTrader } from "../../app/token_service";
import { getStatuteReport } from "../../app/requests/statuteReport";
import { Result } from "postcss";

export const StatuteAcceptationReport = () => {
    const [customerList, setCustomerList] = useState([]);
    const [filtredList, setFiltredList] = useState([]);

    const [username, setUsername] = useState('');
    const [isAccepted, setIsAccepted] = useState(false);
    
    function filter(login, isStatuteAccepted)
    {
        console.log(login);
        console.log(isStatuteAccepted);
        let result = customerList;
        if (login !== '') {
            result = result.filter(x =>
                x.userName.toLowerCase().includes(login.toLowerCase())
            );
        }
        if (isStatuteAccepted) {
            result = result.filter(x => x.isStatuteAccepted === "True");
        }
        else {
            result = result.filter(x => x.isStatuteAccepted === "False");
        }
        console.log(result);
        return result;
    }
    function fetchData() {
        getStatuteReport().then(r => setCustomerList(r))
            .catch("problem");
        if (filtredList.length === 0 && username === '' ) {
            getStatuteReport().then(r => setFiltredList(r)).catch("problem");
        }

    }


    useEffect(fetchData, []);
    console.log(customerList)
    return (
        <div className="p-8">
            <h1 className="text-2xl font-bold md-3 max-w-md mb-5">Raport akceptacji regulaminu</h1>
            <div className="mt-5 overflow-x-auto">
                <table className="table table-compact table-zebra w-full rounded-none static">
                    <thead className="rounded-none">
                        <tr key="head" className="rounded-none">
                            <th className="rounded-none w-1/12" />
                            <th className="rounded-none w-1/3">Użytkownik</th>
                            <th className="rounded-none w-1/3">Data ostatniej akceptacji</th>
                            <th className="rounded-none w-1/6">Aktualny regulamin zaakceptowany?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td />
                            <td> <input type="text" className="border border-gray-400 bg-gray-200 rounded-none p-1 max-w-md"
                                onChange={e => {
                                    setUsername(e.target.value)
                                    setFiltredList(filter(e.target.value, isAccepted));
                                    

                                }
                                } /></td>
                            <td />
                            <td> <input type="checkbox" className="checkbox border border-gray-400 bg-gray-200 rounded-none p-1 max-w-md"
                                
                                onChange={e => {
                                    setIsAccepted(e.target.value)
                                    setFiltredList(filter(username, e.target.checked));

                                }
                                } /></td>
                        </tr>
                        {filtredList && filtredList.map(c => <tr key={c.userId}>
                            <td>{c.userId}</td>
                            <td><a href={'/user?id=' + c.userId}>{c.userName}</a><br />{c.companyShortName}</td>
                            <td>
                                {c.lastAcceptationDateTime.substring(0, 10)}
                            </td>
                            <td>
                                {c.isStatuteAccepted == "True"? "TAK" : "NIE"}
                            </td>
                        </tr>)}

                    </tbody>
                </table>
            </div>
        </div>
    )
}