import { getJwtToken } from "../token_service";
import axios from "axios";
import { API_URL } from "../constants";


export function getStatuteReport() {


    let config = {
        method: 'GET',
        url: API_URL + '/api/user/statuteReport',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}