import {getJwtToken} from "../token_service";
import axios from "axios";
import {API_URL} from "../constants";

export function getPriceLists() {
    let config = {
        method: 'get',
        url: API_URL + '/api/pricelist/list',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                console.log(response.data)
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function getPriceListsChoice() {
    let config = {
        method: 'get',
        url: API_URL + '/api/pricelist',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}


export function getPriceList(id) {
    let config = {
        method: 'get',
        url: API_URL + '/api/pricelist/' + id,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };
    
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                console.log(response.data)
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function getMyPriceList(id) {
    let config = {
        method: 'get',
        url: API_URL + '/api/priceList/myPriceList',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function downloadPriceLists() {
    let config = {
        method: 'get',
        url: API_URL + '/api/pricelist/xlsx',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };
    
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function downloadPriceList(id) {
    let config = {
        method: 'get',
        url: API_URL + '/api/pricelist/xlsx/' + id,
        responseType: 'blob',
        headers: {
            "Content-Type": "application/json",
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function deletePriceList(id) {
    let config = {
        method: 'delete',
        url: API_URL + '/api/pricelist/' + id,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function importPriceList(file) {
    var formData = new FormData();
    formData.append('file', file);
    let config = {
        method: 'put',
        url: API_URL + '/api/pricelist/xlsx',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function setDefaultPriceList(id) {
    let config = {
        method: 'post',
        url: API_URL + '/api/pricelist/default/' + id,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function getPriceListTemplate(priceListName) {
    let config = {
        method: 'get',
        url: API_URL + '/api/pricelist/xlsx-template/' + priceListName,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}
