import axios from "axios";
import {API_URL} from "../constants";


export function issuesJson(filters) {
    let config = {
        method: 'post',
        url: API_URL + '/api/report/issues',
        data: JSON.stringify(filters),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        withCredentials: true
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export function issuesPdf(filters) {
    let config = {
        method: 'post',
        url: API_URL + '/api/report/issues',
        data: JSON.stringify(filters),
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        },
        withCredentials: true
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "Raport awizacji.pdf");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            .catch(error => {
                reject(error);
            });
    });
}
export function issuesXls(filters) {
    let config = {
        method: 'post',
        url: API_URL + '/api/report/issues',
        data: JSON.stringify(filters),
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        withCredentials: true
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "Raport awizacji.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            .catch(error => {
                reject(error);
            });
    });
}
