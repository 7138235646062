import {isClient, isJwtTokenExpired} from "../../app/token_service";
import CreateOrderCustomer from "./CreateOrderCustomer";
import CreateOrderTrader from "./CreateOrderTrader";

export default function CreateOrderScreen() {
    return (
        <>
            {isClient() ? <CreateOrderCustomer/> : <CreateOrderTrader/>}
        </>
    )
}
