import {useState} from "react";
import {getPriceLists, getPriceListTemplate, importPriceList} from "../../app/requests/priceLists";
import {useNavigate} from "react-router-dom";


export default function CreatePriceListScreen() {
    const [priceList, setPriceList] = useState("");
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();


    const importExcel = (e) => {
        setIsLoading(true)
        setSuccess(false)
        importPriceList(file).then(() => {
            getPriceLists().catch(setError).finally(() => {
                setIsLoading(false);
                setSuccess(true);
                setError("");
            });
        })
        document.getElementById("file-input-sm").value = null;
    }

    return (
        <div
            className={"p-8 px-8 md:px-16"}>
            <h1 className={"text-2xl font-bold md-3 max-w-lg mb-5"}>Dodaj nowy cennik</h1>
            <div className={"mt-5 overflow-x-auto content-center"}>
                <input type="text" placeholder="Nazwa cennika" className={"input input-bordered w-96"}
                       onChange={(e) => setPriceList(e.target.value)}/>
                <br/>
                <button className={"btn mt-2"}
                        onClick={() => {
                            if (priceList === "") {
                                setError("Nazwa cennika nie może być pusta")
                                return;
                            }
                            getPriceListTemplate(priceList).then(
                                (response) => {
                                    const href = URL.createObjectURL(response);
                                    // create "a" HTML element with href to file & click
                                    const link = document.createElement('a');
                                    link.href = href;
                                    link.setAttribute('download', priceList + '.xlsx'); //or any other extension
                                    document.body.appendChild(link);
                                    link.click();

                                    // clean up "a" element & remove ObjectURL
                                    document.body.removeChild(link);
                                    URL.revokeObjectURL(href);
                                })
                        }}
                >
                    Pobierz szablon
                </button>
                <br/>
                <br/>
                <input type="file" id="file-input-sm" className={"file-input file-input-bordered mt-2 w-96"}
                       onChange={(e) => setFile(e.target.files[0])}/>
                <br/>
                <button className={"btn mt-2"}
                        onClick={() => {
                            importExcel()
                        }}
                >
                    Importuj
                </button>
                <br/>
                <button className={"btn mt-2"}
                        onClick={() => {
                            navigate(-1)
                        }}
                >
                    Powrót
                </button>

            </div>
            {success ? <div className="alert alert-success shadow-lg mt-8 w-96">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none"
                         viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                    <span>Cennik został zaimportowany</span>
                </div>
            </div> : null}
            {error !== "" ? <div className="alert alert-error shadow-lg mt-8 w-96">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none"
                         viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                    <span>{error}</span>
                </div>
            </div> : null}
            {isLoading ? <div className="alert alert-info shadow-lg mt-8 w-96">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none"
                         viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                    <span>Trwa importowanie cennika</span>
                </div>
            </div> : null}
        </div>
    );
}