import {isClient} from "../../app/token_service";
import EditOrderCustomer from "./EditOrderCustomer";
import EditOrderTrader from "./EditOrderTrader";

export default function EditOrderComponent(props) {
    console.log(props.order)
    const order = {
            Id : props.order.id,
            OrderType: props.order.orderType,
            OrderStatus: props.order.orderStatus,
            OrderNumber: props.order.orderNumber,
            ClientId: props.order.client.id,
            ClientName: props.order.client.companyName,
            TerminalId: props.order.terminal.id,
            OrderDate: props.order.orderDate.substring(0,10),
            DaysToPick: props.order.daysToPick,
            PaymentDays: props.order.paymentDays,
            ProductId: props.order.product.id,
            PriceNetPerTon: props.order.priceNetPerTon,
            QuantityTons: props.order.quantityTons,
            QuantityCars: props.order.quantityCars,
            TransportSbk: props.order.transportSbk,
            DriversDto: props.order.driversConfirmed.map((driver) => {
                return {
                    Id: driver.id,
                    ConfirmationId : driver.confirmationId,
                    FirstName: driver.firstName,
                    LastName: driver.lastName,
                    PhoneNumber: driver.phoneNumber || "",
                    DocumentNumber: driver.documentNumber || "",
                    CarPlate: driver.carsPlateNumber,
                };
            }),
            Comment: props.order.comment,
            CommentTerminal: props.order.commentTerminal,
            Files: props.order.files.map((file) => {
                return {
                    Id: file.id,
                    FileName: file.fileName,
                };
            })
        }
    return (
        <>
            {isClient() ? <EditOrderCustomer order={order}/> : <EditOrderTrader order={order}/>}
        </>
    )};