import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {updateUser, getUserData} from "../../app/requests/userUpdate";
import {
    isJwtTokenExpired
} from "../../app/token_service";


export const UserUpdateScreen = () => {
    const [UserName, setUserName] = useState('');
    const [Password, setPassword] = useState('');
    const [PasswordConfirm, setPasswordConfirm] = useState('');
    const [Email, setEmail] = useState('');
    const [CompanyName, setCompanyName] = useState('');
    const [CompanyShortName, setCompanyShortName] = useState('');
    const [Country, setCountry] = useState('');
    const [State, setState] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [TaxNumber, setTaxNumber] = useState('');
    const [Address, setAddress] = useState('');
    const [BuildingNumber, setBuildingNumber] = useState('');
    const [City, setCity] = useState('');
    const [PostCode, setPostCode] = useState('');
    const [isStatutAccepted, setisStatutAccepted] = useState('');
    const [agreementForRecivingOrderStatus, setagreementForRecivingOrderStatus] = useState(false);
    const [exemptionFromExciseDutyDocumentFile, setexemptionFromExciseDutyDocumentFile] = useState();
    const [exemptionFromExciseDutyDocumentName, setexemptionFromExciseDutyDocumentName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');


    //const api = 
    const onUserUpdate = async () => {
        setLoading(true);

        const phoneRegex = new RegExp(/^[0-9]{9}$/);
        const taxNumberRegex = new RegExp(/^[A-Z]{0,2}[0-9]{10}$/);
        const postCodeRegex = new RegExp(/^([0-9]{2}-[0-9]{3})$|^([0-9]{5})$/);
        const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
        // const buildingNumberRegex = new RegExp(/^[0-9]{1,4}[a-zA-Z]{0,1}$/);
        const buildingNumberRegex = new RegExp(/^[\w\\\/]*$/);
        const passwordRegex = new RegExp(/^(?=.*?[A-Za-z])(?=.*?[0-9])([\w~`!@#$%^&*()_\-+={[}\]\\|\\:;"'<,>.?/]){6,}$/)
        const nameRegex = new RegExp(/^[a-zA-Z0-9\{L}]{2,}$/)
        setError('')
        setLoading(true);
        let flag = false;
        if (!taxNumberRegex.test(TaxNumber)) {
            setError("NIP musi składać się z 10 cyfr");
            flag = true;
        }
        if (!phoneRegex.test(PhoneNumber)) {
            setError("Numer telefonu musi składać się z 9 cyfr");
            flag = true;

        }
        if (!emailRegex.test(Email)) {
            setError("Niepoprawny adres email");
            flag = true;
        }
        if (!passwordRegex.test(Password) && Password.length > 0) {
            setError("Hasło powinno składać sie z conajmniej jedej cyfry i jednej litery. Minimalna długość hasła to 6 znaków");
            flag = true;

        }
        if (Password !== PasswordConfirm && Password.length > 0) {
            setError("Hasła muszą być zgodnie");
            flag = true;
        }
        if (!postCodeRegex.test(PostCode) && PostCode.length > 0) {
            setError("Kod pocztowy musi składać się z 5 znaków");
            flag = true;
        }

        if (flag) {
            setLoading(false);
            return
        } else {
            try {
                console.log(exemptionFromExciseDutyDocumentFile, exemptionFromExciseDutyDocumentName)
                await updateUser(UserName, Password, PasswordConfirm,
                    Email, CompanyName, CompanyShortName,
                    Country, State, PhoneNumber, TaxNumber,
                    Address, BuildingNumber, City, PostCode,
                    agreementForRecivingOrderStatus,
                    exemptionFromExciseDutyDocumentName,
                    exemptionFromExciseDutyDocumentFile)
                    .then(response => {
                        console.log(response);
                        if (response.status >= 200 && response.status < 300) {
                            setSuccess("dane zmienione");
                        } else {
                            setError(response.data.message)
                        }
                    });
            } catch (e) {
                console.log(e.Address)
            }
        }
        setLoading(false);
    };


    const saveFile = (e) => {
        console.log(e.target.files[0], e.target.files[0].name)
        setexemptionFromExciseDutyDocumentFile(e.target.files[0]);
        setexemptionFromExciseDutyDocumentName(e.target.files[0].name);
    }

    useEffect(() => {
        const userData = async () => {
            await getUserData(-1)
                .then(response => {
                    console.log(response)
                    setUserName(response.userName);
                    setAddress(response.address);
                    setEmail(response.email);
                    setCompanyName(response.companyName);
                    setCompanyShortName(response.companyShortName);
                    setCountry((response.country === null ? 'Polska' : response.country));
                    setState(response.state);
                    setPhoneNumber(response.phoneNumber);
                    setTaxNumber(response.taxNumber);
                    setBuildingNumber(response.buildingNumber);
                    setCity(response.city);
                    setPostCode(response.postCode);
                    setagreementForRecivingOrderStatus(response.agreementForRecivingOrderStatus);
                })

        }
        userData().catch(error => {
            setError("Wystąpił błąd podczas danych użytkownika");
            setLoading(false);
        });
    }, []);

    if (!isJwtTokenExpired()) {

        return (
            <div className="p-8 pt-0 my-8 mx-auto rounded-sm max-w-[96rem]">
                <div className="userUpdate-screen__form">
                    <div className="userUpdate-screen__form__header">
                        <center><h1 className="text-2xl font-bold md-3 max-w-md">Edycja profilu{' ' + UserName}</h1>
                        </center>
                    </div>
                    {error && <p className="error">{error}</p>}
                    <div className="userUpdate-screen__form__body">
                        <form>
                            <div className="overflow-x-auto ml-12">
                                <table className="table table-compact w-full static">
                                    <tbody>
                                    <tr className={"z-0"}>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Nazwa użytkownika</label>
                                        </td>
                                        <td><input
                                            type="text"
                                            placeholder="Nazwa użytkownika"
                                            className="input input-bordered input-sm bordered-base-100 ml-3  bg-base-100 rounded-none w-full max-w-md"
                                            value={UserName}
                                            disabled

                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Hasło</label></td>
                                        <td><input
                                            type="password"
                                            placeholder="hasło"
                                            title="min. 8 znaków, małe, wielkie litery, cyfry, znaki specjalne"
                                            className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                            value={Password}
                                            onChange={e => setPassword(e.target.value)}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Powtórz hasło</label></td>
                                        <td><input
                                            type="password"
                                            placeholder="powtórz hasło"
                                            className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                            value={PasswordConfirm}
                                            onChange={e => setPasswordConfirm(e.target.value)}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Adres E-mail</label></td>
                                        <td><input
                                            type="text"
                                            placeholder="adres email"
                                            className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                            value={Email}
                                            onChange={e => setEmail(e.target.value)}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Pełna nazwa firmy</label>
                                        </td>
                                        <td><input
                                            type="text"
                                            placeholder="Nazwa firmy"
                                            className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                            value={CompanyName}
                                            onChange={e => setCompanyName(e.target.value)}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Skrócona nazwa
                                            firma</label></td>
                                        <td><input
                                            type="text"
                                            placeholder="Skrócona nazwa firma"
                                            className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                            value={CompanyShortName}
                                            onChange={e => setCompanyShortName(e.target.value)}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Państwo</label></td>
                                        {/*<td><input*/}
                                        {/*    type="text"*/}
                                        {/*    placeholder="Państwo"*/}
                                        {/*        className="input input-bordered w-full max-w-full max-h-9"*/}
                                        {/*    value={Country}*/}
                                        {/*    onChange={e => setCountry(e.target.value)}*/}
                                        {/*    /></td></tr>*/}
                                        <td><select
                                            className="select  bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                            value={Country} onChange={e => setCountry(e.target.value)}>
                                            <option selected={Country === '' ? 'selected' : ''}>Polska</option>
                                            <option>Cypr</option>
                                            <option>Czechy</option>
                                            <option>Niemcy</option>
                                            <option>Słowacja</option>
                                        </select></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Region</label></td>
                                        {/*<td><input*/}
                                        {/*    type="text"*/}
                                        {/*    placeholder="Region"*/}
                                        {/*        className="input input-bordered w-full max-w-full max-h-9"*/}
                                        {/*    value={State}*/}
                                        {/*    onChange={e => setState(e.target.value)}*/}
                                        {
                                            String(Country).length === 0 || String(Country).includes('Pol') ?
                                                <td><select
                                                    className="select  bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                    value={State} onChange={e => setState(e.target.value)}>
                                                    <option selected={State === '' ? 'selected' : ''}>Wybierz
                                                        wojewodztwo
                                                    </option>
                                                    <option>dolnośląskie</option>
                                                    <option>kujawsko-pomorskie</option>
                                                    <option>lubelskie</option>
                                                    <option>lubuskie</option>
                                                    <option>łódzkie</option>
                                                    <option>małopolskie</option>
                                                    <option>mazowieckie</option>
                                                    <option>opolskie</option>
                                                    <option>podkarpackie</option>
                                                    <option>podlaskie</option>
                                                    <option>pomorskie</option>
                                                    <option>śląskie</option>
                                                    <option>świętokrzyskie</option>
                                                    <option>warmińsko-mazurskie</option>
                                                    <option>wielkopolskie</option>
                                                    <option>zachodniopomorskie</option>
                                                </select></td>
                                                :
                                                Country !== 'Polska' && String(Country).length > 0 ?
                                                    <td><select
                                                        className="select  bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                        value={State} onChange={e => setState(e.target.value)}>
                                                        <option>Zagranica</option>
                                                    </select></td> : <td>
                                                        <div/>
                                                    </td>
                                        } </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Numer telefonu</label>
                                        </td>
                                        <td><input
                                            type="text"
                                            placeholder="Numer telefonu"
                                            className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                            value={PhoneNumber}
                                            onChange={e => setPhoneNumber(e.target.value)}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Numer NIP(10 cyfr bez
                                            myślników)</label></td>
                                        <td><input
                                            type="text"
                                            placeholder="NIP"
                                            className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                            value={TaxNumber}
                                            onChange={e => setTaxNumber(e.target.value)}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Adres</label></td>
                                        <td><input
                                            type="text"
                                            placeholder="Adres"
                                            className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                            value={Address}
                                            onChange={e => setAddress(e.target.value)}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Numer budynku</label></td>
                                        <td><input
                                            type="text"
                                            placeholder="Numer budynku"
                                            className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                            value={BuildingNumber}
                                            onChange={e => setBuildingNumber(e.target.value)}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Miasto</label></td>
                                        <td><input
                                            type="text"
                                            placeholder="Miasto"
                                            className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                            value={City}
                                            onChange={e => setCity(e.target.value)}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Kod pocztowy</label></td>
                                        <td><input
                                            type="text"
                                            placeholder="Kod pocztowy"
                                            className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                            value={PostCode}
                                            onChange={e => setPostCode(e.target.value)}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Załącz dokument zwolnienia
                                            z akcyzy</label></td>
                                        <td><input
                                            type="file"
                                            className="file-input file-input-bordered file-input-sm w-full max-w-xs rounded-none"
                                            onChange={e => saveFile(e)}
                                            disabled={success !== ''}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Zgoda na otrzymywanie
                                            emaili o statusie zamówienia &emsp;</label></td>
                                        <td><input
                                            type="checkbox"
                                            className="checkbox rounded-none"
                                            checked={agreementForRecivingOrderStatus}
                                            onChange={e => setagreementForRecivingOrderStatus(!agreementForRecivingOrderStatus)}
                                            disabled={success !== ''}
                                        /></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex justify-end">
                                <button variant="primary"
                                        className="btn btn-md mt-2 right-3 bg-yellow-600 border-none rounded-sm disabled:bg-yellow-600 disabled:text-gray-200"
                                        type="button" disabled={loading} onClick={onUserUpdate}>


                                    {loading ? 'Ładowanie...' : 'Zapisz zmiany'}
                                </button>
                            </div>


                            {error !== "" ?
                                <div className="alert alert-error shadow-lg">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="stroke-current flex-shrink-0 h-6 w-6"
                                             fill="none"
                                             viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                        <span>{error}</span>
                                    </div>
                                </div> : <div/>
                            }
                            {success !== "" ?
                                <div className="alert alert-success shadow-lg">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="stroke-current flex-shrink-0 h-6 w-6"
                                             fill="none"
                                             viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                        <span>{success}</span>
                                    </div>
                                </div> : <div/>
                            }

                        </form>

                    </div>
                </div>
            </div>
        );
    } else {
        return (<div/>)
    }
};