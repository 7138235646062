
import { useEffect,  useState } from "react";
import { isJwtTokenExpired, isAdministrator } from "../../app/token_service";
import { useNavigate } from "react-router-dom";
import { getUserById, updateUser } from "../../app/requests/admin";
import { getTerminalList } from "../../app/requests/terminalList";


export function AdminUpdateUserScreen() {
    

    const [userDetails, setUserDetails] = useState({});
    const [terminalList, setTerminalList] = useState([]);


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const [errorDict, setErrorDict] = useState({});

    const update = async () => {
        setError('')
        setLoading(true);
        
        try {
            await updateUser(userDetails)
                    .then(response => {
                       
                            setSuccess(true);


                    }).catch((err) => {
                        console.log(err)
                        setError(err.response.data)
                    })
            } catch (e) {
                console.log(e)
                setError(e)
            }
        setLoading(false);
        }
        
    useEffect(() => {
        const userId = window.location.pathname.split("/")[2];
        setLoading(true);
        getUserById(userId).then(setUserDetails).then(() => setLoading(false)).then(() => console.log(userDetails));
        getTerminalList().then(setTerminalList).catch(() => setError("Wystąpił błąd podczas pobierania listy terminali, odśwież stronę aby spróbować ponownie"));
    }, []);

    if (!isJwtTokenExpired()) {

        return (
            <div className="p-8 pt-0 my-8 mx-auto rounded-sm max-w-[96rem]">
                <div className="userUpdate-screen__form">
                    <div className="userUpdate-screen__form__header">
                        <center><h1 className="text-2xl font-bold md-3 max-w-md">Edycja profilu{' ' + userDetails.userName}</h1></center>
                    </div>
                    {error && <p className="error">{error}</p>}
                    <div className="userUpdate-screen__form__body">
                        <form>
                            <div className="overflow-x-auto ml-12">
                                <table className="table table-compact w-full static">
                                    <tbody>
                                        <tr className={"z-0"}>
                                            <td ><label className="text-sm font-bold mt-1 text-lg">Nazwa użytkownika</label></td>
                                            <td ><input
                                                type="text"
                                                placeholder="Nazwa użytkownika"
                                                className="input input-bordered input-sm bordered-base-100 ml-3  bg-base-100 rounded-none w-full max-w-md"
                                                value={userDetails.userName}
                                                disabled

                                            /></td></tr>
                                        <tr>
                                            <td><label className="text-sm font-bold mt-1 text-lg">Symfonia ID</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Symfonia ID"
                                                className="input input-bordered input-sm bordered-base-100 ml-3  bg-base-100 rounded-none w-full max-w-md"
                                                value={userDetails.symfoniaId}
                                                disabled

                                            /></td>
                                        </tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Adres E-mail</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="adres email"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={userDetails.email}
                                                
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Pełna nazwa firmy</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Nazwa firmy"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={userDetails.companyName}
                                                onChange={(e) => {
                                                    setUserDetails(
                                                            {
                                                                ...userDetails,
                                                                companyName: e.target.value
                                                            }
                                                    )
                                                }
                                                    
                                                }
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Skrócona nazwa firma</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Skrócona nazwa firma"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={userDetails.companyShortName}
                                                onChange={(e) => {
                                                    setUserDetails(
                                                        {
                                                            ...userDetails,
                                                            companyShortName: e.target.value
                                                        }
                                                    )
                                                }

                                                }
                                                
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Państwo</label></td>
                                            {/*<td><input*/}
                                            {/*    type="text"*/}
                                            {/*    placeholder="Państwo"*/}
                                            {/*        className="input input-bordered w-full max-w-full max-h-9"*/}
                                            {/*    value={Country}*/}
                                            {/*    onChange={e => setCountry(e.target.value)}*/}
                                            {/*    /></td></tr>*/}
                                            <td><select className="select  bordered-base-100 ml-3 rounded-none w-full max-w-md" value={userDetails.country}
                                            onChange={(e) => {
                                                setUserDetails(
                                                    {
                                                        ...userDetails,
                                                        country: e.target.value
                                                    }
                                                )
                                            }

                                            }                                            >
                                                <option selected={userDetails.country === '' ? 'selected' : ''}>Polska</option>
                                                <option>Cypr</option>
                                                <option>Czechy</option>
                                                <option>Niemcy</option>
                                                <option>Słowacja</option>
                                            </select></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Region</label></td>
                                            {/*<td><input*/}
                                            {/*    type="text"*/}
                                            {/*    placeholder="Region"*/}
                                            {/*        className="input input-bordered w-full max-w-full max-h-9"*/}
                                            {/*    value={State}*/}
                                            {/*    onChange={e => setState(e.target.value)}*/}
                                            {
                                                String(userDetails.country).length === 0 || String(userDetails.country).includes('Pol') ?
                                                    <td><select className="select  bordered-base-100 ml-3 rounded-none w-full max-w-md" value={userDetails.state}
                                                        onChange={(e) => {
                                                            setUserDetails(
                                                                {
                                                                    ...userDetails,
                                                                    state: e.target.value
                                                                }
                                                            )
                                                        }

                                                        }>
                                                        <option selected={userDetails.state === '' ? 'selected' : ''}>Wybierz wojewodztwo</option>
                                                        <option>dolnośląskie</option>
                                                        <option>kujawsko-pomorskie</option>
                                                        <option>lubelskie</option>
                                                        <option>lubuskie</option>
                                                        <option>łódzkie</option>
                                                        <option>małopolskie</option>
                                                        <option>mazowieckie</option>
                                                        <option>opolskie</option>
                                                        <option>podkarpackie</option>
                                                        <option>podlaskie</option>
                                                        <option>pomorskie</option>
                                                        <option>śląskie</option>
                                                        <option>świętokrzyskie</option>
                                                        <option>warmińsko-mazurskie</option>
                                                        <option>wielkopolskie</option>
                                                        <option>zachodniopomorskie</option>
                                                    </select></td>
                                                    :
                                                    userDetails.country !== 'Polska' && String(userDetails.country).length > 0 ?
                                                        <td><select className="select  bordered-base-100 ml-3 rounded-none w-full max-w-md" value={userDetails.state}  >
                                                            <option>Zagranica</option>
                                                        </select></td> : <td><div /></td>
                                            } </tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Numer telefonu</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Numer telefonu"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={userDetails.phoneNumber}
                                                onChange={(e) => {
                                                    setUserDetails(
                                                        {
                                                            ...userDetails,
                                                            phoneNumber: e.target.value
                                                        }
                                                    )
                                                }

                                                }
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Numer NIP(10 cyfr bez myślników)</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="NIP"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={userDetails.taxNumber}
                                                onChange={(e) => {
                                                    setUserDetails(
                                                        {
                                                            ...userDetails,
                                                            taxNumber: e.target.value
                                                        }
                                                    )
                                                }

                                                }
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Adres</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Adres"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={userDetails.address}
                                                onChange={(e) => {
                                                    setUserDetails(
                                                        {
                                                            ...userDetails,
                                                            address: e.target.value
                                                        }
                                                    )
                                                }
                                                }
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Numer budynku</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Numer budynku"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={userDetails.buildingNumber}
                                                onChange={(e) => {
                                                    setUserDetails(
                                                        {
                                                            ...userDetails,
                                                            buildingNumber: e.target.value
                                                        }
                                                    )
                                                }
                                                }
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Miasto</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Miasto"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={userDetails.city}
                                                onChange={(e) => {
                                                    setUserDetails(
                                                        {
                                                            ...userDetails,
                                                            city: e.target.value
                                                        }
                                                    )
                                                }
                                                }
                                            /></td></tr>
                                        <tr><td><label className="text-sm font-bold mt-1 text-lg">Kod pocztowy</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Kod pocztowy"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={userDetails.postCode}
                                                onChange={(e) => {
                                                    setUserDetails(
                                                        {
                                                            ...userDetails,
                                                            postCode: e.target.value
                                                        }
                                                    )
                                                }
                                                }
                                            /></td></tr>
                                        <tr>
                                            <td><label className="text-sm font-bold mt-1 text-lg">ROLE</label></td>
                                            <td>
                                                <table className="table table-compact static">
                                                    <tbody>
                                                        <tr>
                                                            <td>Klient</td>
                                                            <td><input type="checkbox" checked={userDetails.isKlient ? "checked" : null} className="checkbox"
                                                            onChange={(e) => {
                                                                    setUserDetails(
                                                                        {
                                                                            ...userDetails,
                                                                            isKlient: e.target.checked
                                                                        }
                                                                    )
                                                                }
                                                             }
                                                              /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Trader</td>
                                                            <td><input type="checkbox" checked={userDetails.isTrader ? "checked" : null} className="checkbox" onChange={(e) => {
                                                                setUserDetails(
                                                                    {
                                                                        ...userDetails,
                                                                        isTrader: e.target.checked
                                                                    }
                                                                )
                                                            }
                                                            }/></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Terminal</td>
                                                            <td><input type="checkbox" checked={userDetails.isTerminal ? "checked" : null} className="checkbox" onChange={(e) => {
                                                                setUserDetails(
                                                                    {
                                                                        ...userDetails,
                                                                        isTerminal: e.target.checked
                                                                    }
                                                                )
                                                            }
                                                            }/></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Logistyk</td>
                                                            <td><input type="checkbox" checked={userDetails.isLogistyk ? "checked" : null} className="checkbox" onChange={(e) => {
                                                                setUserDetails(
                                                                    {
                                                                        ...userDetails,
                                                                        isLogistyk: e.target.checked
                                                                    }
                                                                )
                                                            }
                                                            }/></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Administrator</td>
                                                            <td><input type="checkbox" checked={userDetails.isAdministrator ? "checked" : null} className="checkbox" onChange={(e) => {
                                                                setUserDetails(
                                                                    {
                                                                        ...userDetails,
                                                                        isAdministrator: e.target.checked
                                                                    }
                                                                )
                                                            }
                                                            }/></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        {
                                            userDetails.isTerminal ?
                                                <tr>
                                                    <td><label className="text-sm font-bold mt-1 text-lg">Terminal</label></td>
                                                    <td>
                                                        <select id="terminal_choice_list"
                                                            className={"select select-sm w-full border-gray-400 rounded-none"}
                                                            disabled={success}
                                                            value={userDetails.terminalId}
                                                            onChange={(e) => {
                                                                setUserDetails(
                                                                    {
                                                                        ...userDetails,
                                                                        terminalId: e.target.value
                                                                    }
                                                                )
                                                            }
                                                            }
                                                            >
                                                            <option defaultValue value={-1}>Wybierz Terminal</option>
                                                            {
                                                                terminalList.map((terminal) => (
                                                                    <option key={"terminal_" + terminal.id}
                                                                        value={Number(terminal.id)}>{terminal.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        </td>
                                                </tr>
                                                :
                                                null
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex justify-end">
                                <button variant="primary" className="btn btn-md mt-2 right-3 bg-yellow-600 border-none rounded-sm disabled:bg-yellow-600 disabled:text-gray-200" type="button" disabled={loading} onClick={update} >


                                    {loading ? 'Ładowanie...' : 'Zapisz zmiany'}
                                </button>
                            </div>


                            {error !== "" ?
                                <div className="alert alert-error shadow-lg">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <span>{error}</span>
                                    </div>
                                </div> : <div />
                            }
                            {success ?
                                <div className="alert alert-success shadow-lg">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <span>Dane zostały zmienione</span>
                                    </div>
                                </div> : <div />
                            }

                        </form>

                    </div>
                </div>
            </div>
        );
    }
    else {
        return (<div />)
    }
}