import pd from '../assets/pd.png';

export function Footer() {
    return (<footer className="footer footer-center items-center p-4 bg-neutral text-neutral-content fixed bottom-0">
        <div className="items-center grid-flow-col">
            <p className="float-left">Projekt i wykonanie</p>
            <a href={"http://www.profidata.com.pl/"} target={"_blank"}> <img src={pd} alt="pd_logo"
                                                                             className="w-4 h-4 mr-1 float-left"/>
                <p className="float-right"> Profi-Data Sp. z o.o.</p></a>
            <p className="">v. 1.005</p>
        </div>
    </footer>)
}