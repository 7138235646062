import {getJwtToken} from "../token_service";
import axios, {put} from "axios";
import {API_URL} from "../constants";
import {refreshJwtToken} from "../../app/token_service";

export async function getCustomerList() {
    let config = {
        method: 'get',
        url: API_URL + '/api/user/clients',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}


export async function getAllUsersAdmin() {
    let config = {
        method: 'get',
        url: API_URL + '/api/user/all',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

export function getUsersBySearchCriteria(searchCriteria) {
    if (searchCriteria.DateFrom === "") {
        searchCriteria.DateFrom = "2020-01-01";
    }
    if (searchCriteria.DateTo === "") {
        searchCriteria.DateTo = "2100-01-01";
    }

    let data = JSON.stringify(searchCriteria);
    let config = {
        method: 'post',
        url: API_URL + '/api/user/search',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true,
        data: data
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}


export function getNewClients() {


    let config = {
        method: 'GET',
        url: API_URL + '/api/user/getNewClients',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function getBlockedUsers() {


    let config = {
        method: 'GET',
        url: API_URL + '/api/user/getBlockedUsers',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function acceptUser(id) {

    let config = {
        method: 'POST',
        url: API_URL + '/api/user/acceptClient/' + id,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function addSymfoniaUser(id) {
    let config = {
        method: 'POST',
        url: API_URL + '/api/user/addSymfoniaUser/'+id,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

export function VerifyUserClient(id) {

    let config = {
        method: 'POST',
        url: API_URL + '/api/user/verifyClientEmail/' + id,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function blockUser(id) {


    let config = {
        method: 'POST',
        url: API_URL + '/api/user/blockAccount/' + id,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function updateUserPricelist(usrId, priceListId) {
    let config = {
        method: 'post',
        url: API_URL + '/api/user/setPriceList/' + usrId + '/' + priceListId,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function updateUserPaymentTerm(usrId, paymentTerm) {
    let config = {
        method: 'post',
        url: API_URL + '/api/user/setPaymentTerm/' + usrId + '/' + paymentTerm,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function downloadFile(id, filename) {
    let config = {
        method: 'get',
        url: API_URL + '/api/file/userFile/' + id,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true,
        responseType: 'blob'
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function AcceptStatute() {
    let config = {
        method: 'put',
        url: API_URL + '/api/user/acceptStatuteUser',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(() => {
                setTimeout(() => {
                    console.log("odswiezanie tokenu")
                }, 500);

            })
            .then(() => {
                refreshJwtToken();

            })
            .then(() => {

                setTimeout(() => {
                    window.location.href = "/";
                }, 2500);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

export async function AdminLoginAs(usrId) {
    let config = {
        method: 'post',
        url: API_URL + '/api/admin/loginas/' + usrId,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                console.log(response);
                resolve(response);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}