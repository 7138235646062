import axios from "axios";
import {getJwtToken} from "../token_service";
import {API_URL} from "../constants";

export function createOrder(order, files) {
    let err = null;
    let resp = null;

    Promise.all(
        files.map(file => new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                resolve({
                    content: fileReader.result.split(',')[1],
                    name: file.name,
                });
            };

            fileReader.onerror = (error) => reject(error);

            fileReader.readAsDataURL(file);
        }))).then((files) => {
            let data = JSON.stringify({
                ...order,
                Files: files.map(file => {
                    return {FileName: file.name, FileContent: btoa(file.content)}
                })
            });

            let config = {
                method: 'post',
                url: `${API_URL}/api/order`,
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': "Bearer " + getJwtToken()
                },
                data: data,
                withCredentials: true
            };


            axios(config)
                .then(function (response) {
                    resp = response;
                })
                .catch(function (error) {
                    err = error;
                })
        }
    )
    return new Promise(async (resolve, reject) => {
            for (let i = 0; i < 100000; i++) {
                if (err) {
                    reject(err);
                }
                if (resp) {
                    resolve(resp);
                }
                await sleep(100);
            }
        }
    )
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function updateOrder(order, files) {
    let err = null;
    let resp = null;

    Promise.all(
        files.map(file => new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                resolve({
                    content: fileReader.result.split(',')[1],
                    name: file.name,
                });
            };

            fileReader.onerror = (error) => reject(error);

            fileReader.readAsDataURL(file);
        })))
        .then((files) => {
            let orderFiles = order.Files.concat(files.map(f => {
                return {FileName: f.name, FileContent: btoa(f.content)}
            }));
            let data = JSON.stringify({
                ...order,
                Files: orderFiles
            });
            let config = {
                method: 'put',
                url: API_URL + '/api/order',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Bearer ' + getJwtToken()
                },
                data: data,
                withCredentials: true
            };

            axios(config)
                .then(function (response) {
                    resp = response;
                })
                .catch(function (error) {
                    err = error;
                })
        })
    
    return new Promise(async (resolve, reject) => {
            for (let i = 0; i < 100000; i++) {
                if (err) {
                    reject(err);
                }
                if (resp) {
                    resolve(resp);
                }
                await sleep(100);
            }
        }
    )
}

export function getOrderPrice(order) {
    let data = JSON.stringify(order);
    let config = {
        method: 'post',
        url: API_URL + '/api/order/pricing',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        data: data,
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function getOrdersBySearchCriteria(searchCriteria, page = 1) {
    if (searchCriteria.WeightFrom === "") {
        searchCriteria.WeightFrom = 0;
    }
    if (searchCriteria.WeightTo === "") {
        searchCriteria.WeightTo = 100000;
    }
    if (searchCriteria.DateFrom === "") {
        searchCriteria.DateFrom = "2020-01-01";
    }
    if (searchCriteria.DateTo === "") {
        searchCriteria.DateTo = "2100-01-01";
    }

    let data = JSON.stringify(searchCriteria);
    let config = {
        method: 'post',
        url: API_URL + '/api/order/search/page/' + page,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        data: data,
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function getPages(searchCriteria, page = 1) {
    if (searchCriteria.WeightFrom === "") {
        searchCriteria.WeightFrom = 0;
    }
    if (searchCriteria.WeightTo === "") {
        searchCriteria.WeightTo = 100000;
    }
    if (searchCriteria.DateFrom === "") {
        searchCriteria.DateFrom = "2020-01-01";
    }
    if (searchCriteria.DateTo === "") {
        searchCriteria.DateTo = "2100-01-01";
    }

    let data = JSON.stringify(searchCriteria);
    let config = {
        method: 'post',
        url: API_URL + '/api/order/search/pages',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        data: data,
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function getOrderById(orderId) {
    let config = {
        method: 'get',
        url: API_URL + '/api/order/' + orderId,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
            axios(config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                })
        }
    );
}

export function acceptOrder(orderId) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/accept/' + orderId,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function rejectOrder(orderId) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/reject/' + orderId,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
            axios(config)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        }
    );
}

export function cancelOrder(orderId) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/cancel/' + orderId,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
            axios(config)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        }
    );
}

export function renewOrder(orderId) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/renew/' + orderId,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
            axios(config)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        }
    );
}

export function completeOrder(orderId) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/complete/' + orderId,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
            axios(config)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        }
    );
}

export function completeOrderDriver(orderId, confirmationId, tons, date) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/completeDriver/' + orderId + '/' + confirmationId + '/' + tons + '/' + date,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
            axios(config)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        }
    );
}

export function downloadOrderFile(id, name) {
    let config = {
        method: 'get',
        url: API_URL + '/api/file/order/' + id,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true,
        responseType: 'blob'
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function syncWithSymfonia(ids) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/symfoniaAdd',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: ids
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function syncWithSymfoniaAll() {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/symfoniaAddAll',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function syncWithSymfoniaMerged(ids) {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/symfoniaAddMerged',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: ids
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function syncWithSymfoniaMergedAll() {
    let config = {
        method: 'post',
        url: API_URL + '/api/order/symfoniaAddMergedAll',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });    
}
export function terminalAddMerged(){
    let config = {
        method: 'post',
        url: API_URL + '/api/order/symfoniaAddMergedTerminal',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}
export function symfoniaGetTerminal(){
    let config = {
        method: 'get',
        url: API_URL + '/api/order/symfoniaGetTerminal',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}
