import {useEffect, useState} from "react";
import {getCustomerList} from "../../app/requests/user";
import {getProductsList} from "../../app/requests/productsList";
import {getTerminalList} from "../../app/requests/terminalList";
import {issuesJson, issuesPdf, issuesXls} from "../../app/requests/reports";
import order from "../../assets/icons/order.png";
import {getJwtTokenCompany, getJwtTokenTerminalId} from "../../app/token_service";


function initialSearch() {
    return {
        OrderCreationDateFrom: '',
        OrderCreationDateTo: '',
        OrderPickDateFrom: '',
        OrderPickDateTo: '',
        OrderStatuses: [],
        ClientCompanyName: window.location.pathname.split('/')[3] === 'client' ? getJwtTokenCompany() : '',
        Terminals: [],
        Products: []
    }
}

const IssueReportScreen = () => {
    const [userType, setUserType] = useState('');

    const [search, setSearch] = useState(initialSearch());
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [products, setProducts] = useState([]);
    const [terminals, setTerminals] = useState([]);
    const [customerList, setCustomerList] = useState([]);

    useEffect(() => {
            if (window.location.pathname.split('/')[3] !== 'client') {
                getCustomerList().then(setCustomerList).catch(() => setError("Wystąpił błąd podczas pobierania listy klientów, odśwież stronę aby spróbować ponownie"));
            }
            getProductsList().then(setProducts).catch(() => setError("Wystąpił błąd podczas pobierania listy produktów, odśwież stronę aby spróbować ponownie"));
            getTerminalList().then((result) => {
                setTerminals(result);
                if (window.location.pathname.split('/')[3] === 'terminal') {
                    let terminalId = Number(getJwtTokenTerminalId());
                    setSearch({...search, Terminals: [result.find(r => r.id === terminalId).name]});
                }}).catch((e) => setError(e + "Wystąpił błąd podczas pobierania listy terminali, odśwież stronę aby spróbować ponownie"));
            setUserType(window.location.pathname.split('/')[3]);
        }, []
    )

    function fetchData() {
        setLoading(true);
        setError('');
        setResult([]);
        issuesJson({
            ...search,
            OrderCreationDateFrom: search.OrderCreationDateFrom === "" ? null : search.OrderCreationDateFrom
            , OrderCreationDateTo: search.OrderCreationDateTo === "" ? null : search.OrderCreationDateTo
            , OrderPickDateFrom: search.OrderPickDateFrom === "" ? null : search.OrderPickDateFrom
            , OrderPickDateTo: search.OrderPickDateTo === "" ? null : search.OrderPickDateTo
        })
            .then((data) => {
                setResult(data);
                console.log(data)
            }).catch(() => setError("Wystąpił błąd podczas pobierania danych, spróbuj ponownie")).finally(() => setLoading(false));
    }

    function mapStatus(status) {
        switch (status) {
            case 0:
                return "Nowe"
            case 1:
                return "Zaaakceptowane"
            case 2:
                return "Zrealizowane"
            case 3:
                return "Anulowane"
            case 4:
                return "Odrzucone"
            case 5:
                return "Przedawnione"
            default:
                return "Nieznany"
        }
    }

    return (
        <div className={"p-8"}>
            <h1 className={"text-2xl font-bold md-3 max-w-md mb-5"}>Raport wydań samochodowych</h1>
            <div className={"grid grid-cols-1 md:grid-cols-3"}>
                <div className={"inline-grid max-w-md mx-2 px-2 mb-4"}>
                    <span className={"text-sm font-bold"}>
                        Klient
                    </span>
                    <input type="text"
                           className="input input-sm border border-gray-400 rounded-none w-full"
                           value={search.ClientCompanyName}
                           disabled={userType === 'client'}
                           onChange={e => {
                               setSearch({...search, ClientCompanyName: e.target.value})
                               console.log(e.target.value)
                           }}
                           list={'customer-selection'}/>
                    <datalist id={'customer-selection'}>
                        {customerList.map(c => {
                            return <option id={"selection" + c.id} key={c.id} value={c.companyName}/>
                        })}
                    </datalist>
                </div>
                <div className={"inline-grid max-w-md mx-2 px-2 mb-4"}>
                    <span className={"text-sm font-bold"}>
                        Data utworzenia zamówienia od
                    </span>
                    <input type="date"
                           className="input input-sm border border-gray-400 rounded-none w-full"
                           value={search.OrderCreationDateFrom}
                           onChange={e => {
                               setSearch({...search, OrderCreationDateFrom: e.target.value})
                               console.log(e.target.value)
                           }}
                    />
                </div>
                <div className={"inline-grid max-w-md mx-2 px-2 mb-4"}>
                    <span className={"text-sm font-bold"}>
                        Data utworzenia zamówienia do
                    </span>
                    <input type="date"
                           className="input input-sm border border-gray-400 rounded-none w-full"
                           value={search.OrderCreationDateTo}
                           onChange={e => {
                               setSearch({...search, OrderCreationDateTo: e.target.value})
                               console.log(e.target.value)
                           }}
                    />
                </div>
                <div className={"inline-grid max-w-md mx-2 px-2 mb-4"}>
                    <span className={"text-sm font-bold"}>
                        Status
                    </span>
                    <div className={"collapse"}>
                        <input type="checkbox"/>
                        <div className={"collapse-title text-sm"}>
                            <label>{search.OrderStatuses.length === 0 ? "Wszystkie" : search.OrderStatuses.map(s => mapStatus(s) + " ")}</label>
                        </div>
                        <div className={"collapse-content"}>
                            <div className={"grid grid-cols-1"}>
                                <div className={"inline-grid mx-1 px-1 mb-1"}>
                                    <nobr>
                                        <input type={"checkbox"} checked={search.OrderStatuses.includes(0)}
                                               className={"checkbox checkbox-sm"}
                                               onChange={e => {
                                                   if (e.target.checked) {
                                                       setSearch({
                                                           ...search,
                                                           OrderStatuses: [...search.OrderStatuses, 0]
                                                       })
                                                   } else {
                                                       setSearch({
                                                           ...search,
                                                           OrderStatuses: search.OrderStatuses.filter(s => s !== 0)
                                                       })
                                                   }
                                               }}/>
                                        <label className={"ml-2"}>Nowe</label>
                                    </nobr>
                                < /div>
                                <div className={"inline-grid mx-1 px-1 mb-1"}>
                                    <nobr>
                                        <input type={"checkbox"} checked={search.OrderStatuses.includes(1)}
                                               className={"checkbox checkbox-sm"}
                                               onChange={e => {
                                                   if (e.target.checked) {
                                                       setSearch({
                                                           ...search,
                                                           OrderStatuses: [...search.OrderStatuses, 1]
                                                       })
                                                   } else {
                                                       setSearch({
                                                           ...search,
                                                           OrderStatuses: search.OrderStatuses.filter(s => s !== 1)
                                                       })
                                                   }
                                               }}/>
                                        <label className={"ml-2"}>Zaaakceptowane</label>
                                    </nobr>
                                </div>
                                <div className={"inline-grid mx-1 px-1 mb-1"}>
                                    <nobr>
                                        <input type={"checkbox"} checked={search.OrderStatuses.includes(2)}
                                               className={"checkbox checkbox-sm"}
                                               onChange={e => {
                                                   if (e.target.checked) {
                                                       setSearch({
                                                           ...search,
                                                           OrderStatuses: [...search.OrderStatuses, 2]
                                                       })
                                                   } else {
                                                       setSearch({
                                                           ...search,
                                                           OrderStatuses: search.OrderStatuses.filter(s => s !== 2)
                                                       })
                                                   }
                                               }}/>
                                        <label className={"ml-2"}>Zrealizowane</label>
                                    </nobr>
                                </div>
                                <div className={"inline-grid mx-1 px-1 mb-1"}>
                                    <nobr>
                                        <input type={"checkbox"} checked={search.OrderStatuses.includes(3)}
                                               className={"checkbox checkbox-sm"}
                                               onChange={e => {
                                                   if (e.target.checked) {
                                                       setSearch({
                                                           ...search,
                                                           OrderStatuses: [...search.OrderStatuses, 3]
                                                       })
                                                   } else {
                                                       setSearch({
                                                           ...search,
                                                           OrderStatuses: search.OrderStatuses.filter(s => s !== 3)
                                                       })
                                                   }
                                               }}/>
                                        <label className={"ml-2"}>Anulowane</label>
                                    </nobr>
                                </div>
                                <div className={"inline-grid mx-1 px-1 mb-1"}>
                                    <nobr>
                                        <input type={"checkbox"} checked={search.OrderStatuses.includes(4)}
                                               className={"checkbox checkbox-sm"}
                                               onChange={e => {
                                                   if (e.target.checked) {
                                                       setSearch({
                                                           ...search,
                                                           OrderStatuses: [...search.OrderStatuses, 4]
                                                       })
                                                   } else {
                                                       setSearch({
                                                           ...search,
                                                           OrderStatuses: search.OrderStatuses.filter(s => s !== 4)
                                                       })
                                                   }
                                               }}/>
                                        <label className={"ml-2"}>Odrzucone</label>
                                    </nobr>
                                </div>
                                <div className={"inline-grid mx-1 px-1 mb-1"}>
                                    <nobr>
                                        <input type={"checkbox"} checked={search.OrderStatuses.includes(5)}
                                               className={"checkbox checkbox-sm"}
                                               onChange={e => {
                                                   if (e.target.checked) {
                                                       setSearch({
                                                           ...search,
                                                           OrderStatuses: [...search.OrderStatuses, 5]
                                                       })
                                                   } else {
                                                       setSearch({
                                                           ...search,
                                                           OrderStatuses: search.OrderStatuses.filter(s => s !== 5)
                                                       })
                                                   }
                                               }}/>
                                        <label className={"ml-2"}>Przedawnione</label>
                                    </nobr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"inline-grid max-w-md mx-2 px-2 mb-4"}>
                    <span className={"text-sm font-bold"}>
                        Data realizacji zamówienia od
                    </span>
                    <input type="date"
                           className="input input-sm border border-gray-400 rounded-none w-full"
                           value={search.OrderPickDateFrom}
                           onChange={e => {
                               setSearch({...search, OrderPickDateFrom: e.target.value})
                           }}
                    />
                </div>
                <div className={"inline-grid max-w-md mx-2 px-2 mb-4"}>
                    <span className={"text-sm font-bold"}>
                        Data realizacji zamówienia do
                    </span>
                    <input type="date"
                           className="input input-sm border border-gray-400 rounded-none w-full"
                           value={search.OrderPickDateTo}
                           onChange={e => {
                               setSearch({...search, OrderPickDateTo: e.target.value})
                           }}
                    />
                </div>
                <div className={"inline-grid max-w-md mx-2 px-2 mb-4"}>
                    <span className={"text-sm font-bold"}>
                        Terminale
                    </span>
                    <div className={"collapse"}>
                        <input type={"checkbox"}
                               disabled={userType === "terminal"}
                        />
                        <div className={"collapse-title text-sm"}>
                            <label>{search.Terminals.length === 0 ? "Wszystkie" : search.Terminals.map(t => t + ", ")}</label>
                        </div>
                        <div className={"collapse-content"}>
                            <div className={"grid grid-cols-2 gap-2"}>
                                <div className={"inline-grid mx-1 px-1 mb-1"}>
                                    {terminals.map(t =>
                                        <>
                                            <nobr>
                                                <input type={"checkbox"} checked={search.Terminals.includes(t.name)}
                                                       className={"checkbox checkbox-sm"}
                                                       onChange={e => {
                                                           if (e.target.checked) {
                                                               setSearch({
                                                                   ...search,
                                                                   Terminals: [...search.Terminals, t.name]
                                                               })
                                                           } else {
                                                               setSearch({
                                                                   ...search,
                                                                   Terminals: search.Terminals.filter(s => s !== t.name)
                                                               })
                                                           }
                                                       }}/>
                                                <label className={"ml-2"}>{t.name}</label>
                                            </nobr>
                                        </>
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"inline-grid max-w-md mx-2 px-2 mb-4"}>
                    <span className={"text-sm font-bold"}>
                        Produkty
                    </span>
                    <div className={"collapse"}>
                        <input type={"checkbox"}/>
                        <div className={"collapse-title text-sm"}>
                            <label>{search.Products.length === 0 ? "Wszystkie" : search.Products.map(t => t + ", ")}</label>
                        </div>
                        <div className={"collapse-content"}>
                            <div className={"grid grid-cols-2 gap-2"}>
                                <div className={"inline-grid mx-1 px-1 mb-1"}>
                                    {products.map(t =>
                                        <>
                                            <nobr>
                                                <input type={"checkbox"} checked={search.Products.includes(t.name)}
                                                       className={"checkbox checkbox-sm"}
                                                       onChange={e => {
                                                           if (e.target.checked) {
                                                               setSearch({
                                                                   ...search,
                                                                   Products: [...search.Products, t.name]
                                                               })
                                                           } else {
                                                               setSearch({
                                                                   ...search,
                                                                   Products: search.Products.filter(s => s !== t.name)
                                                               })
                                                           }
                                                       }}/>
                                                <label className={"ml-2"}>{t.name}</label>
                                            </nobr>
                                        </>
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"inline-grid max-w-md mx-2 px-2 mb-4"}>
                    <button className={"btn btn-sm btn-ghost"} onClick={fetchData}>
                        Generuj
                    </button>
                    <button className={"btn btn-sm btn-ghost"} onClick={() => {
                        setError("");
                        issuesPdf({
                            ...search,
                            OrderCreationDateFrom: search.OrderCreationDateFrom === "" ? null : search.OrderCreationDateFrom
                            , OrderCreationDateTo: search.OrderCreationDateTo === "" ? null : search.OrderCreationDateTo
                            , OrderPickDateFrom: search.OrderPickDateFrom === "" ? null : search.OrderPickDateFrom
                            , OrderPickDateTo: search.OrderPickDateTo === "" ? null : search.OrderPickDateTo
                        }).then().catch(() => setError("Błąd podczas pobierania pliku"));
                    }}>
                        Pobierz PDF
                    </button>
                    <button className={"btn btn-sm btn-ghost"} onClick={() => {
                        setError('');
                        issuesXls({
                            ...search,
                            OrderCreationDateFrom: search.OrderCreationDateFrom === "" ? null : search.OrderCreationDateFrom
                            , OrderCreationDateTo: search.OrderCreationDateTo === "" ? null : search.OrderCreationDateTo
                            , OrderPickDateFrom: search.OrderPickDateFrom === "" ? null : search.OrderPickDateFrom
                            , OrderPickDateTo: search.OrderPickDateTo === "" ? null : search.OrderPickDateTo
                        }).then().catch(() => setError("Błąd podczas pobierania pliku"));
                    }}>
                        Pobierz XLS
                    </button>
                </div>
            </div>
            {error !== "" && <div className={"alert alert-error"}>{error}</div>}
            <table className="table table-compact w-full rounded-none static">
                <thead className="rounded-none">
                <tr key="head" className="rounded-none">
                    <th className="rounded-none w-40">Nr zamówienia</th>
                    <th className="rounded-none w-max min-w-96">Nazwa firmy klienta</th>
                    <th className="rounded-none w-28">Status</th>
                    <th className="rounded-none w-48">Terminal</th>
                    <th className="rounded-none w-20">Data od</th>
                    <th className="rounded-none w-20">Data do</th>
                    <th className="rounded-none w-24">Produkt</th>
                    <th className="rounded-none w-24">Zamówione [t]</th>
                    <th className="rounded-none w-24">Zrealizowane [t]</th>
                    <th className="rounded-none w-24">Netto [PLN/t]</th>
                </tr>
                </thead>
                <tbody className={"text-xs"}>
                {loading ?
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                    </div>
                    : null}
                {result !== undefined ? result.map(o => <>
                    <tr key={"order" + o.orderNumber}>
                        <td className="w-36">{o.orderNumber}</td>
                        <td className="whitespace-normal">{o.client.companyName || "Brak firmy"}</td>
                        <td>{o.orderStatus}</td>
                        <td>{o.terminal.name}</td>
                        <td>{o.orderPickDateFrom.substring(0, 10)}</td>
                        <td>{o.orderPickDateTo.substring(0, 10)}</td>
                        <td>{o.product.name}</td>
                        <td className="text-right">{o.quantityTonsOrdered}</td>
                        <td className="text-right">{o.quantityTonsDelivered}</td>
                        <td className="text-right">{o.priceNetPerTon}</td>
                    </tr>
                </>) : null}
                </tbody>
            </table>
        </div>
    )
}

export default IssueReportScreen;