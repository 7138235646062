import {useEffect, useState} from "react";
import {
    deletePriceList,
    downloadPriceList,
    getMyPriceList,
    getPriceList,
    getPriceLists
} from "../../app/requests/priceLists";
import {getTerminalList} from "../../app/requests/terminalList";
import {getProductsList} from "../../app/requests/productsList";
import {useNavigate} from "react-router-dom";

export default function MyPriceListScreen() {
    const [priceList, setPriceList] = useState([]);
    const [terminals, setTerminals] = useState([]);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        Promise.all(
            [getMyPriceList().then(setPriceList).catch(setError),
                getTerminalList().then(setTerminals).catch(setError),
                getProductsList().then(setProducts).catch(setError)]
        ).finally(() => setIsLoading(false));
    }, []);

    return (
        <div
            className={"p-8 px-8 md:px-16"}>
            <h1 className={"text-2xl font-bold md-3 max-w-lg mb-5"}>{'Cennik: ' + priceList.priceListName}</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 border-[1px]">
                <span className={"text-sm font-bold bg-gray-300"}>Id</span>
                <span className={"text-sm  bg-gray-300"}>{priceList.id}</span>
                <span className={"text-sm font-bold"}>Nazwa</span>
                <span className={"text-sm"}>{priceList.priceListName}</span>
            </div>
            <br/>

            <h2 className={"text-xl font-bold md-3 max-w-lg mb-5 mt-5"}>Towary</h2>
            {isLoading ? <div className={"text-center"}>Ładowanie...</div> :
                <div className={"mt-5 overflow-x-auto"}>
                    <table className={"table table-compact w-full rounded-none static"}>
                        <thead className={"rounded-none"}>
                        <tr key={"head"} className={"rounded-none"}>
                            <th className={"rounded-none w-16"}>Towar</th>
                            {terminals.map((terminal) => (
                                <th key={terminal.id} className={"rounded-none"}
                                    title={terminal.name}
                                >{terminal.shortName}</th>
                            ))}
                        </tr>

                        {products.map((product) => (
                            <tr key={product.id}>
                                <td>{product.name}</td>
                                {terminals.map((terminal) => (
                                    <td key={terminal.id}>{priceList.productPrices.find(position => position.productId === product.id && position.terminalId === terminal.id)?.price
                                    }</td>
                                ))}
                            </tr>
                        ))}

                        </thead>
                    </table>
                </div>
            }

            <h2 className={"text-xl font-bold md-3 max-w-lg mb-5 mt-5"}>Terminy</h2>
            {isLoading ? <div className={"text-center"}>Ładowanie...</div> :
                <div className={"mt-5 overflow-x-auto"}>
                    <table className={"table table-compact w-full rounded-none static"}>
                        <thead className={"rounded-none"}>
                        <tr key={"head"} className={"rounded-none"}>
                            <th className={"rounded-none w-16"}>Dni</th>
                            {terminals.map((terminal) => (
                                <th key={terminal.id} className={"rounded-none"}
                                    title={terminal.name}
                                >{terminal.shortName}</th>
                            ))}
                        </tr>

                        {[0, 7, 14, 21, 30].map((paymentDays) => (
                            <tr key={paymentDays}>
                                <td>{paymentDays === 0 ? "Przedpłata" : paymentDays + " dni"}</td>
                                {terminals.map((terminal) => (
                                    <td key={terminal.id}>
                                        {priceList.paymentDaysPricings.find(position => position.playerDays === paymentDays && position.terminalId === terminal.id)?.price}
                                    </td>
                                ))}
                            </tr>
                        ))}

                        </thead>
                    </table>
                </div>
            }
        </div>


    )
}
