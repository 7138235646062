import axios from "axios";
import {API_URL} from "../constants";
import {getJwtToken} from "../token_service";


export async function getTerminalList() {
    let config = {
        method: 'get',
        url: API_URL + '/api/terminal',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function getAllTerminals() {
    let config = {
        method: 'get',
        url: API_URL + '/api/terminal/list',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function terminalGetLastShift() {
    let config = {
        method: 'get',
        url: API_URL + '/api/terminal/getLastShift',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function terminalSetLastShift() {
    let config = {
        method: 'post',
        url: API_URL + '/api/terminal/setLastShift',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}
    
export async function activateTerminal(id) {
    let config = {
        method: 'post',
        url: API_URL + '/api/terminal/activate/' + id,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function deactivateTerminal(id) {
    let config = {
        method: 'post',
        url: API_URL + '/api/terminal/deactivate/' + id,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function postTerminal(name, shortName) {
    let config = {
        method: 'post',
        url: API_URL + '/api/terminal',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        withCredentials: true,
        data: JSON.stringify({
            name: name,
            shortName: shortName
        })
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                console.log(error)
                reject(error);
            })
    });
}

export async function editTerminal(putDto) {
    const data = JSON.stringify(putDto);
    console.log(data);
    let config = {
        method: 'put',
        url: API_URL + '/api/terminal',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: data
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
} 
