import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import { updateUserByTrader, getUserData} from "../../app/requests/userUpdate";
import {
    getJwtTokenId,
    isJwtTokenExpired,
    isAdministrator,
    isTrader
} from "../../app/token_service";
import {API_URL} from "../../app/constants";
import { downloadFile, updateUserPricelist, updateUserPaymentTerm} from "../../app/requests/user";
import {getPriceListsChoice} from "../../app/requests/priceLists";

export const UserPreviewScreen = () => {
    const [UserName, setUserName] = useState('');

    const [Email, setEmail] = useState('');
    const [CompanyName, setCompanyName] = useState('');
    const [CompanyShortName, setCompanyShortName] = useState('');
    const [Country, setCountry] = useState('');
    const [State, setState] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [TaxNumber, setTaxNumber] = useState('');
    const [Address, setAddress] = useState('');
    const [BuildingNumber, setBuildingNumber] = useState('');
    const [City, setCity] = useState('');
    const [PostCode, setPostCode] = useState('');
    const [error, setError] = useState('');
    const [id, setID] = useState('');
    const [file, setFile] = useState('');
    const [fileId, setFileId] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [PriceList, setPriceList] = useState('')
    const [exemptionFromExciseDutyDocumentName, setExemptionFromExciseDutyDocumentName] = useState('')
    const [priceLists, setPriceLists] = useState([])
    const [paymentTerm, setPaymentTerm] = useState('')
    const [loading, setLoading] = useState('')
    const [success, setSuccess] = useState('')
    const [SymfoniaId, setSymfoniaId] = useState('')
    //const api = 

    const userUpdate = async () => {
        setLoading(true);

        
            try {
                await updateUserByTrader(searchParams.get('id'), UserName,
                    Email, CompanyName, CompanyShortName,
                    Country, State, PhoneNumber, TaxNumber,
                    Address, BuildingNumber, City, PostCode)
                    .then(response => {
                        console.log(response);
                        if (response.status >= 200 && response.status < 300) {
                            setSuccess("dane zmienione");
                        } else {
                            setError(response.data.message)
                        }
                    });
            } catch (e) {
                console.log(e.Address)
            }
        
        setLoading(false);
    };

    useEffect(() => {
        const userData = async () => {
            await getUserData(Number(searchParams.get('id')))
                .then(response => {
                    console.log(response)
                    setUserName(response.userName);
                    setAddress(response.address);
                    setEmail(response.email);
                    setCompanyName(response.companyName);
                    setCompanyShortName(response.companyShortName);
                    setCountry(response.country);
                    setState(response.state);
                    setPhoneNumber(response.phoneNumber);
                    setTaxNumber(response.taxNumber);
                    setBuildingNumber(response.buildingNumber);
                    setCity(response.city);
                    setPostCode(response.postCode);
                    setExemptionFromExciseDutyDocumentName(response.exemptionFromExciseDutyDocumentName);
                    setPaymentTerm(response.paymentTerm)
                    setError('');
                    setSymfoniaId(response.symfoniaId)
                    setPriceList(response.priceList?.id)
                    
                })

        }
        userData().catch(error => {
            console.log(error)
            setError("Wystąpił błąd podczas pobierania danych użytkownika");
        });

        getPriceListsChoice().then(setPriceLists).catch(setError("Wystąpił błąd podczas wczytywania listy cenników"))
    }, []);

    if (!isJwtTokenExpired() && (isTrader() || isAdministrator())) {

        return (
            <div className="p-8 pt-0 my-8 mx-auto rounded-sm max-w-[96rem]">
                <div className="userUpdate-screen__form">
                    <div className="userUpdate-screen__form__header">
                        <center><h1 className="text-2xl font-bold md-3 max-w-md">Podgląd profilu{' ' + UserName}</h1>
                        </center>
                    </div>
                    {error && <p className="error">{error}</p>}
                    <div className="userUpdate-screen__form__body">
                        <form>
                            <div className="overflow-x-auto ml-12">
                                <table className="table table-compact w-full static">
                                    <tbody>
                                    <tr className={"z-0"}>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Nazwa użytkownika</label>
                                        </td>
                                        <td><input
                                            type="text"
                                            placeholder="Nazwa użytkownika"
                                            className="input input-bordered input-sm bordered-base-100 ml-3  bg-base-100 rounded-none w-full max-w-md"
                                            value={UserName}
                                            disabled

                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Symfonia ID</label></td>
                                        <td><input
                                            type="text"
                                            placeholder="Symfonia ID"
                                            className="input input-bordered input-sm bordered-base-100 ml-3  bg-base-100 rounded-none w-full max-w-md"
                                            value={SymfoniaId}
                                            disabled

                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Adres E-mail</label></td>
                                        <td><input
                                            type="text"
                                            placeholder="adres email"
                                            className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                            value={Email}
                                            disabled
                                        /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Pełna nazwa firmy</label>
                                        </td>
                                            <td><input
                                                type="text"
                                                placeholder="Nazwa firmy"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={CompanyName}
                                                onChange={e => setCompanyName(e.target.value)}
                                            /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Skrócona nazwa
                                            firma</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Skrócona nazwa firma"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={CompanyShortName}
                                                onChange={e => setCompanyShortName(e.target.value)}
                                            /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Państwo</label></td>
                                            <td><select className="select  bordered-base-100 ml-3 rounded-none w-full max-w-md" value={Country} onChange={e => setCountry(e.target.value)} >
                                                <option selected={Country === '' ? 'selected' : ''}>Polska</option>
                                                <option>Cypr</option>
                                                <option>Czechy</option>
                                                <option>Niemcy</option>
                                                <option>Słowacja</option>
                                            </select></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Region</label></td>
                                            {String(Country).length === 0 || String(Country).includes('Pol') ?
                                                <td><select className="select  bordered-base-100 ml-3 rounded-none w-full max-w-md" value={State} onChange={e => setState(e.target.value)} >
                                                    <option selected={State === '' ? 'selected' : ''}>Wybierz wojewodztwo</option>
                                                    <option>dolnośląskie</option>
                                                    <option>kujawsko-pomorskie</option>
                                                    <option>lubelskie</option>
                                                    <option>lubuskie</option>
                                                    <option>łódzkie</option>
                                                    <option>małopolskie</option>
                                                    <option>mazowieckie</option>
                                                    <option>opolskie</option>
                                                    <option>podkarpackie</option>
                                                    <option>podlaskie</option>
                                                    <option>pomorskie</option>
                                                    <option>śląskie</option>
                                                    <option>świętokrzyskie</option>
                                                    <option>warmińsko-mazurskie</option>
                                                    <option>wielkopolskie</option>
                                                    <option>zachodniopomorskie</option>
                                                </select></td>
                                                :
                                                Country !== 'Polska' && String(Country).length > 0 ?
                                                    <td><select className="select  bordered-base-100 ml-3 rounded-none w-full max-w-md" value={State} onChange={e => setState(e.target.value)} >
                                                        <option>Zagranica</option>
                                                    </select></td> : <td><div /></td>
                                            }
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Numer telefonu</label>
                                        </td>
                                            <td><input
                                                type="text"
                                                placeholder="Numer telefonu"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={PhoneNumber}
                                                onChange={e => setPhoneNumber(e.target.value)}
                                            /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Numer NIP(10 cyfr bez
                                            myślników)</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="NIP"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={TaxNumber}
                                                onChange={e => setTaxNumber(e.target.value)}
                                            /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Adres</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Adres"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={Address}
                                                onChange={e => setAddress(e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Numer budynku</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Numer budynku"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={BuildingNumber}
                                                onChange={e => setBuildingNumber(e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Miasto</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Miasto"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={City}
                                                onChange={e => setCity(e.target.value)}
                                            /></td>
                                    </tr>
                                    <tr>
                                        <td><label className="text-sm font-bold mt-1 text-lg">Kod pocztowy</label></td>
                                            <td><input
                                                type="text"
                                                placeholder="Kod pocztowy"
                                                className="input input-bordered input-sm bordered-base-100 ml-3 rounded-none w-full max-w-md"
                                                value={PostCode}
                                                onChange={e => setPostCode(e.target.value)}
                                            /></td>
                                    </tr>
                                    <tr>
                                    <td><label className="text-sm font-bold mt-1 text-lg">Cennik</label></td>
                                    <td>
                                    <select id="price_list_choice_list"
                                            className={"select select-sm w-full border-gray-400 rounded-none font-normal"}
                                            value={PriceList}
                                            onChange={() => {
                                                console.log(document.getElementById("price_list_choice_list").value)
                                                setPriceList(Number(document.getElementById("price_list_choice_list").value))
                                                updateUserPricelist(Number(searchParams.get('id')),Number(document.getElementById("price_list_choice_list").value))
                                                    
                                                // wywołanie z argumentem jw
                                            }}
                                        >
                                        <option defaultValue value={0}>Domyślny cennik</option>
                                        {
                                            priceLists.map((product) => (
                                                <option key={"product_" + product.id}
                                                        value={Number(product.id)}>{product.priceListName}</option>
                                            ))
                                        }
                                    </select>
                                    </td>
                                        </tr>
                                        <tr>
                                            <td><label className="text-sm font-bold mt-1 text-lg">Termin płatności</label></td>
                                            <td>
                                                <select id="payment_term_choice_list"
                                                    className={"select select-sm w-full border-gray-400 rounded-none font-normal"}
                                                    value={paymentTerm}
                                                    onChange={e => {
                                                        setPaymentTerm(Number(e.target.value))
                                                        console.log(paymentTerm)
                                                        updateUserPaymentTerm(Number(searchParams.get('id')), Number(e.target.value))

                                                        // wywołanie z argumentem jw
                                                    }}
                                                >
                                                    <option value={0}>Przedpłata</option>
                                                    <option value={7}>7 dni</option>
                                                    <option value={14}>14 dni</option>
                                                    <option value={21}>21 dni</option>
                                                    <option value={30}>30 dni</option>
                                                </select>
                                            </td>
                                        </tr>
                                    {file != undefined ?
                                        <tr>
                                            <td><label className="text-sm font-bold mt-1 text-lg">Dokument zwalniający z
                                                akcyzy</label></td>
                                                <td>
                                                    <a className="btn" target={'_blank'} onClick={(() => downloadFile(Number(searchParams.get('id')), exemptionFromExciseDutyDocumentName))} >Pobierz</a>
                                                
                                            </td>
                                        </tr> : null
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex justify-end">
                                <button variant="primary" className="btn btn-md mt-2 right-3 bg-yellow-600 border-none rounded-sm disabled:bg-yellow-600 disabled:text-gray-200" type="button" disabled={loading} onClick={userUpdate}>


                                    {loading ? 'Ładowanie...' : 'Zapisz zmiany'}
                                </button>
                            </div>
                            {error !== "" ?
                                <div className="alert alert-error shadow-lg">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="stroke-current flex-shrink-0 h-6 w-6"
                                             fill="none"
                                             viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                        <span>{error}</span>
                                    </div>
                                </div> : <div/>
                            }

                        </form>

                    </div>
                </div>
            </div>
        );
    } else {
        return (<div/>)
    }
};