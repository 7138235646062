import axios from "axios";
import {API_URL} from "../constants";
import {getJwtToken, getJwtTokenClaim, getJwtTokenClaims, isJwtTokenExpired, isTrader} from "../token_service";

export async function login(username, password) {
    let data = JSON.stringify({
        "Login": username,
        "Password": password
    });

    let config = {
        method: 'post',
        url: API_URL + '/api/login',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data,
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                console.log(response)
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}


export async function registration(username, password, PasswordConfirm,
                                   Email, CompanyName, CompanyShortName,
                                   Country, State, PhoneNumber, TaxNumber,
                                   Address, BuildingNumber, City, PostCode,
                                   isStatutAccepted, agreementForRecivingOrderStatus,
                                   exemptionFromExciseDutyDocumentName,
                                   exemptionFromExciseDutyDocumentFile
) {

    let data = JSON.stringify({
        "UserName": username,
        "Password": password,
        "PasswordConfirm": PasswordConfirm,
        "Email": Email,
        "CompanyName": CompanyName,
        "CompanyShortName": CompanyShortName,
        "Country": Country,
        "State": State,
        "PhoneNumber": PhoneNumber,
        "TaxNumber": TaxNumber,
        "Address": Address,
        "BuildingNumber": BuildingNumber,
        "City": City,
        "PostCode": PostCode,
        "isStatutAccepted": isStatutAccepted,
        "agreementForRecivingOrderStatus": agreementForRecivingOrderStatus,
        "exemptionFromExciseDutyDocumentName": exemptionFromExciseDutyDocumentName,
    });


    let formdata = new FormData()
    formdata.append('document', data)
    formdata.append('file', exemptionFromExciseDutyDocumentFile)
    console.log(data);
    let headers = {}
    
    // if (!isJwtTokenExpired() && isTrader()) {
    //     headers = {...headers, "Authorization": "Bearer " + getJwtToken()}
    // }

    let config = {
        method: 'post',
        url: API_URL + '/api/registration',
        headers: headers,
        data: formdata,
        withCredentials: true
    };


    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function statuteInfo() {
    let config = {
        method: 'get',
        url: API_URL + '/api/statute/info',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function statuteDownload() {
    let config = {
        method: 'get',
        url: API_URL + '/api/statute',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}

export async function sendPasswordRestartEmail(email) {
    let config = {
        method: 'post',
        url: API_URL + '/api/sendpasswordreset',
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(email),
        withCredentials: true
    };

    console.log(config);

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}


export async function resetPassword(token, password, confirmPassword) {
    let config = {
        method: 'post',
        url: API_URL + '/api/passwordreset',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };

    config.data = JSON.stringify({
        "token": token,
        "newpassword": password,
        "confirmPassword": confirmPassword
    });

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                console.log(response);
                resolve(response);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

export async function activateUser(token, username, password) {
    let config = {
        method: 'post',
        url: API_URL + '/api/veryfiaccount',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };

    config.data = JSON.stringify({
        "token": token,
        "username": username,
        "password": password
    });

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                console.log(response);
                resolve(response);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    });
}

export async function refreshToken() {
    let config = {
        method: 'post',
        url: API_URL + '/api/refreshtoken',
        headers: {
            'Content-Type': 'application/json'
        },
        data: getJwtTokenClaim("RefreshToken"), // TODO FIX TOKEN REFRESHING SYSTEM
        withCredentials: true
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
    });
}