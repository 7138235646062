import {getJwtToken} from "../token_service";
import {API_URL} from "../constants";
import axios from "axios";


export function getDrivers(searchString)
{
    let data = JSON.stringify(searchString);
    const config = {
        method: 'post',
        url: API_URL + '/api/driver/search',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + getJwtToken()
        },
        data: data,
        withCredentials: true
    };
    
    return new Promise((resolve, reject) => {
        axios(config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
        
    });
}