import React, { Component } from 'react';
import {LoginScreen} from "../screens/LoginScreen/LoginScreen";
import {
  isAdministrator,
  isClient,
  isJwtTokenExpired, isTerminal, isTrader, removeJwtToken
} from "../app/token_service";

export class Home extends Component {
  static displayName = Home.name;

  render() {
    if (!isJwtTokenExpired() && isTrader()) {
      window.location.href = "/orders-trader?OrderStatus=0";
    }
    else if (!isJwtTokenExpired() && isTerminal()) {
        window.location.href = '/orders-terminal?OrderStatus=1&DateFrom=' + new Date().toISOString().split('T')[0] + '&DateTo=' + new Date().toISOString().split('T')[0];
    }
    else if (!isJwtTokenExpired() && isClient()) {
        window.location.href = '/orders-client?OrderStatus=-1';
    }
    else if (!isJwtTokenExpired() && isAdministrator()) {
      window.location.href = '/system-users';
    }
    
    return (
      <div>
          {
              !isJwtTokenExpired() ? (
                  <div>
                  </div>
                  ) : (
                    <LoginScreen/>
              )
          }
      </div>
    );
  }
}
