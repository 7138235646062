import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAdministrator, isJwtTokenExpired } from "../../app/token_service";
import { getLogs } from "../../app/requests/admin";



export const AdminLogScreen = () => {

    const [date, setDate] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const download = async () => {
        setError('')
        setLoading(true);
        
        try {
            await getLogs(date)
                .then(response => {
                    console.log("pobieranie");
                    }).catch((err) => {
                        console.log(err)
                        setError(err.response.data)
                    })
                //window.location.reload();
                //window.location.href = window.location.href;
            } catch (e) {
            console.log(e)
            setError(e)
            }
        setLoading(false);
    };
    return (
        <div className="p-2">
            <div className="p-8 my-8 mx-auto border-2 bg-base-200 border-gray-300 rounded-md max-w-lg">
                <h1 className="text-2xl font-bold">Pobieranie logów</h1>
                <br />
                <label className="text-sm font-bold ml-1">wybierz datę logów</label>
                <br />
                <input
                    type="date"
                    placeholder="Wybierz dzień"
                    value={date}
                    className="file-input w-full max-w-xs mb-2 rounded-sm"
                    onChange={e => { setDate(e.target.value) }}

                />


                <button
                    className={"btn bg-yellow-600 border-none rounded-sm" + (loading ? " loading" : "")}
                    type="button"
                    disabled={loading}
                    onClick={download}
                >
                    {loading ? 'Ładowanie...' : 'Pobierz'}
                </button>

            </div>
            {error !== "" ?
                <div className="alert alert-error shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>{error}</span>
                    </div>
                </div> : <div />
            }
            {success !== "" ?
                <div className="alert alert-success shadow-lg">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>{success}</span>
                    </div>
                </div> : <div />
            }
        </div>
        )
}