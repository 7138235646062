function DictionariesManagementScreen() {


    return (
        <div>
            <a 
                href="/dictionaries/products"
                className="btn btn-ghost"
                >Produkty</a>
            <br/>
            <a
                href="/dictionaries/terminals"
                className="btn btn-ghost"
                >Terminale</a>
        </div>
    );
}

export default DictionariesManagementScreen;