import {Counter} from "./components/Counter";
import {FetchData} from "./components/FetchData";
import {Home} from "./components/Home";
import {LoginScreen} from "./screens/LoginScreen/LoginScreen";
import {RegisterScreen} from "./screens/RegistrationScreen/RegistrationScreen";
import {StatuteScreen} from "./screens/StatuteScreen/StatuteScreen";
import {StatuteAcceptationReport} from "./screens/StatuteScreen/StatuteAcceptationReport";
import {StatuteUpdateScreen} from "./screens/StatuteScreen/StatuteUpdateScreen";
import {PasswordResetScreen} from "./screens/PasswordResetScreen/PasswordResetScreen";
import {ForgotPasswordScreen} from "./screens/ForgotPasswordScreen/ForgotPasswordScreen";
import {UserUpdateScreen} from "./screens/UpdateScreen/UserUpdateScreen";
import {UserPreviewScreen} from "./screens/UserListScreen/UserPreviewScreen";
import CreateOrderScreen from "./screens/CreateOrderScreen/CreateOrderScreen";
import NotFoundScreen from "./screens/404Screen/NotFoundScreen";
import OrderListTrader from "./screens/OrderListScreen/OrderListScreenTrader";
import OrderListCustomer from "./screens/OrderListScreen/OrderListScreenCustomer";
import OrderListTerminal from "./screens/OrderListScreen/OrderListScreenTerminal";
import {VerifyUserScreen} from "./screens/VerifyScreen/VerifyUserScreen";
import OrderDetailsScreen from "./screens/OrderDetailsScreen/OrderDetailsScreen";
import NewUsersListTrader from "./screens/UserListScreen/NewUserListScreen"
import UsersListTrader from "./screens/UserListScreen/UsersListTrader"
import DriverConfirmationScreen from "./screens/DriverConfirmationScreen/DriverConfirmationScreen";
import PriceListsTraderScreen from "./screens/PriceListsScreen/PriceListsTraderScreen";
import PriceListDetailsScreen from "./screens/PriceListsScreen/PriceListDetailsScreen";
import CreatePriceListScreen from "./screens/PriceListsScreen/CreatePriceListScreen";
import MyPriceListScreen from "./screens/PriceListsScreen/MyPriceListScreen";
import UsersListAdmin from "./screens/UserListScreen/UsersListAdmin";
import { AdminUpdateUserScreen } from "./screens/UpdateScreen/AdminUpdateUserScreen";
import DictionariesManagementScreen from "./screens/DictionariesManagement/DictionariesManagementScreen";
import ProductsManagementScreen from "./screens/ProductsManagement/ProductsManagementScreen";
import TerminalManagementScreen from "./screens/TerminalsManagement/TerminalManagementScreen";
import ReportsScreen from "./screens/ReportsScreen/ReportsScreen";
import IssueReportScreen from "./screens/ReportsScreen/IssueReportScreen";

import { AdminLogScreen } from "./screens/LogsScreen/AdminLogsScreen";
import {EndTerminalShift} from "./screens/EndTerminalShift/EndTerminalShift";
const AppRoutes = [
    {
        index: true,
        element: <Home/>
    },
    {
        path: '/login',
        element: <LoginScreen/>
    },
    {
        path: '/registration',
        element: <RegisterScreen/>
    },
    {
        path: '/statute',
        element: <StatuteScreen/>
    },
    {
        path: '/reset-password/:token',
        element: <PasswordResetScreen/>
    },
    {
        path: '/forgot-password',
        element: <ForgotPasswordScreen/>
    },
    {
        path: '/myProfile',
        element: <UserUpdateScreen/>
    },
    {
        path: '/create-order',
        element: <CreateOrderScreen/>
    },
    {
        path: '/orders-trader',
        element: <OrderListTrader/>
    },
    {
        path: '/orders-client',
        element: <OrderListCustomer/>
    },
    {
        path: '/orders-terminal',
        element: <OrderListTerminal/>
    },
    {
        path: '*',
        element: <NotFoundScreen/>
    },
    {
        path: '/userVerify/:token',
        element: <VerifyUserScreen/>
    },
    {
        path: '/order-details/:id',
        element: <OrderDetailsScreen/>
    },
    {
        path: '/driver-completion/:id',
        element: <DriverConfirmationScreen/>
    },
    {
        path: '/new-users',
        element: <NewUsersListTrader/>
    },
    {
        path: '/user',
        element: <UserPreviewScreen/>
    },
    {
        path: '/users-list',
        element: <UsersListTrader/>
    },
    {
        path: '/update-statute',
        element: <StatuteUpdateScreen/>
    },
    {
        path: '/statute-acceptation-report',
        element: <StatuteAcceptationReport/>
    },
    {
        path: 'price-lists-trader',
        element: <PriceListsTraderScreen/>
    },
    {
        path: '/price-list/:id',
        element: <PriceListDetailsScreen/>
    },
    {
        path: '/price-lists/create',
        element: <CreatePriceListScreen/>
    },
    {
        path: 'my-price-list',
        element: <MyPriceListScreen/>
    },
    {
        path: 'system-users',
        element: <UsersListAdmin/>
    },
    {
        path: 'user-update-by-admin/:id',
        element: <AdminUpdateUserScreen/>
    },
    {
        path: '/dictionaries',
        element: <DictionariesManagementScreen/>
    },
    {
        path: '/dictionaries/products',
        element: <ProductsManagementScreen/>
    },
    {
        path: '/dictionaries/terminals',
        element: <TerminalManagementScreen/>
    },
    {
        path: 'admin-logs',
        element: <AdminLogScreen />
    },
    {
        path: '/reports',
        element: <ReportsScreen/>
    },
    {
        path: '/reports/issues/:userType',
        element: <IssueReportScreen/>
    },
    {
        path: '/endShift',
        element: <EndTerminalShift/>
    }

];

export default AppRoutes;
