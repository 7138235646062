import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {login} from "../../app/requests/annonymous";
import {getJwtToken, setJwtToken} from "../../app/token_service";

export const LoginScreen = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const onLogin = async () => {
        setLoading(true);

        if (username === "") {
            setError("Wprowadź nazwę użytkownika");
            setLoading(false);
            return;
        }

        if (password === "") {
            setError("Wprowadź hasło");
            setLoading(false);
            return;
        }

        try {
            await login(username, password)
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        setJwtToken(response.data);
                        console.log(getJwtToken()) 
                    } else {
                        throw Error(response.data);
                    }
                });
            window.location.reload();
        } catch (e) {
            console.log(e);
            if (e.response.data === "Użytkownik nie potwierdził adresu email") {
                setError("Użytkownik nie potwierdził adresu email. Link aktywacyjny został wysłany ponownie.");
                setLoading(false);
                return;
            }
            // set e.response.data as error if status is 401
            if (e.response.status === 401) {
                setError(e.response.data);
                setLoading(false);
                return;
            }
            setError('Nie udało się zalogować');
        }
        setLoading(false);
    };

    return (
        <div className="p-2">
            <div className="p-8 my-8 mx-auto border-2 bg-base-200 border-gray-300 rounded-md max-w-lg">
                <h1 className="text-2xl font-bold">Zaloguj do SBK24</h1>
                <br/>
                <label className="text-sm font-bold ml-1">Nazwa użytkownika</label>
                <br/>
                <input
                    type="text"
                    placeholder="Login"
                    className="input input-bordered w-full max-w-xs mb-2 rounded-sm"
                    value={username}
                    onChange={e => setUsername(e.target.value)}

                />
                <br/>

                <label className="text-sm font-bold mt-2 ml-1">Hasło</label>
                <br/>
                <input
                    type="password"
                    placeholder="Hasło"
                    className="input input-bordered w-full max-w-xs mb-2 rounded-sm"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    onKeyDown={event => {
                        if (event.key === 'Enter') onLogin().catch();
                    }}
                />
                <br/>

                <button
                    className={"btn bg-yellow-600 border-none rounded-sm" + (loading ? " loading" : "")}
                    type="button"
                    disabled={loading}
                    onClick={onLogin}
                >
                    {loading ? 'Ładowanie...' : 'Zaloguj'}
                </button>

                <button
                    className="btn mx-2 bg-yellow-600 border-none rounded-sm"
                    onClick={() => navigate('/forgot-password')}
                >
                    Przypomnij hasło
                </button>

                {error !== "" ?
                    <div
                        className="alert alert-error shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                 fill="none"
                                 viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            <span>{error}</span>
                        </div>
                    </div> : <div/>
                }
            </div>
        </div>
    );
};