import React, { Component } from 'react';
import './NavMenu.css';
import logo_sbk from '../assets/logo_sbk.jpg';
import DropdownKlient, {DropdownKlientMobile} from "./navbar_components/DropdownKlient";
import DropdownTrader, {DropdownTraderMobile} from "./navbar_components/DropdownTrader";
import DropdownTerminal, {DropdownTerminalMobile} from "./navbar_components/DropdownTerminal";
import DropdownLogistyk, {DropdownLogistykMobile} from "./navbar_components/DropdownLogistyk";
import DropdownAdmin, {DropdownAdminMobile} from "./navbar_components/DropdownAdmin";

import {
    isAdministrator,
    isClient,
    isJwtTokenExpired,
    isLogistyk,
    isTerminal,
    isTrader,
    removeJwtToken
} from "../app/token_service";

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }


    render() {
        return (
            <div className="navbar bg-white drop-shadow-md" data-theme={'light'}>
                <div className="navbar-start">
                    <div className="dropdown">
                        <label tabIndex={0} className="btn btn-ghost  lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                        </label>
                        <ul tabIndex={0}
                            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-200 w-52">
                            {isJwtTokenExpired() ? <li><a href={'/'} className="btn btn-ghost">Zaloguj</a></li> : null}
                            {isJwtTokenExpired() ? <li><a href={'/registration'} className="btn btn-ghost">Zarejestruj</a></li> : null}
                            {isJwtTokenExpired() ? <li><a href={'/statute'} className="btn btn-ghost">Regulamin</a></li> : null}

                            {!isJwtTokenExpired() && isClient() ? <DropdownKlientMobile/> : null}
                            {!isJwtTokenExpired() && isTrader() ? <DropdownTraderMobile/> : null}
                            {!isJwtTokenExpired() && isLogistyk() ? <DropdownLogistykMobile/> : null}
                            {!isJwtTokenExpired() && isTerminal() ? <DropdownTerminalMobile/> : null}
                            {!isJwtTokenExpired() && isAdministrator() ? <DropdownAdminMobile/> : null}

                            {!isJwtTokenExpired() ? <li><a 
                                className="btn btn-ghost" href='/myProfile'>Mój profil</a></li> : null}

                            {!isJwtTokenExpired() ? <li><a className="btn btn-ghost" onClick={() => {
                                removeJwtToken();
                                window.location.href = "/"
                            }}>Wyloguj</a></li> : null}
                        </ul>
                    </div>
                    <a className= "normal-case text-xl ml-2" href={"/"}>
                        <img src={logo_sbk} alt={"sbk_logo"} className="h-16 w-auto rounded-lg"/>
                    </a>
                </div>
                <div className="navbar-end hidden lg:flex">
                    <ul className="menu menu-horizontal px-1">
                        {isJwtTokenExpired() ? <li><a href={'/'} className="btn btn-ghost hover:bg-yellow-600">Zaloguj</a></li> : null}
                        {isJwtTokenExpired() ? <li><a href={'/registration'} className="btn btn-ghost hover:bg-yellow-600">Zarejestruj</a></li> : null}
                        {isJwtTokenExpired() ? <li><a href={'/statute'} className="btn btn-ghost hover:bg-yellow-600">Regulamin</a></li> : null}

                        {!isJwtTokenExpired() && isTerminal() ? <li><a href={'/endShift'} className="btn btn-ghost hover:bg-yellow-600">Koniec zmiany</a> </li> : null}
                        {!isJwtTokenExpired() && isClient() ? <DropdownKlient/> : null}
                        {!isJwtTokenExpired() && isTrader() ? <DropdownTrader/> : null}
                        {!isJwtTokenExpired() && isLogistyk() ? <DropdownLogistyk/> : null}
                        {!isJwtTokenExpired() && isTerminal() ? <DropdownTerminal/> : null}
                        {!isJwtTokenExpired() && isAdministrator() ? <DropdownAdmin/> : null}

                        {!isJwtTokenExpired() ? <li><a className="btn btn-ghost"
                        href = '/myProfile'>Mój profil</a></li> : null}

                        {!isJwtTokenExpired() ? <li><a className="btn btn-ghost" onClick={() => {
                            removeJwtToken();
                            window.location.href = "/"
                        }}>Wyloguj</a></li> : null}
                    </ul>
                </div>
            </div>
        )
            ;
    }
}
