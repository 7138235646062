import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {terminalAddMerged, symfoniaGetTerminal, getOrdersBySearchCriteria} from "../../app/requests/order";
import {terminalSetLastShift, terminalGetLastShift} from "../../app/requests/terminalList"
import {getJwtToken} from "../../app/token_service";


export const EndTerminalShift = () => {
    // const [date, setDate] = useState();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [orders, setOrders] = useState([]);
    const [lastShit, setLastShift] = useState(null)
    const [product, setProduct] = useState({})
    const [loading, setLoading] = useState(false);

    function fetchData() {
        symfoniaGetTerminal().then(r => {
            setOrders(r.data)
            let grouped = {}
            r.data.map(o => {
                console.log(o)
                o.orderDrivers.map(d => {
                    console.log(d)
                    if (!!grouped[o.productName]) {
                        grouped[o.productName] += d.tons
                    } else {
                        grouped[o.productName] = d.tons
                    }
                })
            })
            setProduct(grouped)
        })
    }

    useEffect(fetchData, []);
    useEffect(() => {
        terminalGetLastShift().then(setLastShift)
    }, [])

    function endShiftButton() {
        setError("");
        setSuccess("");
        setLoading(true);
        terminalAddMerged().then(() => {
            setSuccess("Synchronizacja zakończona pomyślnie")
            terminalSetLastShift()
            fetchData()
        }).catch((err) => {
                if (err.response.status === 500) {
                    setError("Wystąpił błąd wewnętrzny podczas synchronizacji zamówień. Prawdopodobnie serwer Symfonii jest niedostępny.")
                } else {
                    setError(err.response.data)
                }
            }
        )
    }

    return (
        <div className="p-2">
            <div className="p-8 my-8 mx-auto border-2 bg-base-200 border-gray-300 rounded-md max-w-lg">
                <h1 className="text-xl font-bold">Czy na pewno chcesz zakończyć zmianę?</h1>
                <br/>
                <div className={"grid grid-cols-2 gap-4"}>
                    <button className={"btn bg-yellow-600 border-none rounded-sm inline-grid"}
                            onClick={endShiftButton} disabled={loading}>Zakończ Zmianę
                    </button>
                    <div className={"inline-grid content-center text-center font-bold"}>
                        {lastShit?.substring(0, 19).replace("T", " ")}
                    </div>
                </div>
            </div>
            {error !== "" ?
                <div
                    className="alert alert-error shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                             fill="none"
                             viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>
                        <span>{error}</span>
                    </div>
                </div> : <div/>
            }
            {success !== "" ?
                <div
                    className="alert alert-success shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                             fill="none"
                             viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>
                        <span>{success}</span>
                    </div>
                </div> : <div/>
            }
            <table className="table table-fixed w-half rounded-none mx-auto font-bold">
                <tbody>
                {Object.keys(product).map(key=> <tr key={key}>
                        <td>{key}</td>
                        <td>{product[key].toFixed(2)} T</td>
                    </tr>
                )}
                </tbody>
            </table>
            <table className="table table-fixed w-half rounded-none mx-auto">
                <tbody>
                {orders.map(o => <>
                    <tr key={o.id}>
                        <td>{o.orderNumber}</td>
                        <td className={"row-span-2"}>{o.clientName}</td>
                        {o.orderDrivers.map(d => <>{d.tons && !d.isSymfoniaSent && d.isConfirmed > 0?<tr key={d.id} className={"bg-base-200"} colSpan={3}>
                            <td>{d.realizationDate?.substring(0, 10)}</td>
                            <td>{o.productName}</td>
                            <td>{d.tons} T</td>
                        </tr>:null}</>)}
                    </tr>
                </>)}
                </tbody>
            </table>
        </div>
    );
}