import React from 'react';

export const DropdownAdmin = ({...args}) => {
    return (
        <div className="dropdown dropdown-bottom">
            <label tabIndex={0} className="btn m-1 mt-0 bg-yellow-600 border-0 rounded-sm">Administrator</label>
            <ul tabIndex={0}
                className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-auto whitespace-nowrap">
                <li><a className={"btn-ghost hover:bg-yellow-600"} href={"/system-users"}>Lista użytkowników</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"} href='/registration'>Rejestracja</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"} href='/dictionaries/terminals'>Terminale</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"} href='/dictionaries/products'>Produkty</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"} href='admin-logs'>Logi</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"} href='/update-statute'>Regulamin</a></li>
                <li><a className={"btn-ghost hover:bg-yellow-600"} href={"/reports"}>Raporty</a></li>
            </ul>
        </div>
    );
}

export const DropdownAdminMobile = ({...args}) => {
    return (
        <ul className="menu">
            <li><a className="btn btn-ghost" href={"/system-users"}> Lista użytkowników</a></li>
            <li><a className={"btn btn-ghost"} href='/registration'>Rejestracja</a></li>
            <li><a className={"btn btn-ghost"} href='/dictionaries/terminals'>Terminale</a></li>
            <li><a className={"btn btn-ghost"} href='/dictionaries/products'>Produkty</a></li>
            <li><a className="btn btn-ghost" href='admin-logs'> Logi</a></li>
            <li><a className={"btn btn-ghost"} href='/update-statute'>Regulamin</a></li>
            <li><a className="btn btn-ghost" href={"/reports"}> Raporty</a></li>

        </ul>
    );
}

export default DropdownAdmin;
