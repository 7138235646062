import {useEffect, useState} from "react";
import {
    getNewClients,
    acceptUser,
    blockUser,
    VerifyUserClient,
    getBlockedUsers,
    addSymfoniaUser
} from "../../app/requests/user";


export default function NewUsersListTrader() {
    const [customerList, setCustomerList] = useState([]);
    const [filtredList, setFiltredList] = useState([]);

    const [login, setLogin] = useState('')
    const [companyData, setCompanyData] = useState('')
    const [detail, setDetail] = useState('')

    const [checkbox, setCheckbox] = useState(true)

    function filterTable(login, companyData, details) {
        let result = customerList;
        if (login !== '') {
            result = result.filter(x =>
                x.userName.toLowerCase().includes(login.toLowerCase()) ||
                x.companyShortName.toLowerCase().includes(login.toLowerCase())
            );
        }
        if (companyData !== '') {
            result = result.filter(x =>
                (String(x.taxNumber)).toLowerCase().includes(companyData.toLowerCase()) ||
                x.companyName.toLowerCase().includes(companyData.toLowerCase()) ||
                x.email.toLowerCase().includes(companyData.toLowerCase()) ||
                (String(x.phoneNumber)).toLowerCase().includes(companyData.toLowerCase())
            );
        }
        if (details !== '') {
            result = result.filter(x =>
                x.createdAt.toLowerCase().includes(details.toLowerCase())
            );
        }
        return result;
    }

    function fetchData() {
        if (checkbox) {
            getNewClients().then(r => setCustomerList(r.data)).catch("problem");
            if (filtredList.length === 0 && login === '' && companyData === '' && detail === '') {
                getNewClients().then(r => setFiltredList(r.data)).catch("problem");
            }
        } else {
            getBlockedUsers().then(r => setCustomerList(r.data)).catch("problem");
            if (filtredList.length === 0 && login === '' && companyData === '' && detail === '') {
                getBlockedUsers().then(r => setFiltredList(r.data)).catch("problem");
            }
        }
    }


    useEffect(fetchData, []);
    useEffect(fetchData, [checkbox]);
    useEffect(() => {setFiltredList(filterTable(login, companyData, detail))}, [customerList]);


    return (
        <div className="p-8">
            <h1 className="text-2xl font-bold md-3 max-w-md mb-5">Lista klientów</h1>
            <div className="mt-5 overflow-x-auto">
                <table className="table table-compact table-zebra w-full rounded-none static">
                    <thead className="rounded-none">
                    <tr key="head" className="rounded-none">
                        <th className="rounded-none w-1/12"/>
                        <th className="rounded-none w-1/3">Użytkownik</th>
                        <th className="rounded-none w-1/3">Dane firmy</th>
                        <th className="rounded-none w-1/6">Szczegóły konta</th>
                        <th className="rounded-none w-1/12">Operacje</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td></td>
                        <td className="w-36">
                            <input type="text" className="border border-gray-400 bg-gray-200 rounded-none p-1 max-w-md"
                                   onKeyDown={event => {
                                       if (event.key === 'Enter') fetchData();
                                   }}
                                   onChange={e => {
                                       setLogin(e.target.value)
                                       setFiltredList(filterTable(e.target.value, companyData, detail));

                                   }
                                   }/>
                        </td>
                        <td>
                            <input type="text" className="border border-gray-400 bg-gray-200  rounded-none p-1 max-w-md"
                                   onKeyDown={event => {
                                       if (event.key === 'Enter') fetchData();
                                   }}
                                   onChange={e => {
                                       setCompanyData(e.target.value);
                                       setFiltredList(filterTable(login, e.target.value, detail));
                                   }
                                   }/>
                        </td>
                        <td>
                            <input type="text" className="border border-gray-400 bg-gray-200 rounded-none p-1 max-w-md"
                                   onKeyDown={event => {
                                       if (event.key === 'Enter') fetchData();
                                   }}
                                   onChange={e => {

                                       setDetail(e.target.value);
                                       setFiltredList(filterTable(login, companyData, e.target.value));

                                   }}/>
                        </td>
                        <td>
                            <input
                                type="checkbox"
                                id="statute"
                                className="checkbox rounded-none"
                                checked={checkbox}
                                onChange={() => {
                                    setCheckbox(!checkbox);
                                }
                            }
                            />
                            <label htmlFor="statute" className="ml-2" text>Tylko klienci</label>
                        </td>
                    </tr>
                    {filtredList && filtredList.map(c => <tr key={c.id}>
                        <td>{c.id}</td>
                        <td><a href={'/user?id=' + c.id}>{c.userName}</a><br/>{c.companyShortName}</td>
                        <td>
                            <table className="table table-compact static">
                                <tbody>
                                <tr>
                                    <td>Nazwa firmy</td>
                                    <td>{c.companyName}</td>
                                </tr>
                                <tr>
                                    <td>NIP</td>
                                    <td>{c.taxNumber}</td>
                                </tr>
                                <tr>
                                    <td>adres email</td>
                                    <td>{c.email}</td>
                                </tr>
                                <tr>
                                    <td>numer telefonu</td>
                                    <td>{c.phoneNumber}</td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                        <td>
                            <table className="table table-compact static">
                                <tbody>
                                <tr>
                                    <td>Data rejestracji</td>
                                    <td>{c.createdAt.substring(0, 10)}</td>
                                </tr>
                                <tr>
                                    <td>Potwierdzony</td>
                                    <td>{String(c.isActive ? "Tak" : "Nie")}</td>
                                </tr>
                                <tr>
                                    <td>Zaakceptowany</td>
                                    <td>{String(c.isAccountAccepted ? "Tak" : "Nie")}</td>
                                </tr>
                                <tr>
                                    <td>Symfonia ID</td>
                                    <td>{c.symfoniaId}</td>
                                </tr>
                                </tbody>
                            </table>
                        </td>

                        <td>
                            <button
                                className="mt-4 bg-green-600 text-white rounded-none p-1 max-w-ms mt-1 border-[1px] border-gray-400 hover:bg-gray-800 ease-linear transition-colors"
                                onClick={() => {
                                    acceptUser(c.id).then(() => addSymfoniaUser(c.id).then(() => window.location.reload()));//.then(() => window.location.reload());
                                    
                                }}>Akceptuj
                            </button>
                            <br/>
                            {!c.isActive ? <div>
                                    <button
                                        className="mt-4 text-white rounded-none p-1 max-w-ms mt-1 border-[1px] bg-yellow-400 border-gray-400 hover:bg-gray-800 ease-linear transition-colors"
                                        onClick={() => {
                                            VerifyUserClient(c.id).then(() => window.location.reload());
                                        }}>Weryfikuj email
                                    </button>
                                    <br/></div>
                                :
                                null}

                            <button
                                className="mt-4 bg-red-600 text-white rounded-none p-1 max-w-ms mt-1 border-[1px] border-gray-400 hover:bg-gray-800 ease-linear transition-colors"
                                onClick={() => {
                                    blockUser(c.id).then(() => window.location.reload());
                                }}>blokuj
                            </button>
                            <br/>
                            <button
                                className="mt-4 text-white rounded-none p-1 max-w-ms mt-1 border-[1px] bg-gray-400 border-gray-400 hover:bg-gray-800 ease-linear transition-colors"
                                onClick={() => {
                                    window.location = ('/user?id=' + c.id);
                                }}>Podgląd
                            </button>
                        </td>
                    </tr>)}

                    </tbody>
                </table>
            </div>
        </div>

    )
}
