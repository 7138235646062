import {useEffect, useState} from "react";
import {acceptOrder, cancelOrder, getOrderById, rejectOrder, renewOrder} from "../../app/requests/order";
import {getJwtToken, isClient, isTerminal, isTrader} from "../../app/token_service";
import EditOrderComponent from "./EditOrderComponent";
import {API_URL} from "../../app/constants";
import {useNavigate} from "react-router-dom";
import {acceptUser} from "../../app/requests/user";
import {downloadOrderFile} from "../../app/requests/order";

export default function OrderDetailsScreen() {
    const [orderDetails, setOrderDetails] = useState({});

    const [editMode, setEditMode] = useState(false);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const orderId = window.location.pathname.split("/")[2];
        setLoading(true);
        getOrderById(orderId).then(setOrderDetails).then(() => setLoading(false));
    }, []);

    function mapStatus(status) {
        switch (status) {
            case 0:
                return "Nowe"
            case 1:
                return "Zaaakceptowane"
            case 2:
                return "Zrealizowane"
            case 3:
                return "Anulowane"
            case 4:
                return "Odrzucone"
            case 5:
                return "Przedawnione"
            default:
                return "Nieznany"
        }
    }

    function mapOrderType(orderType) {
        switch (orderType) {
            case 0:
                return "Samochodowe"
            case 1:
                return "Kolejowe"
            case 2:
                return "Morskie"
            default:
                return "Nieznany"
        }
    }

    function addDays(date, days) {
        let result = Date.parse(date) + days * 24 * 60 * 60 * 1000;
        result = new Date(result);
        return result.toISOString().split('T')[0];
    }

    console.log(orderDetails.orderLogs);
    return (
        <div className="p-2">
            {!loading ? <>
                    {!editMode ?
                        <div className={"p-8 pt-0 my-8 mx-auto rounded-sm max-w-[65rem]"}>
                            <h1 className={"text-2xl font-bold md-3 max-w-md"}>Szczegóły
                                zlecenia {orderDetails.orderNumber}</h1>
                            <br/>
                            <div className="grid grid-cols-1 md:grid-cols-2 border-[1px]">
                                <span className={"text-sm font-bold bg-gray-300"}>TYP ZAMÓWIENIA</span>
                                <span className={"text-sm  bg-gray-300"}>{mapOrderType(orderDetails.orderType)}</span>
                                <span className={"text-sm font-bold md:pt-1"}>STATUS</span>
                                <span className={"text-sm  md:pt-1"}>{mapStatus(orderDetails.orderStatus)}</span>
                                <span className={"text-sm font-bold md:pt-1 bg-gray-300"}>KLIENT</span>
                                <span className={"text-sm  md:pt-1 bg-gray-300"}>{orderDetails.client.companyName}</span>
                                <span className={"text-sm font-bold md:pt-1"}>NUMER ZAMÓWIENIA SYMFONIA</span>
                                <span className={"text-sm  md:pt-1"}>{orderDetails.symfoniaId}</span>
                                <span className={"text-sm font-bold md:pt-1 bg-gray-300"}>WAŻNE OD</span>
                                <span
                                    className={"text-sm  md:pt-1 bg-gray-300"}>{orderDetails.orderDate.substring(0, 10)}</span>
                                <span className={"text-sm font-bold md:pt-1 "}>WAŻNE DO</span>
                                <span
                                    className={"text-sm  md:pt-1 "}>{(addDays(orderDetails.orderDate, orderDetails.daysToPick + 1))}</span>
                                <span className={"text-sm font-bold md:pt-1 bg-gray-300"}>TERMIN PŁATNOŚCI</span>
                                <span
                                    className={"text-sm  md:pt-1 bg-gray-300"}>{orderDetails.paymentDays === 0 ? "Przedpłata" : orderDetails.paymentDays + " dni"}</span>
                                <span className={"text-sm font-bold md:pt-1"}>TERMINAL</span>
                                <span className={"text-sm  md:pt-1"}>{orderDetails.terminal.name}</span>
                                <span className={"text-sm font-bold md:pt-1  bg-gray-300"}>PRODUKT</span>
                                <span className={"text-sm  md:pt-1  bg-gray-300"}>{orderDetails.product.name}</span>
                                { (isTrader() || isClient()) ?
                                    <>
                                    <span className={"text-sm font-bold md:pt-1 "}>CENA NETTO ZA TONĘ</span>
                                    <span className={"text-sm  md:pt-1 "}>{orderDetails.priceNetPerTon + ' PLN'}</span>
                                    </>
                                    : null
                                }
                                <span className={"text-sm font-bold md:pt-1 bg-gray-300"}>WAGA ŁĄCZNIE</span>
                                <span
                                    className={"text-sm  md:pt-1 bg-gray-300"}>{orderDetails.quantityTons + ' t'}</span>
                                <span className={"text-sm font-bold md:pt-1 "}>ILOŚĆ SAMOCHODÓW</span>
                                <span className={"text-sm  md:pt-1 "}>{orderDetails.quantityCars}</span>
                                <span className={"text-sm font-bold md:pt-1 bg-gray-300"}>TRANSPORT KLIENTA</span>
                                <span
                                    className={"text-sm  md:pt-1 bg-gray-300"}>{orderDetails.transportSbk ? 'NIE' : 'TAK'}</span>
                                <span className={"text-sm font-bold md:pt-1 "}>WAGA ZREALIZOWANA</span>
                                <span
                                    className={"text-sm  md:pt-1 "}>{orderDetails.driversConfirmed.reduce((accumulator, driver) => {
                                    return accumulator + driver.tons
                                }, 0) + ' t'}</span>
                            </div>
                            <br/>

                            <span className={"text-sm font-bold mt-1"}>LISTA KIEROWCÓW</span>
                            <div className="grid grid-cols-7 grid-flow-cols border-[1px]">
                                <span className={"text-sm font-bold mt-1"}>#</span>
                                <span className={"text-sm font-bold mt-1"}>Imię</span>
                                <span className={"text-sm font-bold mt-1"}>Nazwisko</span>
                                <span className={"text-sm font-bold mt-1"}>Samochód</span>
                                <span className={"text-sm font-bold mt-1"}>Nr telefonu</span>
                                <span className={"text-sm font-bold mt-1"}>Dokument</span>
                                <span className={"text-sm font-bold mt-1"}>Waga</span>
                                {orderDetails.driversConfirmed.map((driver, index) => (
                                    <>
                                        <span key={'idx' + index}
                                              className={"text-sm mt-1" + ((index % 2) === 0 ? " bg-gray-300" : "")}>{index + 1}</span>
                                        <span key={'fname' + index}
                                              className={"text-sm mt-1" + ((index % 2) === 0 ? " bg-gray-300" : "")}>{driver.firstName}</span>
                                        <span key={'lname' + index}
                                              className={"text-sm mt-1" + ((index % 2) === 0 ? " bg-gray-300" : "")}>{driver.lastName}</span>
                                        <span key={'car' + index}
                                              className={"text-sm mt-1" + ((index % 2) === 0 ? " bg-gray-300" : "")}>{driver.carsPlateNumber}</span>
                                        <span key={'phone' + index}
                                              className={"text-sm mt-1" + ((index % 2) === 0 ? " bg-gray-300" : "")}>{driver.phoneNumber}</span>
                                        <span key={'doc' + index}
                                              className={"text-sm mt-1" + ((index % 2) === 0 ? " bg-gray-300" : "")}>{driver.documentNumber}</span>
                                        <span key={'tons' + index}
                                              className={"text-sm mt-1" + ((index % 2) === 0 ? " bg-gray-300" : "")}>{driver.tons + ' t'}</span>
                                    </>
                                ))}
                            </div>
                            {isTrader() || isClient() ?

                                <>
                                    <br/>
                                    <span className={"text-sm font-bold mt-1"}>KOMENTARZ</span>
                                    <div className="grid grid-cols-1 grid-flow-cols border-[1px]">
                                        <span
                                            className={"text-sm  mt-1"}>{orderDetails.comment === "" ? "Brak" : orderDetails.comment}</span>
                                    </div>
                                </> : null} {isTerminal() || isTrader() ?
                            <>
                                <br/>
                                <span className={"text-sm font-bold mt-1"}>KOMENTARZ WEWNĘTRZNY</span>
                                <div className="grid grid-cols-1 grid-flow-cols border-[1px]">
                                    <span
                                        className={"text-sm  mt-1"}>{orderDetails.commentTerminal === "" || orderDetails.commentTerminal === null ? "Brak" : orderDetails.commentTerminal}</span>
                                </div>
                            </>
                            : null
                        }
                            <br/>
                            <span className={"text-sm font-bold mt-1"}>ZAŁĄCZNIK</span>
                            {orderDetails.files.length !== 0 ?
                                <div className="grid grid-cols-2 grid-flow-cols border-[1px]">
                                    <span className={"text-sm font-bold mt-1"}>Nazwa</span>
                                    <span className={"text-sm font-bold mt-1"}>Pobierz</span>
                                    {orderDetails.files.map((file, index) => (
                                        <>
                                            <span key={'name' + index}
                                                  className={"text-sm mt-1" + ((index % 2) === 0 ? " bg-gray-300" : "")}>{file.fileName}</span>
                                            <span key={'download' + index}
                                                  className={"text-sm mt-1" + ((index % 2) === 0 ? " bg-gray-300" : "")}>
                                    <a
                                        className="btn btn-sm btn-ghost"
                                        target={'_blank'}
                                        onClick={(() => downloadOrderFile(file.id, file.fileName))}
                                    >
                                    LINK
                                    </a>
                                </span>
                                        </>
                                    ))}
                                </div> : <><br/>
                                    <div className={"grid grid-cols-1 grid-flow-cols border-[1px] text-sm"}>Brak</div>
                                    <br/></>}
                            <br/>
                            <span className={"text-sm font-bold mt-1"}>HISTORIA ZLECENIA</span>
                            {orderDetails.orderLogs.length !== 0 ?
                                <div className="grid grid-cols-3 grid-flow-cols border-[1px]">
                                    <span className={"text-sm font-bold mt-1"}>Użytkownik</span>
                                    <span className={"text-sm font-bold mt-1"}>Data</span>
                                    <span className={"text-sm font-bold mt-1"}>Status</span>
                                    {orderDetails.orderLogs.map((orderLog, index) => (
                                        <>
                                            <span key={'loguser' + index}
                                                  className={"text-sm mt-1" + ((index % 2) === 0 ? " bg-gray-300" : "")}>{orderLog.userName}</span>
                                            <span key={'logdate' + index}
                                                  className={"text-sm mt-1" + ((index % 2) === 0 ? " bg-gray-300" : "")}>{String(orderLog.createdAt).substring(0, 10)}</span>
                                            <span key={'logstatus' + index}
                                                  className={"text-sm mt-1" + ((index % 2) === 0 ? " bg-gray-300" : "")}>{mapStatus(orderLog.orderStatus)}</span>
                                            
                                        </>
                                    ))}
                                </div> : <><br/>
                                    <div className={"grid grid-cols-1 grid-flow-cols border-[1px] text-sm"}>Brak</div>
                                    <br/></>}
                            {isTrader() ?
                                <>
                                    <button
                                        className={"btn btn-sm mt-2"}
                                        onClick={() => {
                                            acceptOrder(orderDetails.id).then(() => {
                                                navigate(-1)
                                            }).catch(() => setError("Akceptacja zlecenia nie powiodła się"))
                                        }}
                                    >
                                        Zaakceptuj
                                    </button>
                                    <button
                                        className={"btn btn-sm mt-2 ml-2"}
                                        onClick={() => {
                                            rejectOrder(orderDetails.id).then(() => {
                                                navigate(-1)
                                            }).catch(() => setError("Odrzucenie zlecenia nie powiodło się"))
                                        }}
                                    >
                                        Odrzuć
                                    </button>
                                </> : null}
                            {(isClient() && orderDetails.orderStatus === 0) ?
                                <>
                                    <button
                                        className={"btn btn-sm mt-2 ml-2"}
                                        onClick={() => {
                                            cancelOrder(orderDetails.id).then(() => setOrderDetails({
                                                ...orderDetails,
                                                orderStatus: 3
                                            })).catch(() => setError("Anulowanie zlecenia nie powiodło się"))
                                        }}
                                    >
                                        Anuluj
                                    </button>
                                </> : null}
                            {(isClient() && orderDetails.orderStatus === 3 && Date.parse(orderDetails.orderDate) >= new Date()) ?
                                <>
                                    <button
                                        className={"btn btn-sm mt-2 ml-2"}
                                        onClick={() => {
                                            renewOrder(orderDetails.id).then(() => setOrderDetails({
                                                ...orderDetails,
                                                orderStatus: 0
                                            })).catch(() => setError("Wznawianie zlecenia nie powiodło się"))
                                        }}
                                    >
                                        Wznów realizację
                                    </button>
                                </> : null}
                            {isTrader() || (isClient() && orderDetails.orderStatus === 0) ?
                                <button
                                    className={"btn btn-sm mt-2 ml-2"}
                                    onClick={() => {
                                        setEditMode(true)
                                    }}
                                >
                                    Edytuj
                                </button> : null}
                            {isTerminal() ? <button
                                className={"btn btn-sm mt-2"}
                                onClick={() => {
                                    navigate('/driver-completion/' + orderDetails.id)
                                }}
                            >
                                Realizacja
                            </button> : null}
                            <button
                                className={"btn btn-sm mt-2 ml-2"}
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                Powrót
                            </button>
                        </div> : <EditOrderComponent order={orderDetails}/>
                    }</> :
                <div className="flex justify-center items-center h-screen">
                    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                </div>
            }
        </div>
    );
}
