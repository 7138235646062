import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { uploadNewStatute } from "../../app/requests/admin";
export const StatuteUpdateScreen = () => {
    const [statuteFile, setStatuteFile] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const saveFile = (e) => {
        setStatuteFile(e.target.files[0]);
    }
    const onUpdate = async () => {
        setLoading(true);
        uploadNewStatute(statuteFile)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    setSuccess('Nowy regulamin został dodany')
            } else {
                setError(response.data)

            }
        }).catch((err) => {
            console.log(err)
            setError(err.response.data)
        })
        
        setLoading(false);
    };

    return (
        <div className="p-2">
            <div className="p-8 my-8 mx-auto border-2 bg-base-200 border-gray-300 rounded-md max-w-lg">
                <h1 className="text-2xl font-bold">Aktualizacja regulaminu sklepu</h1>
                <br />
                <label className="text-sm font-bold ml-1">Załącz nowy regulamin</label>
                <br />
                <input
                    type="file"
                    placeholder="Wybierz plik"
                    className="file-input w-full max-w-xs mb-2 rounded-sm"
                    onChange={saveFile}

                />
                

                <button
                    className={"btn bg-yellow-600 border-none rounded-sm" + (loading ? " loading" : "")}
                    type="button"
                    disabled={loading}
                    onClick={onUpdate}
                >
                    {loading ? 'Ładowanie...' : 'Aktualizuj regulamin'}
                </button>
                
            </div>
            {error !== "" ?
                <div className="alert alert-error shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>{error}</span>
                    </div>
                </div> : <div />
            }
            {success !== "" ?
                <div className="alert alert-success shadow-lg">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>{success}</span>
                    </div>
                </div> : <div />
            }
        </div>
    );
};