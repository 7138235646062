import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {completeOrderDriver, getOrderById} from "../../app/requests/order";


export default function DriverConfirmationScreen() {
    const [orderDetails, setOrderDetails] = useState({});
    const [loading, setLoading] = useState(true);

    const [wasChanged, setWasChanged] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const orderId = window.location.pathname.split("/")[2];
        setLoading(true);
        getOrderById(orderId).then(setOrderDetails).then(() => setLoading(false));
    }, []);

    function mapStatus(status) {
        switch (status) {
            case 0:
                return "Nowe"
            case 1:
                return "Zaaakceptowane"
            case 2:
                return "Zrealizowane"
            case 3:
                return "Anulowane"
            case 4:
                return "Odrzucone"
            case 5:
                return "Przedawnione"
            default:
                return "Nieznany"
        }
    }

    function mapOrderType(orderType) {
        switch (orderType) {
            case 0:
                return "Samochodowe"
            case 1:
                return "Kolejowe"
            case 2:
                return "Morskie"
            default:
                return "Nieznany"
        }
    }

    function addDays(date, days) {
        let result = Date.parse(date) + days * 24 * 60 * 60 * 1000;
        result = new Date(result);
        return result.toISOString().split('T')[0];
    }

    return (
        <div className="p-2">
            {
                loading ?
                    <div className="flex justify-center items-center h-screen">
                        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                    </div> :
                    <>
                        <div className={"p-8 pt-0 my-8 mx-auto rounded-sm max-w-[65rem]"}>
                            <h1 className={"text-2xl font-bold md-3 max-w-md"}>Realizacja
                                zlecenia {orderDetails.orderNumber}</h1>
                            <br/>
                            <h2>
                                <span className={"text-sm font-bold ml-4"}>Zleceniodawca:</span>
                                <span className={"text-sm ml-1"}>{orderDetails.client.companyName}</span>
                                <br/>
                                <span className={"text-sm font-bold ml-4"}>Status</span>
                                <span className={"text-sm ml-1"}>{mapStatus(orderDetails.orderStatus)}</span>
                                <br/>
                                <span className={"text-sm font-bold ml-4"}>Zamówiona ilość</span>
                                <span className={"text-sm ml-1"}>{orderDetails.quantityTons} [t]</span>
                            </h2>
                            <br/>
                            <span className={"text-sm font-bold mt-1"}>LISTA KIEROWCÓW</span>
                            <div className="grid grid-cols-4 grid-flow-cols border-[1px] p-2">
                                <span className={"font-bold mt-1"}>Kierowca</span>
                                <span className={"font-bold mt-1"}>Potwierdzony</span>
                                <span className={"font-bold mt-1"}>Data realizacji</span>
                                <span className={"font-bold mt-1"}>Wartość [t]</span>
                                {orderDetails.driversConfirmed.map((driver, index) => (
                                    <>
                                        <span key={'driver_' + index}
                                              className={"mt-1"}>
                                            {driver.firstName + " " + driver.lastName + " (" + driver.carsPlateNumber + ")"}
                                        </span>
                                        <span key={'driverConfirmed_' + index}>
                                           {driver.confirmed ? "TAK" : "NIE"} 
                                        </span>
                                        <span key={'driverDate_' + index}>
                                            <input type="date"
                                                   className={"input input-bordered input-sm w-36 border-gray-400 rounded-none font-normal"}
                                                   value={driver.realizationDate?.substring(0,10)??""}
                                                   onChange={(e) => {
                                                       if (e.target.value !== '' && e.target.value !== undefined ) {
                                                           const newDriversConfirmed = [...orderDetails.driversConfirmed];
                                                           newDriversConfirmed[index].realizationDate = e.target.value;
                                                           setOrderDetails({
                                                               ...orderDetails,
                                                               driversConfirmed: newDriversConfirmed
                                                           });
                                                           setWasChanged([...wasChanged, index]);
                                                       } else {
                                                           const newDriversConfirmed = [...orderDetails.driversConfirmed];
                                                           newDriversConfirmed[index].realizationDate = null;
                                                           setOrderDetails({
                                                               ...orderDetails,
                                                               driversConfirmed: newDriversConfirmed
                                                           });
                                                            setWasChanged(wasChanged.filter((item) => item !== index));
                                                       }
                                                   }}/>
                                        </span>
                                        <span key={'driverTons_' + index}
                                              className={"mt-1"}>
                                            <input key={'driverTonsInput_' + index}
                                                   type={"number"} min={0} max={100}
                                                   className={"input input-bordered input-xs rounded-xs text-sm mr-1"}
                                                   defaultValue={driver.tons}
                                                   value={driver.tons}
                                                   onChange={(e) => {
                                                       const newDriversConfirmed = [...orderDetails.driversConfirmed];
                                                       newDriversConfirmed[index].tons = e.target.value;
                                                       setOrderDetails({
                                                           ...orderDetails,
                                                           driversConfirmed: newDriversConfirmed
                                                       });
                                                       setWasChanged([...wasChanged, index]);
                                                   }}
                                            />
                                            <button key={'driverTonsButton_' + index}
                                                    className={"btn btn-sm btn-ghost"}
                                                    disabled={!wasChanged.includes(index) && driver.realizationDate !== null}
                                                    onClick={() => {
                                                        completeOrderDriver(orderDetails.id, driver.confirmationId, driver.tons, driver.realizationDate).then(() => {
                                                            setWasChanged(wasChanged.filter((item) => item !== index));
                                                            const newDriversConfirmed = [...orderDetails.driversConfirmed];
                                                            newDriversConfirmed[index].confirmed = true;
                                                            setOrderDetails({
                                                                ...orderDetails,
                                                                driversConfirmed: newDriversConfirmed
                                                            });
                                                        });
                                                    }}
                                            >Zapisz</button>
                                            <button key={'driverTonsEqualsZeroButton_' + index}
                                                    className={"btn btn-sm btn-ghost"}
                                                    onClick={() => {
                                                        completeOrderDriver(orderDetails.id, driver.confirmationId, 0).then(() => {
                                                            const newDriversConfirmed = [...orderDetails.driversConfirmed];
                                                            newDriversConfirmed[index].confirmed = true;
                                                            setOrderDetails({
                                                                ...orderDetails,
                                                                driversConfirmed: newDriversConfirmed
                                                            });
                                                            setWasChanged(wasChanged.filter((item) => item !== index));
                                                        });
                                                    }}
                                            >Zapisz dla 0t</button>
                                        </span>
                                    </>
                                ))}
                            </div>
                            <br/>
                            <button
                                className={"btn bg-yellow-600 border-0 rounded-sm text-white"}
                                onClick={() => navigate(-1)}
                            >Powrót
                            </button>
                        </div>
                    </>
            }
        </div>
    )

}