import {useState} from "react";
import {resetPassword} from "../../app/requests/annonymous";


export function PasswordResetScreen () {
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }
    
    const handlePasswordConfirmChange = (event) => {
        setPasswordConfirm(event.target.value);
    }
    
    const handlePasswordReset = () => {
        setLoading(true);
        setError('');
        setSuccess('');
        if (password !== passwordConfirm) {
            setError('Hasła muszą być takie same');
            setLoading(false);
        } else {
            let token = window.location.pathname.split('/')[2];
            resetPassword(token, password, passwordConfirm)
                .then(() => setSuccess('Zresetowano hasło'))
                .catch((error) => {console.log(error.response); setError(error.response.status === 400 ? error.response.data : 'Wystąpił błąd')});
            setLoading(false);
        }
    }
    
    return (
        <div className="p-2">
            <div className="p-8 my-8 mx-auto border-2 bg-base-200 border-gray-300 rounded-md max-w-lg">
                <h1 className="text-2xl font-bold">Zmień hasło</h1>
                <br/>
                <label>Hasło</label>
                <br/>
                <input
                    type="password"
                    placeholder="Hasło"
                    className="input input-bordered w-full max-w-xs mb-2"
                    value={password}
                    onChange={e => setPassword(e.target.value)}

                />
                <br/>

                <label>Potwierdź hasło</label>
                <br/>
                <input
                    type="password"
                    placeholder="Potwierdź hasło"
                    className="input input-bordered w-full max-w-xs mb-2"
                    value={passwordConfirm}
                    onChange={e => setPasswordConfirm(e.target.value)}
                />
                <br/>

                <button
                    className={"btn bg-yellow-600 border-none rounded-sm" + (loading ? " loading" : "")}
                    type="button"
                    disabled={loading || success !== ''}
                    onClick={handlePasswordReset}
                >
                    {loading ? 'Ładowanie...' : 'Zmień hasło'}
                </button>
               
                {error !== "" ?
                    <div className="alert alert-error shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                 fill="none"
                                 viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            <span>{error}</span>
                        </div>
                    </div> : <div/>
                }
                {success !== "" ?
                    <div className="alert alert-success shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                        d="M5 13l4 4L19 7"/>
                            </svg>
                            <span>{success}</span>
                        </div>
                    </div> : <div/>
                }
            </div>
        </div>
    );
}