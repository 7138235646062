import {API_URL} from "../../app/constants";
import {useEffect, useState} from "react";
import {statuteInfo} from "../../app/requests/annonymous";

export const StatuteScreen = () => {
    const [statute, setStatute] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const loadStatute = async () => {
            await statuteInfo()
                .then(response => {
                    setStatute(response.data);
                    setLoading(false);
                })
        }
        loadStatute().catch(error => {
            setError("Wystąpił błąd podczas ładowania regulaminu");
            setLoading(false);
        });
    }, []);

    return (
        <div className="p-2">
            <div className="p-8 my-8 mx-auto border-2 bg-base-200 border-gray-300 rounded-md max-w-lg">
                <h1 className="text-2xl font-bold">Regulamin</h1>
                <br/>
                <a className="btn" target={'_blank'} href={API_URL + '/api/statute'}>Pobierz regulamin</a>
                {error !== "" ?
                    <div className="alert alert-error shadow-lg mt-4 fixed bottom-[8%] right-[10%] w-[80%] sm:w-[40%] lg:w-96">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                 fill="none"
                                 viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            <span>{error}</span>
                        </div>
                    </div> : <div/>
                }
            </div>
        </div>
    );
}
    