import {useEffect, useState} from "react";
import {useNavigation, useSearchParams} from "react-router-dom";
import {getOrdersBySearchCriteria} from "../../app/requests/order";
import {getProductsList} from "../../app/requests/productsList";
import {getTerminalList} from "../../app/requests/terminalList";
import {getCustomerList} from "../../app/requests/user";
import {getJwtTokenTerminalId, isJwtTokenExpired, isTerminal, removeJwtToken} from "../../app/token_service";
import {deserializeQuery} from "../../app/functions/query";
import order from "../../assets/icons/order.png"
import pack from "../../assets/icons/pack.png"


function createInitialSearchCriteria(searchParams) {
    return {
        OrderNumber: searchParams.get("OrderNumber") || "",
        OrderStatus: Number(searchParams.get("OrderStatus")) ?? -1,
        Client: Number(searchParams.get("Client")) || "",
        TerminalId: getJwtTokenTerminalId(),
        ProductId: Number(searchParams.get("ProductId")) || -1,
        DateFrom: searchParams.get("DateFrom") || '',
        DateTo: searchParams.get("DateTo") || '',
        WeightFrom: Number(searchParams.get("WeightFrom")) || '',
        WeightTo: Number(searchParams.get("WeightTo")) || '',
    }
}

export default function OrderListTrader() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchCriteria, setSearchCriteria] = useState(createInitialSearchCriteria(searchParams));
    const [orders, setOrders] = useState([]);

    const [products, setProducts] = useState([]);
    const [terminals, setTerminals] = useState([]);
    const [customerList, setCustomerList] = useState([]);

    const [dropdownOpen, setDropdownOpen] = useState(searchParams.get("showDrivers") === "true");

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [error, setError] = useState(null);

    function initialFetch() {
        getCustomerList().then(setCustomerList).catch(() => setError("Wystąpił błąd podczas pobierania listy klientów, odśwież stronę aby spróbować ponownie"));
        getProductsList().then(setProducts).catch(() => setError("Wystąpił błąd podczas pobierania listy produktów, odśwież stronę aby spróbować ponownie"));
        getTerminalList().then(setTerminals).catch(() => setError("Wystąpił błąd podczas pobierania listy terminali, odśwież stronę aby spróbować ponownie"));
        if (searchParams.toString() !== "") fetchData();
    }

    function fetchData() {
        getOrdersBySearchCriteria(JSON.parse(JSON.stringify(searchCriteria)), page).then(r => setOrders(r.data));
    }

    useEffect(fetchData, [searchCriteria, page])
    useEffect(() => setPage(1), [searchCriteria])

    function mapStatus(status) {
        switch (status) {
            case 0:
                return "Nowe"
            case 1:
                return "Zaaakceptowane"
            case 2:
                return "Zrealizowane"
            case 3:
                return "Anulowane"
            case 4:
                return "Odrzucone"
            case 5:
                return "Przedawnione"
            default:
                return "Nieznany"
        }
    }

    useEffect(initialFetch, []);

    if (isJwtTokenExpired() || !isTerminal()) {
        removeJwtToken();
        window.location.href = "/";
    }

    return (<div className="p-8">
        <h1 className="text-2xl font-bold md-3 max-w-md mb-5">Wyszukaj zamówienia</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4 place-content-evenly">

        </div>
        <div className="mt-5 overflow-x-auto">
            <div className="btn-group mb-2">
                <button className="btn btn-sm" onClick={() => setPage(Math.max(1, page - 1))}>«</button>
                <button className="btn btn-sm">Strona {page} / {totalPages}</button>
                <button className="btn btn-sm" onClick={() => setPage(Math.min(totalPages, page + 1))}>»</button>
            </div>
            <table className="table table-compact w-full rounded-none static">
                <thead className="rounded-none">
                <tr key="head" className="rounded-none">
                    <th className="rounded-none w-8"/>
                    <th className="rounded-none w-40">Nr zamówienia</th>
                    <th className="rounded-none w-max min-w-96">Nazwa firmy klienta</th>
                    <th className="rounded-none w-28">Status</th>
                    <th className="rounded-none w-48">Terminal</th>
                    <th className="rounded-none w-20">Data od</th>
                    <th className="rounded-none w-20">Data do</th>
                    <th className="rounded-none w-24">Produkt</th>
                    <th className="rounded-none w-24">Waga [t]</th>
                    <th className="rounded-none w-32">Akcje</th>
                </tr>
                </thead>
                <thead className={"mt-0"}>
                <th className="rounded-none w-8"/>
                <th className="rounded-none w-40">
                    <input type="text" className="border border-gray-400 rounded-none p-1 max-w-md"
                           value={searchCriteria.OrderNumber}
                           onKeyDown={event => {
                               if (event.key === 'Enter') fetchData();
                           }}
                           onChange={e => setSearchCriteria({
                               ...searchCriteria, OrderNumber: e.target.value
                           })}/>
                </th>
                <th className="rounded-none w-max min-w-96">
                    <input type="text" className="border border-gray-400 rounded-none p-1 max-w-md"
                           value={searchCriteria.Client}
                           onKeyDown={event => {
                               if (event.key === 'Enter') fetchData();
                           }}
                           onChange={e => setSearchCriteria({...searchCriteria, Client: e.target.value})}
                           list={'customer-selection'}/>
                    <datalist id={'customer-selection'}>
                        {customerList.map(c => <option key={c.id} value={c.companyName}/>)}
                    </datalist>
                </th>
                <th className="rounded-none w-28">
                    <select className="border border-gray-400 rounded-none p-1 max-w-md"
                            value={searchCriteria.OrderStatus}
                            onChange={e => setSearchCriteria({
                                ...searchCriteria, OrderStatus: Number(e.target.value)
                            })}>
                        <option value={null}>Wszystkie</option>
                        <option value={0}>Nowe</option>
                        <option value={1}>Zaakceptowane</option>
                        <option value={2}>Zrealizowane</option>
                        <option value={3}>Anulowane</option>
                        <option value={4}>Odrzucone</option>
                        <option value={5}>Przedawnione</option>
                    </select>
                </th>
                <th className="rounded-none w-48">
                    <select className="border border-gray-400 rounded-none p-1 max-w-md"
                            value={searchCriteria.TerminalId}
                            onKeyDown={event => {
                                if (event.key === 'Enter') fetchData();
                            }}
                            disabled={true}
                            onChange={e => setSearchCriteria({
                                ...searchCriteria, TerminalId: Number(e.target.value)
                            })}>
                        <option value={-1}>Wszystkie</option>
                        {terminals.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
                    </select>
                </th>
                <th className="rounded-none w-20">
                    <input type="date" className="border border-gray-400 rounded-none p-1 max-w-md"
                           value={searchCriteria.DateFrom === "null" ? "" : searchCriteria.DateFrom}
                           onKeyDown={event => {
                               if (event.key === 'Enter') fetchData();
                           }}
                           onChange={e => setSearchCriteria({
                               ...searchCriteria, DateFrom: e.target.value === undefined ? "" : e.target.value
                           })}/>
                </th>
                <th className="rounded-none w-20">
                    <input type="date" className="border border-gray-400 rounded-none p-1 max-w-md"
                           value={searchCriteria.DateTo === "null" ? "" : searchCriteria.DateTo}
                           onKeyDown={event => {
                               if (event.key === 'Enter') fetchData();
                           }}
                           onChange={e => setSearchCriteria({
                               ...searchCriteria, DateTo: e.target.value === null ? "" : e.target.value
                           })}/>
                </th>
                <th className="rounded-none w-24">
                    <select className="border border-gray-400 rounded-none p-1 max-w-md"
                            value={searchCriteria.ProductId}
                            onKeyDown={event => {
                                if (event.key === 'Enter') fetchData();
                            }}
                            onChange={e => setSearchCriteria({
                                ...searchCriteria, ProductId: Number(e.target.value)
                            })}>
                        <option value={-1}>Wszystkie</option>
                        {products.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
                    </select>
                </th>
                <th className="rounded-none w-24">
                    <input type="number" className="border border-gray-400 rounded-none p-1 max-w-[3rem]"
                           value={searchCriteria.WeightFrom}
                           onKeyDown={event => {
                               if (event.key === 'Enter') fetchData();
                           }}
                           onChange={e => setSearchCriteria({
                               ...searchCriteria, WeightFrom: e.target.value
                           })}/>
                    <input type="number" className="border border-gray-400 rounded-none p-1 max-w-[3rem]"
                           value={searchCriteria.WeightTo}
                           onKeyDown={event => {
                               if (event.key === 'Enter') fetchData();
                           }}
                           onChange={e => setSearchCriteria({
                               ...searchCriteria, WeightTo: e.target.value
                           })}/>
                </th>
                <th className="rounded-none w-32">Akcje</th>
                </thead>
                <tbody>
                {orders.map(o => <>
                    <tr key={o.orderNumber}>
                        <td></td>
                        <td className="w-36">{o.orderNumber}</td>
                        <td className="whitespace-normal">{o.clientName || "Brak firmy"}</td>
                        <td>{mapStatus(o.orderStatus)}</td>
                        <td>{o.terminalName}</td>
                        <td>{o.orderDate.substring(0, 10)}</td>
                        <td>{o.orderEndDate.substring(0, 10)}</td>
                        <td>{o.productName}</td>
                        <td className="text-right">{o.quantity}</td>
                        <td>
                            <a href={`/order-details/${o.id}`}>
                                <div className={"tooltip m-0 p-0"} data-tip={"Szczegóły zlecenia"}>
                                    <img src={order} alt="Szczegóły zlecenia" className="w-6 h-6"/>
                                </div>
                            </a>
                            <a href={`/driver-completion/${o.id}`}>
                                <div className={"tooltip m-0 p-0"} data-tip={"Realizuj zlecenie"}>
                                    <img src={pack} alt="Realizuj zlecenie" className="w-6 h-6"/>
                                </div>
                            </a>
                        </td>
                    </tr>
                    {dropdownOpen ? o.orderDrivers.map(d => <tr key={d.id} className={"bg-base-200"}>
                        <td className={"bg-base-200"}/>
                        <td className="w-36" className={"bg-base-200"}>{d.symfoniaCode}</td>
                        <td className={"bg-base-200 text-left"}> {d.firstName} </td>
                        <td className={"bg-base-200"}> {d.lastName} </td> 
                        <td className={"bg-base-200"}> {d.carPlate} </td>
                        <td className={"bg-base-200"}> {d.documentNumber} </td>
                        <td className={"bg-base-200"}> {d.phoneNumber} </td>
                        <td className={"bg-base-200"}> {d.realizationDate != null ? d.realizationDate.substring(0, 10) : null}</td>
                        <td className={"bg-base-200 text-right"}> {d.tons} </td>
                        <td className={"bg-base-200"}>
                            <a href={`/driver-completion/${o.id}`}>
                                Odbiór
                            </a>
                        </td>
                    </tr>) : null}
                </>)}

                </tbody>
            </table>
            <nobr>
                <button className={"btn btn-sm mt-4"}
                        onClick={() => {
                            setDropdownOpen(!dropdownOpen);
                            setSearchParams({
                                ...deserializeQuery(searchParams.toString()), showDrivers: !dropdownOpen
                            })
                        }}>{dropdownOpen ? "Ukryj kierowców" : "Pokaż kierowców"}
                </button>
            </nobr>
        </div>
    </div>)
}
